#brand {
  color: $white;
  padding: 80px 20px;
  background: url('/static/img/blesq_diamond.jpg');
  background-size: cover;
  background-position: center center;
  text-align: center;
  position: relative;
  border-top: 16px solid $white;
  .button {
    margin-top: 350px;
  }
  h2, p, a {
    position: relative;
    z-index: 2;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: $black;
    opacity: 0.3;
  }
  @media screen and (max-width: $medium_breakpoint) {
    border-top: 8px solid $white;
    .button {
      margin-top: 250px;
    }
  }
}