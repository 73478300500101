#overview {
  width: 100%;
}

.overview__intro {
  width: 100%;
  position: relative;
  padding-bottom: 250px;
  @media screen and (max-width: $medium_breakpoint) {
    padding-bottom: 200px;
  }
}

.overview__intro__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: $near_black;
  background-size: cover;
  background-position: center;
  transition: transform $reveal_duration $reveal_timing_function,
  opacity $reveal_duration $reveal_timing_function;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba($black, 0.5), rgba($black, 0));
  }
  #overview.bridal & {
    &:after {
      //opacity: 0;
    }
  }
  .reveal & {
    transform: scale(0.9);
    opacity: 0;
  }
}

.overview__intro__title {
  position: relative;
  z-index: 2;
  text-align: left;
  width: 100%;
  color: $white;
  transition: transform $reveal_duration $reveal_timing_function 0.4s,
  opacity $reveal_duration $reveal_timing_function 0.4s;
  .reveal & {
    transform: scale(0.9);
    opacity: 0;
  }
}

.overview__intro__title__main {
  width: 100%;
  max-width: 1200px;
  padding: 100px 80px;
  margin: 0 auto;
  h1 {
    font-family: $JannonAntiqua;
    font-weight: bold;
    line-height: 1.4;
    font-size: 50px;
    margin-bottom: 20px;
    span {
      font-weight: normal;
      font-size: 30px;
      display: inline-block;
      position: relative;
      margin-bottom: 15px;
    }
  }
  p {
    max-width: 600px;
    transition: opacity $reveal_duration $reveal_timing_function 0.7s;
    .reveal & {
      opacity: 0;
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    padding: 60px 20px 40px;
    max-width: 450px;
    margin: 0 auto;
    h1 {
      font-size: 30px;
      span {
        font-size: 20px;
      }
      .headline_br {
        display: none;
      }
    }
    p {
      font-size: 14px;
    }
  }
}

.overview__products {
  width: 100%;
  margin: -250px 0 0 0;
  padding-bottom: 75vh;
  @media screen and (max-width: $medium_breakpoint) {
    margin-top: -200px;
    padding-bottom: 50px;
  }
}

.overview__list {
  width: 100%;
}

.overview__list__controls {
  width: 100%;
  font-size: 0;
  margin-bottom: 40px;
  position: relative;
  z-index: 5;
}

.overview__list__controls__frame {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding: 0 80px;
  z-index: 2;
  @media screen and (max-width: $medium_breakpoint) {
    max-width: 450px;
    padding: 0 20px;
  }
}

.overview__list__controls__item {
  display: inline-block;
  height: 44px;
  width: 32%;
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 14px;
  color: $near_black;
  position: relative;
  vertical-align: top;
  line-height: 44px;
  background: $light_gray;
  text-decoration: none;
  margin-left: 2%;
  transition: transform $reveal_duration $reveal_timing_function,
  opacity $reveal_duration $reveal_timing_function;
  .reveal & {
    transform: scale(0.9);
    opacity: 0;
  }
  &:first-child {
    margin-left: 0;
  }
  @media screen and (max-width: $medium_breakpoint) {
    margin-left: 0;
    margin-top: 4%;
    width: 48%;
    &:last-child {
      margin-left: 4%;
    }
  }
}

.overview__list__controls__icon {
  position: absolute;
  top: 12px;
  right: 20px;
  width: 20px;
  height: 20px;
  will-change: transform;
  svg {
    width: 100%;
    height: 100%;
    display: block;
    fill: currentColor;
    will-change: transform;
  }
}

.overview__list__controls__item--search {
  transition-delay: 1.1s;
  transform-origin: 100% 0;
  input {
    width: 100%;
    height: 100%;
    font-weight: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    border: none;
    text-indent: 20px;
    background: transparent;
    padding-right: 60px;
    -webkit-appearance: none;
    border-radius: 0;
    &::placeholder {
      color: inherit;
    }
  }
  .overview__list__controls__icon {
    cursor: text;
  }
  @media screen and (max-width: $medium_breakpoint) {
    width: 100%;
    transform-origin: 50% 0;
  }
}

.overview__list__controls__item--filter {
  transition-delay: 1.4s;
  padding-left: 20px;
  transform-origin: 50% 0;
  &.active {
    background: $gray;
    color: $white;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border: 2px solid $light_gray;
      border-bottom: none;
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    transform-origin: 50% 0;
  }
}

.overview__list__controls__item--sort {
  transition-delay: 1.7s;
  transform-origin: 0 0;
  label {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding-right: 60px;
    padding-left: 20px;
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
  }
  .overview__list__controls__icon {
    cursor: pointer;
  }
  input {
    position: absolute;
    display: block;
    opacity: 0;
    z-index: -1;
    &:focus {
      & ~ ul {
        display: block;
      }
      & ~ label {
        pointer-events: none;
      }
    }
  }
  ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: $dark_light_gray;
    z-index: 20;
    li {
      line-height: 1.3;
      padding: 10px 20px;
      cursor: pointer;
      letter-spacing: 0.1em;
      font-weight: normal;
      &:hover {
        background: $white;
      }
      &.active {
        font-weight: 600;
      }
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    transform-origin: 50% 0;
  }
}

.overview__list__filter__content {
  width: 32%;
  margin-left: 2%;
  display: inline-block;
  vertical-align: top;
  color: $white;
  padding: 0 10px;
  &:first-child {
    margin-left: 0;
  }
  @media screen and (max-width: $medium_breakpoint) {
    width: 100%;
    margin: 0 0 20px 0;
  }
}

.overview__list__filter__content__title {
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px;
  font-weight: 600;
}

.overview__list__filter__content__list {
  margin: 0 -1%;
  white-space: normal;
}

.filter__checkbox {
  display: inline-block;
  vertical-align: top;
  width: 48%;
  margin: 0 1%;
  margin-bottom: 2%;
  input {
    display: none;
    &:checked + .filter__checkbox__content {
      background: $white;
      color: $near_black;
      svg {
        opacity: 1;
      }
    }
  }
}

.filter__checkbox__content {
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 1.4;
  padding: 8px 8px 8px 30px;
  border: 2px solid $white;
  position: relative;
  letter-spacing: 0.5px;
  transition: background 0.3s $easeOutQuad, color 0.3s $easeOutQuad;
  font-weight: 500;
}

.filter__checkbox__icon {
  position: absolute;
  top: 10px;
  left: 8px;
  width: 14px;
  height: 14px;
  svg {
    display: block;
    opacity: 0;
    width: 100%;
    height: 100%;
    fill: darken($gray, 30);
  }
}

.overview__list__frame {
  width: 100%;
  font-size: 0;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 80px;
  position: relative;
  z-index: 4;
  @media screen and (max-width: $medium_breakpoint) {
    max-width: 450px;
    padding: 0 20px;
  }
}

.product_list {
  display: block;
  letter-spacing: -4px;
  margin: 0 -1%;
  @media screen and (max-width: $medium_breakpoint) {
    margin: 0;
  }
}

.product_list__item {
  letter-spacing: normal;
  width: 31.33333%;
  display: inline-block;
  vertical-align: top;
  margin: 0 1% 2%;
  @media screen and (max-width: $medium_breakpoint) {
    width: 100%;
    margin: 0 0 20px !important;
  }
}

$product_list_item_transition_duration: 1s;

.product_list__item__content {
  width: 100%;
  height: 0;
  padding-bottom: 155%;
  white-space: normal;
  position: relative;
  overflow: hidden;
  background: $white;
  border: 1px solid $light_gray;
  display: block;
  text-decoration: none;
  color: $near_black;
  font-size: 16px;
  font-family: $FreightSansPro;
  box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.05);
  transition: transform $reveal_duration $reveal_timing_function,
  opacity $reveal_duration $easeOutExpo;
  .first & {
    transition-delay: 0.3s;
  }
  .second & {
    transition-delay: 0.6s;
  }
  .third & {
    transition-delay: 0.9s;
  }
  .black & {
    background: $black;
    color: $white;
    border: none;
    box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.4);
    html.no-touchevents &:hover {
      background: $black;
    }
  }
  .reveal &, ._reveal & {
    opacity: 0;
    transform: translateY($reveal_delta);
  }
  ._reveal & {
    transition: transform $reveal_duration/4 $reveal_timing_function,
    opacity $reveal_duration/4 $easeOutExpo;
  }
  html.no-touchevents &:hover {
    .product_list__item__image {
      transform: translateX(-50%) translateY(-50%) translateY(-60px);
    }
    .product_list__item__text {
      transform: translateY(-90px);
    }
    .product_list__item__price {
      opacity: 1;
      transform: none;
    }
    .product_list__item__arrow {
      opacity: 1;
      transform: none;
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    padding-bottom: 100%;
    transition-delay: 0s !important;
  }
}

.product_list__item__image {
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  will-change: transform;
  transform: translateX(-50%) translateY(-50%);
  transition: transform $product_list_item_transition_duration $easeOutQuart;
}

.product_list__item__text {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
  transform: none;
  line-height: 1.4;
  transition: opacity $product_list_item_transition_duration $easeOutQuart,
  transform $product_list_item_transition_duration $easeOutQuart;
}

.product_list__item__title {
  display: block;
  margin-bottom: 5px;
  font-family: $JannonAntiqua;
  font-weight: bold;
  span {
    font-weight: normal;
    opacity: 0.6;
    display: inline-block;
  }
}

.product_list__item__price {
  opacity: 0;
  display: block;
  transform: translateY(50px);
  transition: opacity $product_list_item_transition_duration $easeOutQuart,
  transform $product_list_item_transition_duration $easeOutQuart;
}

.product_list__item__arrow {
  position: absolute;
  bottom: 20px;
  width: 50px;
  height: 50px;
  left: 50%;
  margin-left: -25px;
  opacity: 0;
  transform: translateY(200px);
  transition: opacity $product_list_item_transition_duration $easeOutQuart,
  transform $product_list_item_transition_duration $easeOutQuart;
  svg {
    fill: $near_black;
  }
  .black & {
    svg {
      fill: $white;
    }
  }
}

.overview__list__controls__filter__content {
  background: $gray;
  width: 100%;
  border-top: 2px solid $light_gray;
  margin-top: -2px;
  position: relative;
  z-index: 1;
  display: none;
  &.active {
    display: block;
  }
}

.overview__list__controls__filter__content__main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 80px 60px;
  position: relative;
  @media screen and (max-width: $medium_breakpoint) {
    max-width: 450px;
    padding: 80px 20px 60px;
  }
}

.overview__list__controls__close {
  position: absolute;
  right: 80px;
  top: 20px;
  width: 40px;
  height: 40px;
  padding: 13px;
  display: block;
  svg {
    fill: $white;
    display: block;
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: $medium_breakpoint) {
    right: 20px;
  }
}

.overview__list__controls__reset {
  position: absolute;
  right: 140px;
  top: 18px;
  height: 40px;
  padding: 11px 0;
  display: block;
  text-decoration: none;
  color: $white;
  font-size: 12px;
  line-height: 18px;
  vertical-align: bottom;
  letter-spacing: 0.05em;
  font-weight: 500;
  svg {
    fill: $white;
    display: inline-block;
    height: 18px;
    width: 18px;
    vertical-align: bottom;
    margin-right: 10px;
  }
  &:before, &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: $white;
    position: absolute;
    bottom: 3px;
    left: 0;
    opacity: 0.3;
    z-index: 2;
  }
  &:after {
    opacity: 1;
    width: 0;
    z-index: 3;
    transition: width 0.3s ease-in-out;
  }
  &:hover {
    &:after {
      width: 100%;
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    right: 80px;
  }
}