#instagram {
  padding: 80px 0 100px;
  overflow: hidden;
}

.instagram__feed {
  margin: 80px 0 0 0;
}

.instagram__feed__row {
  width: 100%;
  margin-top: 10px;
  white-space: nowrap;
  font-size: 0;
  text-align: right;
  &:first-child {
    margin: 0 0 0 -8%;
  }
}

.instagram__feed__item {
  display: inline-block;
  width: 25%;
  margin: 0 5px;
  min-width: 150px;
  max-width: 300px;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}