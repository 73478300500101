.product_overview_frame {
  background: $light_gray;
  padding: 80px 30px;
  @media screen and (max-width: $medium_breakpoint) {
    padding-top: 50px;
  }
}

.product_overview {
  max-width: 1400px;
  margin: 0 auto;
}

.product_overview__content {
  margin: 0 -1%;
  font-size: 0;
}

.product_overview__category {
  margin: 0 0 10px 0;
  display: inline-block;
  width: 100%;
  h3 {
    padding: 20px 25px;
    font-size: 14px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    line-height: 1.3;
  }
}

.product_overview__category--thirds-right {
  .product_overview__item {
    &.product_overview__item--last {
      width: 48%;
    }
  }
}

.product_overview__category--thirds-left {
  .product_overview__item {
    &.product_overview__item--first {
      width: 48%;
    }
  }
}

.product_overview__category--inline {
  width: 33.33333%;
  .product_overview__item {
    width: 94%;
    margin: 0 3%;
    padding-bottom: 45%;
  }
  @media screen and (max-width: $small_breakpoint) {
    width: 100%;
  }
}

.product_overview__item {
  margin: 0 1%;
  width: 23%;
  padding-bottom: 15%;
  display: inline-block;
  position: relative;
  white-space: normal;
  overflow: hidden;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $black;
    opacity: 0.3;
    z-index: 4;
  }
  h4 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    padding: 0 10%;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    color: $white;
    line-height: 1.3;
    letter-spacing: 0.14em;
    z-index: 5;
    @media screen and (max-width: $large_breakpoint) {
      font-size: 16px;
    }
    @media screen and (max-width: $medium_breakpoint) {
      font-size: 14px;
    }
  }
  &:hover {
    .product_overview__item__background {
      transform: scale(1.1);
    }
  }
  @media screen and (max-width: $small_breakpoint) {
    width: 100% !important;
    margin: 0 0 20px 0 !important;
    padding-bottom: 30% !important;
  }
}

.product_overview__item__background {
  display: block;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 1s $easeOutQuad;
  transform: none;
}