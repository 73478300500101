#share {
  width: 100%;
  padding: 25px 20px;
  background: $bronze;
  text-align: center;
  color: $bronze;
  border-top: 16px solid $white;
  @media screen and (max-width: $medium_breakpoint) {
    border-top: 8px solid $white;
  }
}

.share__title {
  line-height: 35px;
  display: inline-block;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  vertical-align: bottom;
  color: $white;
}

.share__list {
  margin-left: 15px;
  display: inline-block;
  vertical-align: bottom;
}

.share__item {
  margin-left: 15px;
  display: inline-block;
  a {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: $white;
    padding: 10px;
    color: inherit;
    svg {
      width: 100%;
      height: 100%;
      fill: currentColor;
    }
  }
}