#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  z-index: 500;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: $white;
  }
  body.transparentheader & {
    position: absolute;
    top: 16px;
    &:after {
      transform: translateY(-100%);
      transition: transform 1s $easeOutQuart;
    }
    &.fixed {
      position: fixed;
      top: 0;
      &:after {
        transform: none;
      }
    }
    @media screen and (max-width: $medium_breakpoint) {
      top: 8px;
    }
  }
  body.noheader & {
    display: none;
  }
  body.logoheader & {
    top: 16px;
    &:after {
      opacity: 0;
    }
    @media screen and (max-width: $medium_breakpoint) {
      top: 8px;
    }
  }
  body.whiteheader & {
    color: $white;
    transition: color 1s $easeOutQuart;
    &.fixed {
      color: $near_black;
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    height: 80px;
  }
}

.header__navigation {
  position: absolute;
  top: 0;
  padding: 0 20px;
  text-decoration: none;
  display: block;
  text-transform: uppercase;
  line-height: 100px;
  font-size: 13px;
  letter-spacing: 3px;
  color: inherit;
  font-weight: 500;
  &:before {
    height: 100px;
    width: 20px;
    position: absolute;
    top: 0;
    font-size: 20px;
  }
  body.logoheader & {
    display: none;
  }
  @media screen and (max-width: $medium_breakpoint) {
    line-height: 80px;
    &:before {
      height: 80px;
    }
  }
  @media screen and (max-width: $small_breakpoint) {
    font-size: 10px;
    white-space: nowrap;
  }
}

.header__navigation__icon {
  position: absolute;
  display: block;
  width: 20px;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  svg {
    fill: currentColor;
    display: block;
    width: 100%;
    height: auto;
  }
}

.header__navigation--products {
  left: 0;
  padding-left: 90px;
  transition: transform $reveal_duration $reveal_timing_function,
  opacity $reveal_duration $reveal_timing_function;
  body.delayheader & {
    transition: transform $reveal_duration $reveal_timing_function 1s,
    opacity $reveal_duration $reveal_timing_function 1s;
  }
  .reveal & {
    transform: translateY(-100%);
    opacity: 0;
    &:after {
      opacity: 0 !important;
    }
  }
  .header__navigation__icon {
    left: 50px;
  }
  @media screen and (max-width: $medium_breakpoint) {
    padding-left: 70px;
    .header__navigation__icon {
      left: 30px;
    }
    &:after {
      left: 8px !important;
    }
  }
  @media screen and (max-width: $small_breakpoint) {
    padding-left: 60px;
  }
}

.header__navigation--basket {
  right: 0;
  padding-right: 90px;
  transition: transform $reveal_duration $reveal_timing_function 1s,
  opacity $reveal_duration $reveal_timing_function 1s;
  body.delayheader & {
    transition: transform $reveal_duration $reveal_timing_function 1.8s,
    opacity $reveal_duration $reveal_timing_function 1.8s;
  }
  .reveal & {
    transform: translateY(-100%);
    opacity: 0;
  }
  .header__navigation__icon {
    right: 50px;
  }
  @media screen and (max-width: $medium_breakpoint) {
    padding-right: 70px;
    .header__navigation__icon {
      right: 30px;
    }
  }
}

.header__navigation__amount {
  position: absolute;
  top: -7px;
  right: -6px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $blue;
  color: $white;
  line-height: 15px;
  font-size: 8px;
  font-family: sans-serif;
  display: block;
  text-align: center;
  letter-spacing: -0.1px;
}

.logo {
  display: inline-block;
  height: 20px;
  margin: 40px 0;
  text-decoration: none;
  color: inherit;
  opacity: 1;
  transition: transform $reveal_duration $reveal_timing_function 0.5s,
  opacity $reveal_duration $reveal_timing_function 0.5s;
  .reveal & {
    transform: translateY(-100%);
    opacity: 0;
  }
  svg {
    height: auto;
    width: 135px;
    fill: currentColor;
  }
  @media screen and (max-width: $medium_breakpoint) {
    margin: 30px 0;
  }
  @media screen and (max-width: $small_breakpoint) {
    height: 16px;
    margin: 32px 0;
    svg {
      height: auto;
      width: 108px;
    }
  }
}

.header__logo {
  body.delayheader & {
    transition: transform $reveal_duration $reveal_timing_function 1.4s,
    opacity $reveal_duration $reveal_timing_function 1.4s;
  }
  body.logoheader & {
    svg {
      fill: currentColor;
    }
  }
}