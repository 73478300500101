$normal_width: 80px;
$large_width: 120px;
$extra_large_width: 150px;

.selection_slider {
  width: 100%;
  height: 60px;
  line-height: 60px;
  position: relative;
  user-select: none;
  will-change: transform;
  input {
    display: none;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    width: $normal_width;
    height: 100%;
    margin-left: $normal_width / -2;
    background: $black;
    opacity: 0.1;
  }
  &.large {
    &:after {
      width: $large_width;
      margin-left: $large_width / -2;
    }
  }
  &.extra_large {
    &:after {
      width: $extra_large_width;
      margin-left: $extra_large_width / -2;
    }
  }
  &.dark {
    &:after {
      background: $white;
    }
  }
}

.selection_slider__selection {
  position: absolute;
  top: 0;
  left: 50%;
  width: $normal_width;
  height: 100%;
  margin-left: $normal_width / -2;
  border: 2px solid $white;
  box-shadow: 0 0 10px rgba($black, 0.4);
  z-index: 10;
  pointer-events: none;
  .selection_slider.large & {
    width: $large_width;
    margin-left: $large_width / -2;
  }
  .selection_slider.extra_large & {
    width: $extra_large_width;
    margin-left: $extra_large_width / -2;
  }
  .selection_slider.dark & {
    border-color: $black;
  }
}

.selection_slider__frame {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 3;
  //mask-image: linear-gradient(to right, transparent, $black, transparent);
  will-change: transform;
  cursor: grab;
  &:before {
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, $light_black, rgba($light_black, 0), $light_black);
    z-index: 5;
    .selection_slider.dark & {
      background: linear-gradient(to right, $white, rgba($white, 0), $white);
    }
    .no-csspointerevents & {
      display: none;
    }
  }
  &:active {
    cursor: grabbing;
  }
}

.selection_slider__content {
  width: 100%;
  height: 80px;
  padding-bottom: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  white-space: nowrap;
  font-size: 0;
  &:before, &:after {
    content: '';
    display: inline-block;
    width: 50%;
    height: 100%;
    vertical-align: top;
    margin-left: $normal_width / -2;
  }
  .selection_slider.large & {
    &:before, &:after {
      margin-left: $large_width / -2;
    }
  }
  .selection_slider.extra_large & {
    &:before, &:after {
      margin-left: $extra_large_width / -2;
    }
  }
  html.touchevents & {
    overflow: hidden;
  }
}

.selection_slider__list {
  width: auto;
  height: 100%;
  display: inline-block;
  position: relative;
}

.selection_slider__item {
  border-left: 1px solid rgba($white, 0.2);
  border-bottom: 1px solid rgba($white, 0.2);
  display: inline-block;
  width: $normal_width;
  height: 100%;
  &:first-child {
    border-left: 0;
  }
  .selection_slider.large & {
    width: $large_width;
  }
  .selection_slider.extra_large & {
    width: $extra_large_width;
  }
  .selection_slider.dark & {
    border-color: rgba($black, 0.2);
  }
}

.selection_slider__element {
  display: block;
  width: 100%;
  height: 100%;
  color: inherit;
  text-decoration: none;
  font-family: $Roboto;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.01em;
  &.svg {
    padding: 15px 0;
  }
  svg {
    width: 30px;
    height: 30px;
    display: block;
    margin: 0 auto;
    fill: $white;
  }
}