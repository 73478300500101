#diamonds {
  height: 100%;
  #share {
    background: $light_blue;
    color: $light_blue;
  }
  #quality {
    background: $light_black;
    color: $white;
  }
  #additional {
    background: $light_blue;
  }
  .action_controls__buttons {
    margin: 0;
  }
}

.diamonds__intro {
  width: 100%;
  height: 100%;
  border-bottom: 15px solid $white;
  color: $white;
  position: relative;
  display: table;
  overflow: hidden;
  @media screen and (max-width: $medium_breakpoint) {
    border-bottom: 8px solid $white;
  }
}

.diamonds__intro__content {
  width: 100%;
  height: 100%;
  display: table;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $black;
    z-index: 1;
    transition: transform $reveal_duration $reveal_timing_function,
    opacity $reveal_duration $reveal_timing_function;
  }
  .reveal &:after {
    transform: scale(0.9);
    opacity: 0;
  }
}

.diamonds__intro__title__frame {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  z-index: 3;
  transition: transform $reveal_duration $reveal_timing_function 1.2s,
  opacity $reveal_duration $reveal_timing_function 1.2s;
  .reveal & {
    transform: scale(0.9);
    opacity: 0;
    .diamonds__intro__paragraph {
      opacity: 0;
    }
  }
}

.diamonds__intro__title__main {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  padding: 100px 0;
  @media screen and (max-width: $small_breakpoint) {
    padding: 200px 0 100px;
  }
}

.diamonds__intro__title {
  padding-left: 34%;
  padding-right: 20px;
  display: inline-block;
  width: 100%;
  h1 {
    font-family: $JannonAntiqua;
    font-size: viewport_scale(1.5);
    font-weight: bold;
    width: 90%;
    margin-bottom: 25px;
    padding-right: 80px;
    line-height: 1.25;
    text-shadow: 0 0 30px $black;
    span {
      display: inline-block;
      transform: none;
      opacity: 1;
    }
    .intro {
      font-size: viewport_scale(0.8);
      display: block;
      font-weight: normal;
      margin-left: 4vw;
      margin-top: 20px;
    }
  }
  @media screen and (max-width: $large_breakpoint) {
    h1 {
      width: 100%;
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    h1 {
      padding-right: 20px;
    }
  }
  @media screen and (max-width: $small_breakpoint) {
    padding: 0 20px;
    h1 {
      .intro {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}

.diamonds__intro__paragraph {
  width: 100%;
  padding: 0 16% 0 16%;
  letter-spacing: 1px;
  line-height: 1.4;
  font-size: viewport_scale(0.3);
  max-width: 1200px;
  transition: opacity $reveal_duration $reveal_timing_function 1.3s;
  p {
    width: 100%;
    a {
      display: inline-block;
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid rgba($white, 0.5);
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 0;
        height: 1px;
        transition: width 0.3s ease-in-out;
        background: $white;
      }
      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: $large_breakpoint) {
    font-size: 15px;
  }
  @media screen and (max-width: $small_breakpoint) {
    padding: 0;
  }
}

.diamonds__intro__frame {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
  z-index: 4;
  @media screen and (max-width: $small_breakpoint) {
    z-index: 2;
  }
}

.diamonds__image__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform $reveal_duration $reveal_timing_function 1s,
  opacity $reveal_duration $reveal_timing_function 1s;
  .reveal & {
    transform: scale(0.9);
    opacity: 0;
  }
  @for $i from 0 through 7 {
    &.data_id_#{$i} {
      transition-delay: 0.8s + $i * 0.1s;
    }
  }
}

.diamonds__intro__scroll {
  width: 50px;
  height: 50px;
  padding: 12px;
  display: block;
  position: absolute;
  bottom: 25px;
  left: 50%;
  margin-left: -25px;
  z-index: 5;
  transition: opacity $reveal_duration $reveal_timing_function 2s;
  svg {
    fill: $gray;
    width: 100%;
    height: 100%;
    display: block;
  }
  .reveal & {
    opacity: 0;
  }
}

.diamonds__image__frame {
  transition: transform $reveal_duration $reveal_timing_function,
  opacity $reveal_duration $reveal_timing_function;
}

.diamonds__image {
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  transition: filter 0.2s $easeOutQuart;
  img {
    display: block;
    height: auto;
    width: auto;
  }
  .data_id_1 & {
    top: 48%;
    left: 37%;
    transform: translateX(-100%) translateY(0);
    .diamonds__image__frame {
      transition-delay: 0.3s;
    }
    img {
      height: viewport_scale(9);
    }
    @media screen and (max-width: $large_breakpoint) {
      top: 45%;
      left: 25%;
    }
    @media screen and (max-width: $small_breakpoint) {
      top: 16%;
      left: 60%;
      transform: translateX(-60%) translateY(0);
      img {
        height: viewport_scale(8);
      }

    }
  }
  .data_id_2 & {
    top: 83%;
    left: 74%;
    .diamonds__image__frame {
      transition-delay: 0.4s;
    }
    img {
      height: viewport_scale(3.5);
    }
    @media screen and (max-width: $small_breakpoint) {
      top: 92%;
    }
  }
  .data_id_3 & {
    top: 25.5%;
    left: 21%;
    .diamonds__image__frame {
      transition-delay: 0.5s;
    }
    img {
      height: viewport_scale(4.2);
    }
  }
  .data_id_4 & {
    top: 82%;
    left: 17%;
    .diamonds__image__frame {
      transition-delay: 0.6s;
    }
    img {
      height: viewport_scale(3);
    }
    @media screen and (max-width: $small_breakpoint) {
      top: 90%;
    }
  }
  .data_id_5 & {
    top: 11%;
    left: 10%;
    .diamonds__image__frame {
      transition-delay: 0.7s;
    }
    img {
      height: viewport_scale(2.3);
    }
  }
  .data_id_6 & {
    top: 13%;
    left: 75%;
    .diamonds__image__frame {
      transition-delay: 0.8s;
    }
    img {
      height: viewport_scale(4);
    }
    @media screen and (max-width: $small_breakpoint) {
      display: none;
    }
  }
  .data_id_7 & {
    top: 27%;
    left: 90%;
    .diamonds__image__frame {
      transition-delay: 0.9s;
    }
    img {
      height: viewport_scale(2.4);
    }
  }
}

#diamond_configurator {
  width: 100%;
  background: $light_black;
  border-bottom: 15px solid $white;
  padding: 200px 80px;
  color: $white;
  &:after {
    content: '';
    display: block;
    clear: both;
    float: none;
  }
  @for $i from 0 through 20 {
    &[data-shape="#{$i}"] {
      .diamond_configurator__preview__image {
        img.clear.data_shape_id_#{$i} {
          display: block;
        }
      }
    }
    @for $ii from 1 through 3 {
      &[data-shape="#{$i}"][data-color="#{$ii}"] {
        .diamond_configurator__preview__image__hue {
          img.data_shape_id_#{$i}.data_color_id_#{$ii} {
            display: block;
          }
        }
      }
    }
    &[data-color="0"][data-shape="#{$i}"] {
      .diamond_configurator__preview__image__hue {
        img.yellow.data_shape_id_#{$i} {
          display: block;
        }
      }
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    padding: 100px 30px;
    border-bottom: 8px solid $white;
  }
}

.diamond_configurator__content {
  max-width: 1000px;
  margin: 0 auto;
  @media screen and (max-width: $medium_breakpoint) {
    max-width: 450px;
  }
}

.diamond_configurator__visual {
  float: right;
  width: 45%;
  margin-left: 10%;
  @media screen and (max-width: $medium_breakpoint) {
    float: none;
    width: 100%;
    margin: 0;
    .action_controls {
      display: none;
    }
  }
}

.diamond_configurator__color {
  margin: 30px 0 0 0;
  text-align: center;
  input {
    display: none;
  }
}

.diamond_configurator__color__list {
  display: inline-block;
  white-space: nowrap;
  font-size: 0;
  li {
    display: inline-block;
    a {
      width: 50px;
      height: 50px;
      display: block;
      position: relative;
      &:before, &:after {
        content: '';
        display: block;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -13px;
        margin-left: -13px;
      }
      &:before {
        background: $white;
        transform: scale(0.8);
      }
      &:after {
        width: 36px;
        height: 36px;
        border: 3px solid $light_blue;
        margin-top: -18px;
        margin-left: -18px;
        opacity: 0;
      }
      &.pink {
        &:before {
          background: #BD5AA3;
        }
      }
      &.yellow {
        &:before {
          background: #D8D06D;
        }
      }
      &.blue {
        &:before {
          background: #5F82CA;
        }
      }
    }
    @for $i from 0 through 5 {
      #diamond_configurator[data-color="#{$i}"] & {
        &.data_id_#{$i} {
          a {
            &:before {
              transform: none;
            }
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.diamond_configurator__preview {
  width: 100%;
}

.diamond_configurator__preview__image {
  position: relative;
  width: 100%;
  padding-bottom: 80%;
  overflow: hidden;
  img {
    position: absolute;
    top: -20%;
    left: 0;
    width: 100%;
    height: auto;
    display: none;
  }
}

.diamond_configurator__preview__image__hue {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.diamond_configurator__detail {
  float: right;
  width: 45%;
  .action_controls {
    display: none;
    margin-top: 50px;
  }
  @media screen and (max-width: $medium_breakpoint) {
    float: none;
    width: 100%;
    .action_controls {
      display: block;
    }
  }
}

.diamond_configurator__detail__item {
  margin-top: 40px;
  text-align: center;
  position: relative;
  &:first-child {
    margin-top: 0;
  }
}

#diamond_configurator__color {
  display: none;
  #diamond_configurator[data-color="0"] & {
    display: block;
  }
}

#diamond_configurator__fancy {
  display: block;
  &.loaded {
    #diamond_configurator[data-color="0"] & {
      display: none;
    }
  }
}

.diamond_configurator__detail__info {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  margin: 15px 0 0 0;
  text-transform: uppercase;
  color: $white;
  text-decoration: none;
  letter-spacing: 0.1em;
  line-height: 20px;
  height: 20px;
  vertical-align: middle;
}

.diamond_configurator__detail__info.color {
  display: none;
  &.fancy {
    display: inline-block;
  }
}

#diamond_configurator[data-color="0"] {
  .diamond_configurator__detail__info.color {
    display: inline-block;
    &.fancy {
      display: none;
    }
  }
}

a.diamond_configurator__detail__info {
  &:after {
    content: '?';
    width: 20px;
    height: 20px;
    background: $light_blue;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-family: $Roboto;
    font-size: 12px;
    letter-spacing: 0;
    margin-left: 5px;
    line-height: 18px;
  }
}