.product_navigation__frame {
  width: 100%;
  position: relative;
  .overlay_close {
    right: auto !important;
    left: 40px;
    @media screen and (max-width: $medium_breakpoint) {
      left: 30px;
    }
    @media screen and (max-width: $small_breakpoint) {
      left: 15px;
    }
  }
}

.product_navigation__main {
  width: 100%;
  height: 100vh;
  max-width: 1600px;
  display: table;
  margin: 0 auto;
  .overlay__title__frame {
    display: table-row;
    width: 100%;
    @media screen and (max-width: $medium_breakpoint) {
      display: block;
    }
  }
  .overlay__foter {
    display: table-row;
    width: 100%;
    text-align: left;
    @media screen and (max-width: $medium_breakpoint) {
      display: block;
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    display: block;
    height: 100%;
  }
}

.product_navigation__content {
  width: 100%;
  height: 100%;
  display: table-row;
  @media screen and (max-width: $medium_breakpoint) {
    display: block;
  }
}

.product_navigation {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  padding: 30px 0 50px;
  text-align: center;
  font-size: 0;
  white-space: nowrap;
  transform: translateX(33.3333%);
  transition: transform 0.5s $easeOutQuad;
  will-change: transform;
  &[data-active] {
    transform: none;
    .product_navigation__page--main {
      border-color: $dark_light_gray;
    }
    .product_navigation__page--detail {
      opacity: 1;
    }
    ul {
      transition-delay: 0s;
    }
  }
  @for $i from 0 through 10 {
    &[data-active="#{$i}"] {
      ul[data-id="#{$i}"] {
        max-height: 100%;
        overflow: visible;
        transition-delay: 0s;
      }
      .product_navigation__page--main li {
        a {
          opacity: 0.15;
        }
        &[data-id="#{$i}"] {
          a {
            opacity: 1;
          }
        }
      }
    }
  }
  @media screen and (max-width: $large_breakpoint) {
    transform: translateX(25%);
  }
  @media screen and (max-width: $medium_breakpoint) {
    display: block;
    transform: none;
    vertical-align: top;
    &[data-active] {
      transform: translateX(-100%);
    }
  }
}

.product_navigation__page {
  display: inline-block;
  padding: 0 40px;
  vertical-align: middle;
  text-align: left;
  li {
    border-top: 1px solid $dark_light_gray;
    transition: transform 1s $easeOutQuad, opacity 1s $easeOutQuad;
    @for $i from 0 through 10 {
      &[data-id="#{$i}"] {
        transition-delay: 0.1s * $i + 0.3s;
      }
    }
    &:first-child {
      border-top: none;
    }
    ._reveal &, .reveal & {
      transform: translateY($reveal_delta);
      opacity: 0;
      transition-delay: 0s !important;
    }
  }
  a {
    font-size: 30px;
    padding: 28px 30px 20px;
    line-height: 1.35;
    display: inline-block;
    width: 100%;
    color: $black;
    text-decoration: none;
    font-family: $JannonAntiqua;
    opacity: 1;
    transition: opacity 0.5s, transform 0.3s;
    transform: none;
    white-space: normal;
    &:hover {
      transform: translateX(10px);
      opacity: 1 !important;
    }
    @media screen and (max-width: $large_breakpoint) {
      font-size: 22px;
      padding: 21px 30px 15px;
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    vertical-align: top;
  }
  @media screen and (max-width: $small_breakpoint) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.product_navigation__page__back {
  opacity: 0.4;
  svg {
    vertical-align: bottom;
    height: 30px;
    position: relative;
    top: -5px;
    @media screen and (max-width: $large_breakpoint) {
      top: -1px;
    }
  }
  a {
    padding: 10px 0;
    font-family: $FreightSansPro;
    font-size: 18px;
    line-height: 2.3;
    letter-spacing: 0.23em;
    font-weight: 600;
    text-transform: uppercase;
    &:hover {
      transform: translateX(-10px);
    }
    @media screen and (max-width: $large_breakpoint) {
      font-size: 13px;
    }
  }
}

.product_navigation__page--main {
  width: 33.33333%;
  border-right: 1px solid rgba($dark_light_gray, 0);
  transition: border-color 0.5s $easeOutQuad;
  @media screen and (max-width: $large_breakpoint) {
    width: 50%;
  }
  @media screen and (max-width: $medium_breakpoint) {
    width: 100%;
  }
}

.product_navigation__page--detail {
  width: 66.66666%;
  opacity: 0;
  transition: opacity 0.5s $easeOutQuad;
  @media screen and (max-width: $large_breakpoint) {
    width: 50%;
  }
  @media screen and (max-width: $medium_breakpoint) {
    width: 100%;
  }
}

.product_navigation__page__detail {
  position: relative;
  width: 100%;
  white-space: normal;
  ul {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0s 0.5s;
  }
}