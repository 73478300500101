*, *:before, *:after {
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
}

body {
  font-family: $FreightSansPro;
  font-size: 16px;
  color: $near_black;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $white;
  overflow-x: hidden;
  transition: background 0s 1s;
}

#canvas {
  height: 100%;
  width: 100%;
  transform: none;
  opacity: 1;
  top: 0;
  left: 0;
  transition: transform 1s $easeOutQuart, opacity 1s $easeOutQuart;
  position: relative;
  z-index: 10;
  background: $white;
  &.hidden {
    display: none;
  }
  &.transition {
    transform: scale(1.1);
    opacity: 0;
    transition: transform 0.5s $easeInQuart, opacity 0.5s $easeInQuart !important;
  }
}

.canvas__main {
  width: 100%;
  height: 100%;
  position: relative;
}

.cursor {
  transition: transform 0.1s $easeOutQuart;
  will-change: transform;
}

p {
  line-height: 1.4;
}

.text_link {
  text-decoration: none;
  color: $bronze;
  display: inline-block;
  position: relative;
  line-height: 1.4;
  &:before, &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: currentColor;
  }
  &:before {
    opacity: 0.7;
  }
  &:after {
    width: 0;
    background: currentColor;
    transition: width 0.3s ease-in-out;
  }
  &:hover {
    &:after {
      width: 100%;
    }
  }
  .black & {
    color: $taube;
  }
  .watches & {
    color: $mint;
  }
  .bridal & {
    color: $bridal;
  }
}

.button {
  font-size: 14px;
  font-weight: bold;
  color: $near_black;
  background: $white;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  display: inline-block;
  margin: 0 0 0 20px;
  text-decoration: none;
  line-height: 1.4;
  padding: 11px 20px;
  text-align: center;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 0;
  &:first-child {
    margin-left: 0;
  }
}

.button--secondary {
  color: $white;
  background: rgba($white, 0.3);
}

.button--blue {
  color: $white;
  background: $blue;
}

.button--ghost {
  color: $white;
  background: transparent;
  border: 1px solid $white;
  transition: color 0.3s, background 0.3s;
  &:hover {
    background: $white;
    color: $near_black;
  }
}

.button--white, .button--black {
  display: inline-block;
  line-height: 1.3;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.22em;
  text-decoration: none;
  color: $near_black;
  text-transform: uppercase;
  padding: 12px 40px;
  background: $white;
  margin-top: 50px;
  transition: background 0.2s, color 0.2s;
  &:hover {
    background: $near_black;
    color: $white;
  }
}

.button--black {
  background: $near_black;
  color: $white;
  &:hover {
    background: $white;
    color: $near_black;
  }
}

.split_image {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px 80px;
  white-space: nowrap;
}

.split_image__item {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  &:last-child {
    margin-top: 40px;
  }
  img {
    display: block;
    width: 106%;
    margin-left: -3%;
    height: auto;
  }
}