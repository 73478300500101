@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("//hello.myfonts.net/count/34b59e");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: 'JannonAntiqua';
  src: url("../fonts/Jannon/34B59E_1_0.woff2") format("woff2"), url("../fonts/Jannon/34B59E_1_0.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'JannonAntiqua';
  src: url("../fonts/JannonBold/34B59E_0_0.woff2") format("woff2"), url("../fonts/JannonBold/34B59E_0_0.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

*, *:before, *:after {
  box-sizing: border-box; }

html {
  height: 100%;
  width: 100%; }

body {
  font-family: "freight-sans-pro", sans-serif;
  font-size: 16px;
  color: #333333;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FFFFFF;
  overflow-x: hidden;
  transition: background 0s 1s; }

#canvas {
  height: 100%;
  width: 100%;
  transform: none;
  opacity: 1;
  top: 0;
  left: 0;
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
  z-index: 10;
  background: #FFFFFF; }
  #canvas.hidden {
    display: none; }
  #canvas.transition {
    transform: scale(1.1);
    opacity: 0;
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22), opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) !important; }

.canvas__main {
  width: 100%;
  height: 100%;
  position: relative; }

.cursor {
  transition: transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: transform; }

p {
  line-height: 1.4; }

.text_link {
  text-decoration: none;
  color: #BFBCAB;
  display: inline-block;
  position: relative;
  line-height: 1.4; }
  .text_link:before, .text_link:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: currentColor; }
  .text_link:before {
    opacity: 0.7; }
  .text_link:after {
    width: 0;
    background: currentColor;
    transition: width 0.3s ease-in-out; }
  .text_link:hover:after {
    width: 100%; }
  .black .text_link {
    color: #4d3a39; }
  .watches .text_link {
    color: #2a3954; }
  .bridal .text_link {
    color: #BFBCAB; }

.button {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  background: #FFFFFF;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  display: inline-block;
  margin: 0 0 0 20px;
  text-decoration: none;
  line-height: 1.4;
  padding: 11px 20px;
  text-align: center;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 0; }
  .button:first-child {
    margin-left: 0; }

.button--secondary {
  color: #FFFFFF;
  background: rgba(255, 255, 255, 0.3); }

.button--blue {
  color: #FFFFFF;
  background: #2a3954; }

.button--ghost {
  color: #FFFFFF;
  background: transparent;
  border: 1px solid #FFFFFF;
  transition: color 0.3s, background 0.3s; }
  .button--ghost:hover {
    background: #FFFFFF;
    color: #333333; }

.button--white, .button--black {
  display: inline-block;
  line-height: 1.3;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.22em;
  text-decoration: none;
  color: #333333;
  text-transform: uppercase;
  padding: 12px 40px;
  background: #FFFFFF;
  margin-top: 50px;
  transition: background 0.2s, color 0.2s; }
  .button--white:hover, .button--black:hover {
    background: #333333;
    color: #FFFFFF; }

.button--black {
  background: #333333;
  color: #FFFFFF; }
  .button--black:hover {
    background: #FFFFFF;
    color: #333333; }

.split_image {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px 80px;
  white-space: nowrap; }

.split_image__item {
  width: 50%;
  display: inline-block;
  vertical-align: top; }
  .split_image__item:last-child {
    margin-top: 40px; }
  .split_image__item img {
    display: block;
    width: 106%;
    margin-left: -3%;
    height: auto; }

/* Ion.RangeSlider
// css version 2.0.3
// © 2013-2014 Denis Ineshin | IonDen.com
// ===================================================================================================================*/
/* =====================================================================================================================
// RangeSlider */
.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
  height: 20px;
  top: 25px; }

.irs-line-left, .irs-line-mid, .irs-line-right {
  position: absolute;
  display: block;
  top: 0; }

.irs-line-left {
  left: 0;
  width: 11%; }

.irs-line-mid {
  left: 9%;
  width: 82%; }

.irs-line-right {
  right: 0;
  width: 11%; }

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0; }

.irs-bar-edge {
  position: absolute;
  display: block;
  top: 0;
  left: 0; }

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0; }

.irs-slider {
  position: absolute;
  display: block;
  cursor: default;
  z-index: 1; }

.irs-slider.type_last {
  z-index: 2; }

.irs-min {
  position: absolute;
  display: block;
  left: 0;
  cursor: default; }

.irs-max {
  position: absolute;
  display: block;
  right: 0;
  cursor: default; }

.irs-from, .irs-to, .irs-single {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap; }

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px; }

.irs-with-grid .irs-grid {
  display: block; }

.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000; }

.irs-grid-pol.small {
  height: 4px; }

.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #000; }

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2; }

.irs-disabled {
  opacity: 0.4; }

.lt-ie9 .irs-disabled {
  filter: alpha(opacity=40); }

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important; }

/* Ion.RangeSlider, Nice Skin
// css version 2.0.3
// © Denis Ineshin, 2014    https://github.com/IonDen
// ===================================================================================================================*/
/* =====================================================================================================================
// Skin details */
.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge,
.irs-slider {
  background: none; }

.irs {
  height: 60px; }

.irs-with-grid {
  height: auto;
  padding-bottom: 20px; }

.irs-line-left {
  height: 8px;
  background-position: 0 -30px; }

.irs-line-mid {
  height: 8px;
  background-position: 0 0; }

.irs-line-right {
  height: 8px; }

.irs-bar {
  height: 20px;
  top: 25px;
  background: #636363; }

.irs-bar-edge {
  top: 25px;
  height: 8px;
  width: 11px; }

.irs-shadow {
  height: 1px;
  top: 34px;
  background: #000;
  opacity: 0.15; }

.lt-ie9 .irs-shadow {
  filter: alpha(opacity=15); }

.irs-slider {
  width: 22px;
  height: 22px;
  top: 17px;
  background-position: 0 -120px; }

.irs-slider.state_hover, .irs-slider:hover {
  background-position: 0 -150px; }

.irs-min, .irs-max {
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 1px 3px;
  background: rgba(255, 255, 255, 0.1);
  -moz-border-radius: 3px;
  border-radius: 3px; }

.lt-ie9 .irs-min, .lt-ie9 .irs-max {
  background: #ccc; }

.irs-from, .irs-to, .irs-single {
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background: rgba(0, 0, 0, 0.3);
  -moz-border-radius: 3px;
  border-radius: 3px; }

.lt-ie9 .irs-from, .lt-ie9 .irs-to, .lt-ie9 .irs-single {
  background: #FFFFFF; }

.irs-grid-pol {
  background: #FFFFFF; }

.irs-grid-text {
  color: #FFFFFF; }

.filter__rangeslider {
  height: auto;
  width: 100%;
  font-size: 18px;
  position: relative;
  color: #333333;
  margin-bottom: 20px; }

.irs-line-left, .irs-line-mid, .irs-line-right {
  margin: 9px 0;
  height: 2px;
  background: #FFFFFF; }

.irs-line-left:before, .irs-line-right:before {
  content: '';
  display: block;
  width: 2px;
  height: 20px;
  position: absolute;
  top: -9px;
  background: #FFFFFF; }

.irs-line-left:before {
  left: 0; }

.irs-line-right:before {
  right: 0; }

.irs-slider.from, .irs-slider.to {
  width: 50px;
  height: 50px;
  margin-top: -15px;
  top: 25px;
  padding: 15px; }
  .irs-slider.from:after, .irs-slider.to:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #FFFFFF; }

#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  z-index: 500;
  overflow: hidden; }
  #header:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: #FFFFFF; }
  body.transparentheader #header {
    position: absolute;
    top: 16px; }
    body.transparentheader #header:after {
      transform: translateY(-100%);
      transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1); }
    body.transparentheader #header.fixed {
      position: fixed;
      top: 0; }
      body.transparentheader #header.fixed:after {
        transform: none; }
    @media screen and (max-width: 840px) {
      body.transparentheader #header {
        top: 8px; } }
  body.noheader #header {
    display: none; }
  body.logoheader #header {
    top: 16px; }
    body.logoheader #header:after {
      opacity: 0; }
    @media screen and (max-width: 840px) {
      body.logoheader #header {
        top: 8px; } }
  body.whiteheader #header {
    color: #FFFFFF;
    transition: color 1s cubic-bezier(0.165, 0.84, 0.44, 1); }
    body.whiteheader #header.fixed {
      color: #333333; }
  @media screen and (max-width: 840px) {
    #header {
      height: 80px; } }

.header__navigation {
  position: absolute;
  top: 0;
  padding: 0 20px;
  text-decoration: none;
  display: block;
  text-transform: uppercase;
  line-height: 100px;
  font-size: 13px;
  letter-spacing: 3px;
  color: inherit;
  font-weight: 500; }
  .header__navigation:before {
    height: 100px;
    width: 20px;
    position: absolute;
    top: 0;
    font-size: 20px; }
  body.logoheader .header__navigation {
    display: none; }
  @media screen and (max-width: 840px) {
    .header__navigation {
      line-height: 80px; }
      .header__navigation:before {
        height: 80px; } }
  @media screen and (max-width: 600px) {
    .header__navigation {
      font-size: 10px;
      white-space: nowrap; } }

.header__navigation__icon {
  position: absolute;
  display: block;
  width: 20px;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%); }
  .header__navigation__icon svg {
    fill: currentColor;
    display: block;
    width: 100%;
    height: auto; }

.header__navigation--products {
  left: 0;
  padding-left: 90px;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1); }
  body.delayheader .header__navigation--products {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1s; }
  .reveal .header__navigation--products {
    transform: translateY(-100%);
    opacity: 0; }
    .reveal .header__navigation--products:after {
      opacity: 0 !important; }
  .header__navigation--products .header__navigation__icon {
    left: 50px; }
  @media screen and (max-width: 840px) {
    .header__navigation--products {
      padding-left: 70px; }
      .header__navigation--products .header__navigation__icon {
        left: 30px; }
      .header__navigation--products:after {
        left: 8px !important; } }
  @media screen and (max-width: 600px) {
    .header__navigation--products {
      padding-left: 60px; } }

.header__navigation--basket {
  right: 0;
  padding-right: 90px;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1s; }
  body.delayheader .header__navigation--basket {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.8s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.8s; }
  .reveal .header__navigation--basket {
    transform: translateY(-100%);
    opacity: 0; }
  .header__navigation--basket .header__navigation__icon {
    right: 50px; }
  @media screen and (max-width: 840px) {
    .header__navigation--basket {
      padding-right: 70px; }
      .header__navigation--basket .header__navigation__icon {
        right: 30px; } }

.header__navigation__amount {
  position: absolute;
  top: -7px;
  right: -6px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #2a3954;
  color: #FFFFFF;
  line-height: 15px;
  font-size: 8px;
  font-family: sans-serif;
  display: block;
  text-align: center;
  letter-spacing: -0.1px; }

.logo {
  display: inline-block;
  height: 20px;
  margin: 40px 0;
  text-decoration: none;
  color: inherit;
  opacity: 1;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s; }
  .reveal .logo {
    transform: translateY(-100%);
    opacity: 0; }
  .logo svg {
    height: auto;
    width: 135px;
    fill: currentColor; }
  @media screen and (max-width: 840px) {
    .logo {
      margin: 30px 0; } }
  @media screen and (max-width: 600px) {
    .logo {
      height: 16px;
      margin: 32px 0; }
      .logo svg {
        height: auto;
        width: 108px; } }

body.delayheader .header__logo {
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.4s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.4s; }

body.logoheader .header__logo svg {
  fill: currentColor; }

#share {
  width: 100%;
  padding: 25px 20px;
  background: #BFBCAB;
  text-align: center;
  color: #BFBCAB;
  border-top: 16px solid #FFFFFF; }
  @media screen and (max-width: 840px) {
    #share {
      border-top: 8px solid #FFFFFF; } }

.share__title {
  line-height: 35px;
  display: inline-block;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  vertical-align: bottom;
  color: #FFFFFF; }

.share__list {
  margin-left: 15px;
  display: inline-block;
  vertical-align: bottom; }

.share__item {
  margin-left: 15px;
  display: inline-block; }
  .share__item a {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #FFFFFF;
    padding: 10px;
    color: inherit; }
    .share__item a svg {
      width: 100%;
      height: 100%;
      fill: currentColor; }

#brand {
  color: #FFFFFF;
  padding: 80px 20px;
  background: url("/static/img/blesq_diamond.jpg");
  background-size: cover;
  background-position: center center;
  text-align: center;
  position: relative;
  border-top: 16px solid #FFFFFF; }
  #brand .button {
    margin-top: 350px; }
  #brand h2, #brand p, #brand a {
    position: relative;
    z-index: 2; }
  #brand:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #000000;
    opacity: 0.3; }
  @media screen and (max-width: 840px) {
    #brand {
      border-top: 8px solid #FFFFFF; }
      #brand .button {
        margin-top: 250px; } }

#landing {
  height: 90%;
  width: 100%;
  border: 16px solid #FFFFFF;
  border-bottom: none;
  overflow: hidden;
  background: #FFFFFF; }
  #landing + #footer {
    margin-top: -16px; }
  @media screen and (max-width: 840px) {
    #landing {
      border: 8px solid #FFFFFF; }
      #landing + #footer {
        margin-top: -8px; } }

.landing__slider {
  position: relative;
  width: 100%;
  height: 100%; }
  .landing__slider[data-active="0"] .landing__slider__content {
    transform: translateX(0%); }
  .landing__slider[data-active="0"] .landing__slider__navigation__button__list {
    transform: translateX(0%); }
    .landing__slider[data-active="0"] .landing__slider__navigation__button__list a.data_id_0 {
      opacity: 1; }
  .landing__slider[data-active="0"] .landing__slider__navigation__line {
    transform: translateX(0px); }
    @media screen and (max-width: 840px) {
      .landing__slider[data-active="0"] .landing__slider__navigation__line {
        transform: translateX(0px); } }
  .landing__slider[data-active="0"] .landing__slider__item.data_id_0 .landing__slider__item__headline__frame {
    transform: none;
    opacity: 1; }
  .landing__slider[data-active="1"] .landing__slider__content {
    transform: translateX(-100%); }
  .landing__slider[data-active="1"] .landing__slider__navigation__button__list {
    transform: translateX(-100%); }
    .landing__slider[data-active="1"] .landing__slider__navigation__button__list a.data_id_1 {
      opacity: 1; }
  .landing__slider[data-active="1"] .landing__slider__navigation__line {
    transform: translateX(-180px); }
    @media screen and (max-width: 840px) {
      .landing__slider[data-active="1"] .landing__slider__navigation__line {
        transform: translateX(-150px); } }
  .landing__slider[data-active="1"] .landing__slider__item.data_id_1 .landing__slider__item__headline__frame {
    transform: none;
    opacity: 1; }
  .landing__slider[data-active="2"] .landing__slider__content {
    transform: translateX(-200%); }
  .landing__slider[data-active="2"] .landing__slider__navigation__button__list {
    transform: translateX(-200%); }
    .landing__slider[data-active="2"] .landing__slider__navigation__button__list a.data_id_2 {
      opacity: 1; }
  .landing__slider[data-active="2"] .landing__slider__navigation__line {
    transform: translateX(-360px); }
    @media screen and (max-width: 840px) {
      .landing__slider[data-active="2"] .landing__slider__navigation__line {
        transform: translateX(-300px); } }
  .landing__slider[data-active="2"] .landing__slider__item.data_id_2 .landing__slider__item__headline__frame {
    transform: none;
    opacity: 1; }
  .landing__slider[data-active="3"] .landing__slider__content {
    transform: translateX(-300%); }
  .landing__slider[data-active="3"] .landing__slider__navigation__button__list {
    transform: translateX(-300%); }
    .landing__slider[data-active="3"] .landing__slider__navigation__button__list a.data_id_3 {
      opacity: 1; }
  .landing__slider[data-active="3"] .landing__slider__navigation__line {
    transform: translateX(-540px); }
    @media screen and (max-width: 840px) {
      .landing__slider[data-active="3"] .landing__slider__navigation__line {
        transform: translateX(-450px); } }
  .landing__slider[data-active="3"] .landing__slider__item.data_id_3 .landing__slider__item__headline__frame {
    transform: none;
    opacity: 1; }

.landing__slider__content {
  width: 100%;
  height: 100%;
  font-size: 0;
  white-space: nowrap;
  transition: transform 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: transform; }

.landing__slider__item {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative; }

.landing__slider__item__content {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .landing__slider__item__content span {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(center, rgba(0, 0, 0, 0.75), #000000);
    opacity: 0.3; }
  .landing__slider__item__content:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 200px;
    display: block;
    background-image: radial-gradient(ellipse at top left, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    z-index: 1;
    will-change: transform; }
  .reveal .landing__slider__item__content {
    transform: scale(0.9);
    opacity: 0; }

.landing__slider__item__headline__frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  z-index: 5;
  transform: scale(0.9);
  opacity: 0; }
  .reveal .landing__slider__item__headline__frame {
    transform: scale(0.9) !important;
    opacity: 0 !important; }

.landing__slider__item__headline {
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) translateY(-20px);
  display: inline-block;
  padding: 20px 40px;
  max-width: 1700px;
  width: 100%;
  white-space: normal;
  text-align: center; }
  .landing__slider__item__headline h2 {
    color: #FFFFFF;
    font-size: calc(1.5vw + 1.5vh + 10px);
    font-family: "JannonAntiqua", serif;
    line-height: 1.5;
    letter-spacing: 0.02em;
    font-weight: bold;
    transition: box-shadow 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
    text-shadow: 0 0 3em #000000, 0 0 2em rgba(0, 0, 0, 0.5), 0 0 1em rgba(0, 0, 0, 0.8); }
    .reveal .landing__slider__item__headline h2 {
      text-shadow: none; }
  @media screen and (max-width: 840px) {
    .landing__slider__item__headline {
      max-width: 650px; }
      .landing__slider__item__headline br {
        display: none; } }

.landing__slider__navigation {
  position: absolute;
  bottom: 100px;
  width: 100%; }
  @media screen and (max-width: 840px) {
    .landing__slider__navigation {
      bottom: 40px; } }

.landing__slider__navigation__line {
  font-size: 0;
  position: relative;
  left: 50%;
  margin-left: -26px;
  white-space: nowrap;
  transition: transform 1.5s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .landing__slider__navigation__line a {
    display: inline-block;
    width: 51px;
    height: 51px;
    margin-left: 130px;
    position: relative; }
    .landing__slider__navigation__line a:before {
      content: '';
      width: 11px;
      height: 11px;
      position: absolute;
      border-radius: 50%;
      background: #FFFFFF;
      top: 20px;
      left: 20px;
      transform: none;
      transition: transform 0.3s; }
    .landing__slider__navigation__line a:after {
      content: '';
      position: absolute;
      top: 25px;
      height: 1px;
      background: #FFFFFF;
      left: 40px;
      width: 150px; }
    .landing__slider__navigation__line a:hover:before {
      transform: scale(1.2); }
    .landing__slider__navigation__line a:first-child {
      margin: 0; }
    .landing__slider__navigation__line a:last-child:after {
      display: none; }
    .landing__slider__navigation__line a.data_id_0 {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.4s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.4s; }
      .landing__slider__navigation__line a.data_id_0:after {
        transition: width 0.5s linear 3.5s, opacity 0.5s linear 3.5s; }
      .reveal .landing__slider__navigation__line a.data_id_0 {
        opacity: 0;
        transform: translateY(100px); }
        .reveal .landing__slider__navigation__line a.data_id_0:after {
          width: 0;
          opacity: 0; }
    .landing__slider__navigation__line a.data_id_1 {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.7s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.7s; }
      .landing__slider__navigation__line a.data_id_1:after {
        transition: width 0.5s linear 4.1s, opacity 0.5s linear 4.1s; }
      .reveal .landing__slider__navigation__line a.data_id_1 {
        opacity: 0;
        transform: translateY(100px); }
        .reveal .landing__slider__navigation__line a.data_id_1:after {
          width: 0;
          opacity: 0; }
    .landing__slider__navigation__line a.data_id_2 {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2s; }
      .landing__slider__navigation__line a.data_id_2:after {
        transition: width 0.5s linear 4.7s, opacity 0.5s linear 4.7s; }
      .reveal .landing__slider__navigation__line a.data_id_2 {
        opacity: 0;
        transform: translateY(100px); }
        .reveal .landing__slider__navigation__line a.data_id_2:after {
          width: 0;
          opacity: 0; }
    .landing__slider__navigation__line a.data_id_3 {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.3s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.3s; }
      .landing__slider__navigation__line a.data_id_3:after {
        transition: width 0.5s linear 5.3s, opacity 0.5s linear 5.3s; }
      .reveal .landing__slider__navigation__line a.data_id_3 {
        opacity: 0;
        transform: translateY(100px); }
        .reveal .landing__slider__navigation__line a.data_id_3:after {
          width: 0;
          opacity: 0; }
    .landing__slider__navigation__line a.data_id_4 {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.6s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.6s; }
      .landing__slider__navigation__line a.data_id_4:after {
        transition: width 0.5s linear 5.9s, opacity 0.5s linear 5.9s; }
      .reveal .landing__slider__navigation__line a.data_id_4 {
        opacity: 0;
        transform: translateY(100px); }
        .reveal .landing__slider__navigation__line a.data_id_4:after {
          width: 0;
          opacity: 0; }
    .landing__slider__navigation__line a.data_id_5 {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.9s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.9s; }
      .landing__slider__navigation__line a.data_id_5:after {
        transition: width 0.5s linear 6.5s, opacity 0.5s linear 6.5s; }
      .reveal .landing__slider__navigation__line a.data_id_5 {
        opacity: 0;
        transform: translateY(100px); }
        .reveal .landing__slider__navigation__line a.data_id_5:after {
          width: 0;
          opacity: 0; }
    .landing__slider__navigation__line a.data_id_6 {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 3.2s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 3.2s; }
      .landing__slider__navigation__line a.data_id_6:after {
        transition: width 0.5s linear 7.1s, opacity 0.5s linear 7.1s; }
      .reveal .landing__slider__navigation__line a.data_id_6 {
        opacity: 0;
        transform: translateY(100px); }
        .reveal .landing__slider__navigation__line a.data_id_6:after {
          width: 0;
          opacity: 0; }
    .landing__slider__navigation__line a.data_id_7 {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 3.5s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 3.5s; }
      .landing__slider__navigation__line a.data_id_7:after {
        transition: width 0.5s linear 7.7s, opacity 0.5s linear 7.7s; }
      .reveal .landing__slider__navigation__line a.data_id_7 {
        opacity: 0;
        transform: translateY(100px); }
        .reveal .landing__slider__navigation__line a.data_id_7:after {
          width: 0;
          opacity: 0; }
    .landing__slider__navigation__line a.data_id_8 {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 3.8s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 3.8s; }
      .landing__slider__navigation__line a.data_id_8:after {
        transition: width 0.5s linear 8.3s, opacity 0.5s linear 8.3s; }
      .reveal .landing__slider__navigation__line a.data_id_8 {
        opacity: 0;
        transform: translateY(100px); }
        .reveal .landing__slider__navigation__line a.data_id_8:after {
          width: 0;
          opacity: 0; }
    @media screen and (max-width: 840px) {
      .landing__slider__navigation__line a {
        margin-left: 100px; }
        .landing__slider__navigation__line a:after {
          width: 120px; } }

.landing__slider__navigation__button__frame {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block; }

.landing__slider__navigation__button {
  display: inline-block;
  height: 50px;
  background: #FFFFFF;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.5s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.5s; }
  .reveal .landing__slider__navigation__button {
    opacity: 0;
    transform: translateY(100px); }

.landing__slider__navigation__button__list {
  transition: transform 1.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 100%;
  height: 100%;
  display: block; }

#instagram {
  padding: 80px 0 100px;
  overflow: hidden; }

.instagram__feed {
  margin: 80px 0 0 0; }

.instagram__feed__row {
  width: 100%;
  margin-top: 10px;
  white-space: nowrap;
  font-size: 0;
  text-align: right; }
  .instagram__feed__row:first-child {
    margin: 0 0 0 -8%; }

.instagram__feed__item {
  display: inline-block;
  width: 25%;
  margin: 0 5px;
  min-width: 150px;
  max-width: 300px; }
  .instagram__feed__item img {
    display: block;
    width: 100%;
    height: auto; }

.action_controls {
  width: 100%;
  color: inherit; }
  .action_controls select, .action_controls input[type="text"] {
    width: 100%;
    height: 40px;
    border: 1px solid #FFFFFF;
    font-family: "roboto", sans-serif;
    border-radius: 0;
    -webkit-appearance: none;
    text-indent: 16px;
    background: transparent;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF;
    letter-spacing: 0.05em;
    display: inline-block;
    text-decoration: none;
    line-height: 38px; }
    .action_controls select:first-child, .action_controls input[type="text"]:first-child {
      margin-left: 0; }
  .action_controls input[type="text"] {
    margin-top: 15px; }
    .action_controls input[type="text"]::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.6);
      text-transform: uppercase; }
    .action_controls input[type="text"]::-moz-placeholder {
      color: rgba(255, 255, 255, 0.6);
      text-transform: uppercase; }
    .action_controls input[type="text"]:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.6);
      text-transform: uppercase; }
    .action_controls input[type="text"]:-moz-placeholder {
      color: rgba(255, 255, 255, 0.6);
      text-transform: uppercase; }

.action_controls__attributes {
  margin-top: 40px; }

.action_controls__select {
  width: 48%;
  position: relative;
  display: inline-block;
  vertical-align: top; }
  .action_controls__select:first-child {
    margin-right: 4%; }
  .action_controls__select:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #FFFFFF;
    border-right: 2px solid #FFFFFF;
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    right: 20px; }
  .action_controls__select.errors select {
    border-color: red; }
  .action_controls__select a {
    display: inline-block;
    margin-top: 5px;
    font-size: 13px;
    text-decoration: none;
    color: #FFFFFF;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    transition: border 0.2s; }
    .action_controls__select a:hover {
      border-bottom-color: rgba(255, 255, 255, 0.6); }

.action_controls__price_table {
  display: none; }

.action_controls__price {
  font-family: "JannonAntiqua", serif;
  font-size: 25px;
  height: 45px;
  line-height: 45px;
  position: relative;
  border-bottom: 1px solid #FFFFFF;
  text-align: right; }
  .action_controls__price .action_controls__currency {
    position: absolute;
    top: 0;
    left: 0; }
  .action_controls__price.request {
    text-align: center; }

.action_controls__buttons {
  width: 100%;
  margin: 40px 0 0 0;
  font-size: 0;
  white-space: nowrap; }
  .action_controls__buttons .button {
    width: 48%;
    margin: 0; }
    .action_controls__buttons .button:first-child {
      margin-right: 4%; }

#quality {
  background: #F4F4F4;
  color: #333333;
  padding: 80px 20px 0;
  border-top: 16px solid #FFFFFF; }
  @media screen and (max-width: 840px) {
    #quality {
      border-top: 8px solid #FFFFFF; } }

.quality__content {
  max-width: 800px;
  margin: 0 auto; }

.quality__quote__frame {
  padding: 90px 0;
  margin: 0 auto; }
  @media screen and (max-width: 840px) {
    .quality__quote__frame {
      max-width: 400px; } }

.quality__quote {
  font-size: 30px;
  line-height: 1.5;
  font-family: "JannonAntiqua", serif;
  padding: 0 30px;
  position: relative; }
  .quality__quote:before, .quality__quote:after {
    content: '„';
    font-size: 55px;
    opacity: 0.3; }
  .quality__quote:before {
    position: absolute;
    top: -40px;
    left: 0; }
  .quality__quote:after {
    content: '“';
    position: absolute;
    margin-left: 10px;
    margin-top: 15px; }
  @media screen and (max-width: 840px) {
    .quality__quote {
      font-size: 20px;
      padding-left: 20px;
      padding-right: 20px; }
      .quality__quote:before, .quality__quote:after {
        font-size: 35px; } }

.quality__person {
  width: 100%;
  text-align: right;
  margin-top: 60px; }

.quality__person__content {
  display: inline-block;
  text-align: left; }

.quality__signature {
  width: 240px; }
  .quality__signature svg .stroke {
    stroke: currentColor !important; }

.quality__function {
  width: 100%;
  font-style: italic;
  display: block;
  margin-top: -15px; }

.quality__promise__list {
  width: 100%;
  white-space: nowrap;
  font-size: 0;
  position: relative; }
  .quality__promise__list:before, .quality__promise__list:after {
    content: '';
    position: absolute;
    display: block;
    width: 1px;
    top: 35px;
    bottom: 35px;
    background: currentColor;
    opacity: 0.3;
    left: 30%; }
  .quality__promise__list:after {
    left: auto;
    right: 34%; }
  @media screen and (max-width: 840px) {
    .quality__promise__list:before, .quality__promise__list:after {
      display: none; } }

.quality__promise__list__separator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: currentColor;
  opacity: 0.3; }
  @media screen and (max-width: 840px) {
    .quality__promise__list__separator {
      display: none; } }

.quality__promise__item {
  display: inline-block;
  vertical-align: top;
  width: 36%;
  position: relative;
  padding: 60px 30px 60px 80px;
  white-space: normal; }
  .quality__promise__item:first-child {
    width: 30%;
    padding-left: 40px; }
    .quality__promise__item:first-child .quality__promise__icon {
      left: 0; }
  .quality__promise__item:last-child {
    width: 34%;
    padding-right: 0; }
  .quality__promise__item a {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px; }
    .quality__promise__item a:last-child {
      margin-right: 0; }
  @media screen and (max-width: 840px) {
    .quality__promise__item {
      display: block;
      width: 100% !important;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      padding-left: 40px;
      padding-top: 30px;
      padding-bottom: 30px;
      max-width: 400px;
      margin: 0 auto; }
      .quality__promise__item .quality__promise__icon {
        left: 0;
        top: 30px; } }

.quality__promise__icon {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 40px;
  top: 60px; }
  .quality__promise__icon svg {
    width: 100%;
    fill: currentColor;
    height: 100%; }

.quality__promise__title {
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: 1.5;
  margin-bottom: 10px; }

.quality__promise__text {
  font-size: 12px;
  letter-spacing: 0.01em;
  line-height: 1.4;
  margin-bottom: 15px; }

#footer {
  width: 100%;
  background: #FFFFFF;
  font-size: 14px;
  padding: 25px 20px 10px; }
  #footer ul {
    display: inline-block;
    margin-bottom: 15px; }
    #footer ul li {
      display: inline-block;
      padding-right: 21px;
      position: relative; }
      #footer ul li a {
        line-height: 30px;
        text-decoration: none;
        color: #333333; }
      #footer ul li:before {
        content: '';
        display: block;
        height: 14px;
        background: #E4E4E4;
        width: 1px;
        position: absolute;
        top: 9px;
        right: 9px; }
      #footer ul li:last-child {
        padding: 0; }
        #footer ul li:last-child:before {
          display: none; }
      #footer ul li.active a {
        font-weight: 600; }
    @media screen and (max-width: 600px) {
      #footer ul {
        display: block; }
        #footer ul li {
          display: block; }
        #footer ul:last-child li:before {
          display: none; } }

.footer__meta {
  margin: 0 40px 15px 0; }
  .footer__meta li {
    display: inline-block !important; }
    .footer__meta li a {
      text-transform: uppercase; }

body.overlayopen {
  transition: background 0s 0s; }
  body.overlayopen .header__logo {
    opacity: 0;
    transition: opacity 0.2s; }
  body.overlayopen #canvas {
    transform: scale(0.9);
    opacity: 0.8;
    will-change: transform; }
  body.overlayopen .overlay.active {
    height: auto;
    min-height: 100%;
    opacity: 1;
    transform: none;
    transition: min-height 0s 0s, transform 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s, opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s; }

.overlay {
  width: 100%;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  min-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: scale(1.1);
  z-index: 500;
  transition: min-height 0s 1.1s, transform 1s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .overlay.transition {
    opacity: 0 !important;
    transform: scale(1.1) !important;
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22), opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) !important; }

.overlay__foter {
  text-align: center;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s; }
  ._reveal .overlay__foter, .reveal .overlay__foter {
    transform: translateY(100px);
    opacity: 0;
    transition: transform 0s 1s, opacity 0s 1s; }

.overlay--navigation .overlay__main {
  padding-bottom: 200px; }

.overlay__separator {
  width: 30px;
  height: 1px;
  background: #AFAFAF;
  border: none;
  display: block;
  margin: 0;
  transition: width 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 4s; }
  ._reveal .overlay__separator, .reveal .overlay__separator {
    width: 0; }

.overlay__logo {
  text-align: center; }
  .overlay--header .overlay__logo {
    position: relative;
    z-index: 5; }
    .overlay--header .overlay__logo svg {
      fill: #FFFFFF; }

.overlay_close {
  position: absolute;
  top: 25px;
  right: 40px;
  width: 50px;
  height: 50px;
  z-index: 100; }
  .overlay_close:before, .overlay_close:after {
    content: '';
    height: 1px;
    width: 30px;
    position: absolute;
    top: 25px;
    left: 50%;
    margin-left: -15px;
    background: #AFAFAF;
    transform: rotate(45deg); }
  .overlay_close:after {
    transform: rotate(-45deg); }
  .overlay--header .overlay_close:before, .overlay--header .overlay_close:after {
    background: #FFFFFF; }
  @media screen and (max-width: 840px) {
    .overlay_close {
      top: 15px;
      right: 30px; } }
  @media screen and (max-width: 600px) {
    .overlay_close {
      right: 15px; } }

.overlay__title__frame {
  width: 100%; }
  .overlay--header .overlay__title__frame {
    padding-bottom: 40px;
    color: #FFFFFF;
    position: relative; }
    .overlay--header .overlay__title__frame:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.6;
      background: #000000;
      z-index: 2; }
    .overlay--header .overlay__title__frame:before, .overlay--header .overlay__title__frame:after {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1); }
    .overlay--header .overlay__title__frame .overlay__title {
      position: relative;
      z-index: 3; }
  .overlay--header._reveal .overlay__title__frame:before, .overlay--header._reveal .overlay__title__frame:after, .overlay--header.reveal .overlay__title__frame:before, .overlay--header.reveal .overlay__title__frame:after {
    transform: scale(0.9);
    opacity: 0;
    transition: transform 0s 1s, opacity 0s 1s; }
  .overlay--header--boutique .overlay__title__frame:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-position: center;
    background-size: cover;
    background-image: url("/static/img/blesq_boutique.jpg"); }

.overlay__title {
  text-align: center;
  padding: 100px 80px 60px;
  max-width: 1200px;
  margin: 0 auto; }
  @media screen and (max-width: 840px) {
    .overlay__title {
      padding: 80px 20px; } }
  @media screen and (max-width: 600px) {
    .overlay__title {
      padding: 60px 20px; } }

.overlay__introline {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  line-height: 1.5;
  display: inline-block;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1); }
  ._reveal .overlay__introline, .reveal .overlay__introline {
    transform: translateY(100px);
    opacity: 0;
    transition: transform 0s 1s, opacity 0s 1s; }
  @media screen and (max-width: 1200px) {
    .overlay__introline {
      font-size: 14px; } }
  @media screen and (max-width: 840px) {
    .overlay__introline {
      font-size: 12px; } }

.overlay__breadcumbs {
  display: inline-block;
  font-size: 0;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1); }
  ._reveal .overlay__breadcumbs, .reveal .overlay__breadcumbs {
    transform: translateY(100px);
    opacity: 0;
    transition: transform 0s 1s, opacity 0s 1s; }
  .overlay__breadcumbs li {
    display: inline-block;
    font-size: 13px;
    letter-spacing: 0.05em;
    line-height: 20px;
    padding-left: 30px;
    position: relative; }
    .overlay__breadcumbs li:first-child {
      padding-left: 0; }
    .overlay__breadcumbs li a {
      color: inherit;
      text-decoration: none; }

.overlay__breadcumbs__icon {
  position: absolute;
  top: 0;
  left: 5px;
  height: 20px;
  width: 20px; }
  .overlay__breadcumbs__icon svg {
    display: block;
    width: 20px;
    height: 20px;
    fill: currentColor; }

.overlay__headline {
  font-family: "JannonAntiqua", serif;
  font-size: 50px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 15px;
  line-height: 1.4;
  display: block;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s; }
  ._reveal .overlay__headline, .reveal .overlay__headline {
    transform: translateY(100px);
    opacity: 0;
    transition: transform 0s 1s, opacity 0s 1s; }
  @media screen and (max-width: 1200px) {
    .overlay__headline {
      font-size: 42px; } }
  @media screen and (max-width: 840px) {
    .overlay__headline {
      font-size: 34px; } }
  @media screen and (max-width: 600px) {
    .overlay__headline {
      font-size: 24px; } }

.overlay__text {
  letter-spacing: 0.05em;
  font-size: 16px;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.6s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.6s; }
  ._reveal .overlay__text, .reveal .overlay__text {
    transform: translateY(100px);
    opacity: 0;
    transition: transform 0s 1s, opacity 0s 1s; }
  @media screen and (max-width: 840px) {
    .overlay__text {
      font-size: 14px; } }
  @media screen and (max-width: 600px) {
    .overlay__text {
      font-size: 13px; } }

.overlay__footer {
  text-align: left;
  margin: 50px 0 0 0; }
  .overlay__footer li {
    margin: 20px 0; }
    .overlay__footer li a {
      text-decoration: none;
      color: #333333;
      letter-spacing: 2px;
      font-size: 15px;
      text-transform: uppercase; }
    .overlay__footer li.data_id_0 {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2s; }
    .overlay__footer li.data_id_1 {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.15s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.15s; }
    .overlay__footer li.data_id_2 {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.3s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.3s; }
    .overlay__footer li.data_id_3 {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.45s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.45s; }
    .overlay__footer li.data_id_4 {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.6s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.6s; }
    .overlay__footer li.data_id_5 {
      transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.75s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.75s; }
    ._reveal .overlay__footer li, .reveal .overlay__footer li {
      transform: translateY(100px);
      opacity: 0; }

.overlay__meta {
  text-align: left;
  margin: 50px 0 0 0;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.5s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.5s; }
  .overlay__meta li {
    display: inline-block;
    position: relative;
    vertical-align: top;
    padding-left: 17px; }
    .overlay__meta li a {
      line-height: 20px;
      text-decoration: none;
      color: #333333;
      text-transform: uppercase; }
    .overlay__meta li:before {
      content: '';
      height: 16px;
      width: 1px;
      position: absolute;
      display: block;
      left: 7px;
      top: 2px;
      background: #E4E4E4; }
    .overlay__meta li.active a {
      font-weight: 600; }
  ._reveal .overlay__meta, .reveal .overlay__meta {
    transform: translateY(100px);
    opacity: 0;
    transition: transform 0s 1s, opacity 0s 1s; }

.overlay__meta__first {
  padding-left: 0 !important; }
  .overlay__meta__first:before {
    display: none !important; }

.overlay__meta__icon {
  margin: 4px 0;
  height: 14px;
  display: block; }
  .overlay__meta__icon svg {
    display: block;
    height: 100%;
    width: auto;
    fill: #333333; }

.overlay__meta__social {
  margin-left: 20px;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 5.5s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 5.5s; }
  .overlay__meta__social:before {
    display: none !important; }
  ._reveal .overlay__meta__social, .reveal .overlay__meta__social {
    transform: translateY(25px);
    opacity: 0;
    transition: transform 0s 1s, opacity 0s 1s; }

.overlay__main {
  padding: 0 80px 100px;
  position: relative;
  z-index: 5; }
  @media screen and (max-width: 840px) {
    .overlay__main {
      padding: 0 20px 100px; } }

.overlay__content {
  display: block;
  width: 100%;
  max-width: 850px;
  margin: 0 auto; }
  @media screen and (max-width: 840px) {
    .overlay__content {
      max-width: 450px; } }

.overlay__content--columns {
  letter-spacing: -4px; }

.overlay__choice__list {
  display: block;
  font-size: 0;
  margin: 0 -1%; }
  @media screen and (max-width: 840px) {
    .overlay__choice__list {
      margin: 0; } }

.overlay__choice__item {
  width: 50%;
  display: inline-block;
  padding: 1%;
  vertical-align: top; }
  .overlay__choice__item.data_id_0 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1s; }
  .overlay__choice__item.data_id_1 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s; }
  .overlay__choice__item.data_id_2 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.3s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.3s; }
  .overlay__choice__item.data_id_3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.45s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.45s; }
  .overlay__choice__item.data_id_4 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.6s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.6s; }
  .overlay__choice__item.data_id_5 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.75s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.75s; }
  .overlay__choice__item.data_id_6 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.9s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.9s; }
  .overlay__choice__item.data_id_7 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.05s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.05s; }
  .overlay__choice__item.data_id_8 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.2s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.2s; }
  .overlay__choice__item.data_id_9 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.35s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.35s; }
  .overlay__choice__item.data_id_10 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.5s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.5s; }
  ._reveal .overlay__choice__item, .reveal .overlay__choice__item {
    transform: translateY(100px);
    opacity: 0;
    transition: transform 0s 1s, opacity 0s 1s; }
  @media screen and (max-width: 840px) {
    .overlay__choice__item {
      width: 100%;
      padding: 0;
      margin: 0 0 20px 0; } }

.overlay__choice {
  display: block;
  width: 100%;
  height: auto;
  text-decoration: none;
  color: inherit;
  background: #F4F4F4;
  padding: 40px 30px 40px 100px;
  position: relative;
  font-size: 14px;
  line-height: 1.4; }
  .overlay__choice h2 {
    font-weight: bold;
    letter-spacing: 0.1em;
    margin-bottom: 15px;
    text-transform: uppercase; }
  .overlay__choice p {
    letter-spacing: 0.07em; }
  @media screen and (max-width: 840px) {
    .overlay__choice {
      padding: 30px 30px 30px 80px; } }

.overlay__choice__icon {
  position: absolute;
  top: 40px;
  height: 40px;
  width: 40px;
  left: 40px; }
  .overlay__choice__icon svg {
    fill: #2a3954;
    display: block;
    width: 100%;
    height: 100%; }
  @media screen and (max-width: 840px) {
    .overlay__choice__icon {
      top: 30px;
      height: 30px;
      width: 30px;
      left: 30px; } }

.overlay__navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  padding: 25px 80px;
  text-align: right;
  font-size: 0;
  background: #FFFFFF;
  z-index: 10;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1); }
  ._reveal .overlay__navigation, .reveal .overlay__navigation {
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0s 1s, opacity 0s 1s; }
  @media screen and (max-width: 840px) {
    .overlay__navigation {
      padding: 25px 20px; } }
  @media screen and (max-width: 600px) {
    .overlay__navigation {
      padding: 10px 20px; } }

.overlay__navigation__content {
  max-width: 850px;
  margin: 0 auto; }

.overlay__navigation__button {
  display: inline-block;
  width: 49%;
  margin-right: 2%;
  height: 50px;
  background: #F4F4F4;
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  vertical-align: bottom;
  line-height: 50px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  border-radius: 0; }
  @media screen and (max-width: 600px) {
    .overlay__navigation__button {
      font-size: 12px;
      height: 30px;
      line-height: 30px; } }

.overlay__navigation__button--next {
  margin: 0;
  background: #2a3954;
  color: #FFFFFF; }

.overlay__navigation__icon {
  margin: 0 10px;
  vertical-align: bottom;
  height: 50px;
  width: 50px;
  display: inline-block; }
  .overlay__navigation__icon svg {
    fill: currentColor;
    display: block;
    width: 100%;
    height: 100%; }
  @media screen and (max-width: 600px) {
    .overlay__navigation__icon {
      height: 30px;
      width: 30px;
      margin: 0 5px; } }

.overlay__aside {
  display: inline-block;
  width: 49%;
  margin-right: 2%;
  margin-top: -20px;
  letter-spacing: 0;
  vertical-align: top;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.6s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.6s; }
  .overlay__aside h2 {
    font-weight: bold;
    letter-spacing: 0.1em;
    margin-bottom: 15px;
    text-transform: uppercase; }
  .overlay__aside p {
    line-height: 1.6; }
    .overlay__aside p .time_indent {
      display: inline-block;
      width: 60px; }
  .overlay__aside + .overlay__form {
    width: 49%;
    max-width: 100%;
    display: inline-block; }
  ._reveal .overlay__aside, .reveal .overlay__aside {
    transform: translateY(100px);
    opacity: 0;
    transition: transform 0s 1s, opacity 0s 1s; }
  @media screen and (max-width: 840px) {
    .overlay__aside {
      width: 100%;
      margin-right: 0; }
      .overlay__aside + .overlay__form {
        width: 100%; } }

.overlay__form {
  display: block;
  margin: 0 auto;
  max-width: 450px;
  letter-spacing: 0;
  vertical-align: top;
  padding-top: 20px; }
  .overlay__form input, .overlay__form textarea {
    width: 100%;
    height: 45px;
    background: #F4F4F4;
    font-family: "freight-sans-pro", sans-serif;
    font-size: 15px;
    border-radius: 0;
    -webkit-appearance: none;
    border: none;
    text-indent: 20px;
    letter-spacing: 0.08em; }
  .overlay__form textarea {
    height: 140px;
    text-indent: 0;
    padding: 15px 20px;
    display: block;
    resize: none; }

.overlay__form__product {
  padding: 40px 40px 40px 160px;
  background: #F4F4F4;
  margin-bottom: 20px;
  position: relative;
  min-height: 160px;
  line-height: 1.5; }
  .overlay__form__product h2 {
    margin: 0; }
  .overlay__form__product img {
    display: block;
    position: absolute;
    background: #FFFFFF;
    top: 40px;
    left: 40px;
    width: 100px;
    height: 100px; }
  .overlay__form__product b {
    font-weight: 600; }

.overlay__form__contact {
  background: #F4F4F4;
  padding: 40px; }
  @media screen and (max-width: 840px) {
    .overlay__form__contact {
      display: none; } }

.overlay__form__fields {
  width: 100%;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s; }
  ._reveal .overlay__form__fields, .reveal .overlay__form__fields {
    transform: translateY(100px);
    opacity: 0;
    transition: transform 0s 1s, opacity 0s 1s; }
  .overlay__form__fields.errors {
    transition: none; }

.overlay__form__input {
  margin-bottom: 20px; }
  .overlay__form__input.error {
    border-bottom: 1px solid red; }

#products {
  text-align: center; }
  #products .overlay__content {
    display: inline-block;
    width: auto;
    max-width: 100%;
    text-align: left; }

.products__frame {
  width: 100%;
  margin: 0 auto; }
  @media screen and (max-width: 840px) {
    .products__frame {
      max-width: 400px; } }

.products__list {
  margin: 0 -20px 20px -20px;
  font-size: 0; }

.products__range {
  display: inline-block;
  margin: 0 0 90px 0;
  padding: 0 20px;
  vertical-align: top; }
  .products__range.data_id_0 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s; }
  .products__range.data_id_1 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.82s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.82s; }
  .products__range.data_id_2 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.94s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.94s; }
  .products__range.data_id_3 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.06s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.06s; }
  .products__range.data_id_4 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.18s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.18s; }
  .products__range.data_id_5 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.3s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.3s; }
  .products__range.data_id_6 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.42s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.42s; }
  .products__range.data_id_7 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.54s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.54s; }
  .products__range.data_id_8 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.66s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.66s; }
  .products__range.data_id_9 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.78s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.78s; }
  .products__range.data_id_10 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.9s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.9s; }
  .products__range.data_id_11 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.02s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.02s; }
  .products__range.data_id_12 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.14s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.14s; }
  .products__range.data_id_13 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.26s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.26s; }
  .products__range.data_id_14 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.38s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.38s; }
  .products__range.data_id_15 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.5s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.5s; }
  .products__range.data_id_16 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.62s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.62s; }
  .products__range.data_id_17 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.74s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.74s; }
  .products__range.data_id_18 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.86s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.86s; }
  .products__range.data_id_19 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.98s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.98s; }
  .products__range.data_id_20 h3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 3.1s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 3.1s; }
  ._reveal .products__range h3, .reveal .products__range h3 {
    transform: translateY(100px);
    opacity: 0;
    transition: transform 0s 1s, opacity 0s 1s; }
  @media screen and (max-width: 840px) {
    .products__range {
      width: 100%; } }

.products__range__title {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 1.5; }
  @media screen and (max-width: 1200px) {
    .products__range__title {
      font-size: 14px;
      margin-bottom: 8px; } }
  @media screen and (max-width: 840px) {
    .products__range__title {
      font-size: 12px;
      margin-bottom: 5px; } }

.products__range__list {
  display: block;
  margin: 0;
  letter-spacing: -4px;
  white-space: normal; }

.products__category {
  width: 180px;
  display: inline-block;
  vertical-align: top;
  margin: 0 15px 0 0;
  letter-spacing: 0; }
  .products__category:last-child {
    margin: 0; }
  .products__category.data_id_0 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s; }
  .products__category.data_id_1 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.82s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.82s; }
  .products__category.data_id_2 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.94s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.94s; }
  .products__category.data_id_3 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.06s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.06s; }
  .products__category.data_id_4 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.18s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.18s; }
  .products__category.data_id_5 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.3s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.3s; }
  .products__category.data_id_6 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.42s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.42s; }
  .products__category.data_id_7 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.54s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.54s; }
  .products__category.data_id_8 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.66s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.66s; }
  .products__category.data_id_9 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.78s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.78s; }
  .products__category.data_id_10 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.9s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.9s; }
  .products__category.data_id_11 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.02s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.02s; }
  .products__category.data_id_12 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.14s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.14s; }
  .products__category.data_id_13 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.26s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.26s; }
  .products__category.data_id_14 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.38s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.38s; }
  .products__category.data_id_15 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.5s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.5s; }
  .products__category.data_id_16 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.62s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.62s; }
  .products__category.data_id_17 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.74s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.74s; }
  .products__category.data_id_18 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.86s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.86s; }
  .products__category.data_id_19 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.98s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2.98s; }
  .products__category.data_id_20 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 3.1s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 3.1s; }
  ._reveal .products__category, .reveal .products__category {
    transform: translateY(100px);
    opacity: 0;
    transition: transform 0s 1s, opacity 0s 1s; }
  @media screen and (max-width: 1200px) {
    .products__category {
      width: 120px;
      margin: 0 10px 0 0; } }
  @media screen and (max-width: 840px) {
    .products__category {
      width: 31%;
      margin: 0 3.5% 0 0; }
      .products__category:nth-child(3) {
        margin: 0; } }

.products__category__frame {
  display: block;
  width: 100%;
  color: #333333;
  text-decoration: none;
  transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .hover .products__category__frame {
    opacity: 0.4; }
  .products__category__frame:hover {
    opacity: 1; }

.products__category__item {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background: #000000;
  overflow: hidden; }
  .products__category__item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto; }

.products__category__title {
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 10px 0 0 0;
  line-height: 1.5; }
  @media screen and (max-width: 1200px) {
    .products__category__title {
      font-size: 14px; } }
  @media screen and (max-width: 840px) {
    .products__category__title {
      font-size: 12px; } }

#diamonds {
  height: 100%; }
  #diamonds #share {
    background: #7588aa;
    color: #7588aa; }
  #diamonds #quality {
    background: #000000;
    color: #FFFFFF; }
  #diamonds #additional {
    background: #7588aa; }
  #diamonds .action_controls__buttons {
    margin: 0; }

.diamonds__intro {
  width: 100%;
  height: 100%;
  border-bottom: 15px solid #FFFFFF;
  color: #FFFFFF;
  position: relative;
  display: table;
  overflow: hidden; }
  @media screen and (max-width: 840px) {
    .diamonds__intro {
      border-bottom: 8px solid #FFFFFF; } }

.diamonds__intro__content {
  width: 100%;
  height: 100%;
  display: table; }
  .diamonds__intro__content:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    z-index: 1;
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .reveal .diamonds__intro__content:after {
    transform: scale(0.9);
    opacity: 0; }

.diamonds__intro__title__frame {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  z-index: 3;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.2s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.2s; }
  .reveal .diamonds__intro__title__frame {
    transform: scale(0.9);
    opacity: 0; }
    .reveal .diamonds__intro__title__frame .diamonds__intro__paragraph {
      opacity: 0; }

.diamonds__intro__title__main {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  padding: 100px 0; }
  @media screen and (max-width: 600px) {
    .diamonds__intro__title__main {
      padding: 200px 0 100px; } }

.diamonds__intro__title {
  padding-left: 34%;
  padding-right: 20px;
  display: inline-block;
  width: 100%; }
  .diamonds__intro__title h1 {
    font-family: "JannonAntiqua", serif;
    font-size: calc(1.5vw + 1.5vh + 10px);
    font-weight: bold;
    width: 90%;
    margin-bottom: 25px;
    padding-right: 80px;
    line-height: 1.25;
    text-shadow: 0 0 30px #000000; }
    .diamonds__intro__title h1 span {
      display: inline-block;
      transform: none;
      opacity: 1; }
    .diamonds__intro__title h1 .intro {
      font-size: calc(0.8vw + 0.8vh + 10px);
      display: block;
      font-weight: normal;
      margin-left: 4vw;
      margin-top: 20px; }
  @media screen and (max-width: 1200px) {
    .diamonds__intro__title h1 {
      width: 100%; } }
  @media screen and (max-width: 840px) {
    .diamonds__intro__title h1 {
      padding-right: 20px; } }
  @media screen and (max-width: 600px) {
    .diamonds__intro__title {
      padding: 0 20px; }
      .diamonds__intro__title h1 .intro {
        margin-left: 0;
        margin-top: 10px; } }

.diamonds__intro__paragraph {
  width: 100%;
  padding: 0 16% 0 16%;
  letter-spacing: 1px;
  line-height: 1.4;
  font-size: calc(0.3vw + 0.3vh + 10px);
  max-width: 1200px;
  transition: opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.3s; }
  .diamonds__intro__paragraph p {
    width: 100%; }
    .diamonds__intro__paragraph p a {
      display: inline-block;
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      position: relative; }
      .diamonds__intro__paragraph p a:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 0;
        height: 1px;
        transition: width 0.3s ease-in-out;
        background: #FFFFFF; }
      .diamonds__intro__paragraph p a:hover:after {
        width: 100%; }
  @media screen and (max-width: 1200px) {
    .diamonds__intro__paragraph {
      font-size: 15px; } }
  @media screen and (max-width: 600px) {
    .diamonds__intro__paragraph {
      padding: 0; } }

.diamonds__intro__frame {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
  z-index: 4; }
  @media screen and (max-width: 600px) {
    .diamonds__intro__frame {
      z-index: 2; } }

.diamonds__image__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1s; }
  .reveal .diamonds__image__container {
    transform: scale(0.9);
    opacity: 0; }
  .diamonds__image__container.data_id_0 {
    transition-delay: 0.8s; }
  .diamonds__image__container.data_id_1 {
    transition-delay: 0.9s; }
  .diamonds__image__container.data_id_2 {
    transition-delay: 1s; }
  .diamonds__image__container.data_id_3 {
    transition-delay: 1.1s; }
  .diamonds__image__container.data_id_4 {
    transition-delay: 1.2s; }
  .diamonds__image__container.data_id_5 {
    transition-delay: 1.3s; }
  .diamonds__image__container.data_id_6 {
    transition-delay: 1.4s; }
  .diamonds__image__container.data_id_7 {
    transition-delay: 1.5s; }

.diamonds__intro__scroll {
  width: 50px;
  height: 50px;
  padding: 12px;
  display: block;
  position: absolute;
  bottom: 25px;
  left: 50%;
  margin-left: -25px;
  z-index: 5;
  transition: opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2s; }
  .diamonds__intro__scroll svg {
    fill: #AFAFAF;
    width: 100%;
    height: 100%;
    display: block; }
  .reveal .diamonds__intro__scroll {
    opacity: 0; }

.diamonds__image__frame {
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1); }

.diamonds__image {
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  transition: filter 0.2s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .diamonds__image img {
    display: block;
    height: auto;
    width: auto; }
  .data_id_1 .diamonds__image {
    top: 48%;
    left: 37%;
    transform: translateX(-100%) translateY(0); }
    .data_id_1 .diamonds__image .diamonds__image__frame {
      transition-delay: 0.3s; }
    .data_id_1 .diamonds__image img {
      height: calc(9vw + 9vh + 10px); }
    @media screen and (max-width: 1200px) {
      .data_id_1 .diamonds__image {
        top: 45%;
        left: 25%; } }
    @media screen and (max-width: 600px) {
      .data_id_1 .diamonds__image {
        top: 16%;
        left: 60%;
        transform: translateX(-60%) translateY(0); }
        .data_id_1 .diamonds__image img {
          height: calc(8vw + 8vh + 10px); } }
  .data_id_2 .diamonds__image {
    top: 83%;
    left: 74%; }
    .data_id_2 .diamonds__image .diamonds__image__frame {
      transition-delay: 0.4s; }
    .data_id_2 .diamonds__image img {
      height: calc(3.5vw + 3.5vh + 10px); }
    @media screen and (max-width: 600px) {
      .data_id_2 .diamonds__image {
        top: 92%; } }
  .data_id_3 .diamonds__image {
    top: 25.5%;
    left: 21%; }
    .data_id_3 .diamonds__image .diamonds__image__frame {
      transition-delay: 0.5s; }
    .data_id_3 .diamonds__image img {
      height: calc(4.2vw + 4.2vh + 10px); }
  .data_id_4 .diamonds__image {
    top: 82%;
    left: 17%; }
    .data_id_4 .diamonds__image .diamonds__image__frame {
      transition-delay: 0.6s; }
    .data_id_4 .diamonds__image img {
      height: calc(3vw + 3vh + 10px); }
    @media screen and (max-width: 600px) {
      .data_id_4 .diamonds__image {
        top: 90%; } }
  .data_id_5 .diamonds__image {
    top: 11%;
    left: 10%; }
    .data_id_5 .diamonds__image .diamonds__image__frame {
      transition-delay: 0.7s; }
    .data_id_5 .diamonds__image img {
      height: calc(2.3vw + 2.3vh + 10px); }
  .data_id_6 .diamonds__image {
    top: 13%;
    left: 75%; }
    .data_id_6 .diamonds__image .diamonds__image__frame {
      transition-delay: 0.8s; }
    .data_id_6 .diamonds__image img {
      height: calc(4vw + 4vh + 10px); }
    @media screen and (max-width: 600px) {
      .data_id_6 .diamonds__image {
        display: none; } }
  .data_id_7 .diamonds__image {
    top: 27%;
    left: 90%; }
    .data_id_7 .diamonds__image .diamonds__image__frame {
      transition-delay: 0.9s; }
    .data_id_7 .diamonds__image img {
      height: calc(2.4vw + 2.4vh + 10px); }

#diamond_configurator {
  width: 100%;
  background: #000000;
  border-bottom: 15px solid #FFFFFF;
  padding: 200px 80px;
  color: #FFFFFF; }
  #diamond_configurator:after {
    content: '';
    display: block;
    clear: both;
    float: none; }
  #diamond_configurator[data-shape="0"] .diamond_configurator__preview__image img.clear.data_shape_id_0 {
    display: block; }
  #diamond_configurator[data-shape="0"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_0.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="0"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_0.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="0"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_0.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="0"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_0 {
    display: block; }
  #diamond_configurator[data-shape="1"] .diamond_configurator__preview__image img.clear.data_shape_id_1 {
    display: block; }
  #diamond_configurator[data-shape="1"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_1.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="1"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_1.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="1"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_1.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="1"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_1 {
    display: block; }
  #diamond_configurator[data-shape="2"] .diamond_configurator__preview__image img.clear.data_shape_id_2 {
    display: block; }
  #diamond_configurator[data-shape="2"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_2.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="2"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_2.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="2"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_2.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="2"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_2 {
    display: block; }
  #diamond_configurator[data-shape="3"] .diamond_configurator__preview__image img.clear.data_shape_id_3 {
    display: block; }
  #diamond_configurator[data-shape="3"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_3.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="3"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_3.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="3"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_3.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="3"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_3 {
    display: block; }
  #diamond_configurator[data-shape="4"] .diamond_configurator__preview__image img.clear.data_shape_id_4 {
    display: block; }
  #diamond_configurator[data-shape="4"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_4.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="4"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_4.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="4"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_4.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="4"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_4 {
    display: block; }
  #diamond_configurator[data-shape="5"] .diamond_configurator__preview__image img.clear.data_shape_id_5 {
    display: block; }
  #diamond_configurator[data-shape="5"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_5.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="5"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_5.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="5"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_5.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="5"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_5 {
    display: block; }
  #diamond_configurator[data-shape="6"] .diamond_configurator__preview__image img.clear.data_shape_id_6 {
    display: block; }
  #diamond_configurator[data-shape="6"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_6.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="6"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_6.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="6"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_6.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="6"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_6 {
    display: block; }
  #diamond_configurator[data-shape="7"] .diamond_configurator__preview__image img.clear.data_shape_id_7 {
    display: block; }
  #diamond_configurator[data-shape="7"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_7.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="7"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_7.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="7"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_7.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="7"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_7 {
    display: block; }
  #diamond_configurator[data-shape="8"] .diamond_configurator__preview__image img.clear.data_shape_id_8 {
    display: block; }
  #diamond_configurator[data-shape="8"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_8.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="8"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_8.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="8"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_8.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="8"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_8 {
    display: block; }
  #diamond_configurator[data-shape="9"] .diamond_configurator__preview__image img.clear.data_shape_id_9 {
    display: block; }
  #diamond_configurator[data-shape="9"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_9.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="9"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_9.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="9"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_9.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="9"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_9 {
    display: block; }
  #diamond_configurator[data-shape="10"] .diamond_configurator__preview__image img.clear.data_shape_id_10 {
    display: block; }
  #diamond_configurator[data-shape="10"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_10.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="10"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_10.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="10"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_10.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="10"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_10 {
    display: block; }
  #diamond_configurator[data-shape="11"] .diamond_configurator__preview__image img.clear.data_shape_id_11 {
    display: block; }
  #diamond_configurator[data-shape="11"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_11.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="11"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_11.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="11"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_11.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="11"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_11 {
    display: block; }
  #diamond_configurator[data-shape="12"] .diamond_configurator__preview__image img.clear.data_shape_id_12 {
    display: block; }
  #diamond_configurator[data-shape="12"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_12.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="12"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_12.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="12"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_12.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="12"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_12 {
    display: block; }
  #diamond_configurator[data-shape="13"] .diamond_configurator__preview__image img.clear.data_shape_id_13 {
    display: block; }
  #diamond_configurator[data-shape="13"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_13.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="13"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_13.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="13"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_13.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="13"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_13 {
    display: block; }
  #diamond_configurator[data-shape="14"] .diamond_configurator__preview__image img.clear.data_shape_id_14 {
    display: block; }
  #diamond_configurator[data-shape="14"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_14.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="14"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_14.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="14"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_14.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="14"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_14 {
    display: block; }
  #diamond_configurator[data-shape="15"] .diamond_configurator__preview__image img.clear.data_shape_id_15 {
    display: block; }
  #diamond_configurator[data-shape="15"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_15.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="15"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_15.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="15"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_15.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="15"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_15 {
    display: block; }
  #diamond_configurator[data-shape="16"] .diamond_configurator__preview__image img.clear.data_shape_id_16 {
    display: block; }
  #diamond_configurator[data-shape="16"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_16.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="16"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_16.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="16"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_16.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="16"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_16 {
    display: block; }
  #diamond_configurator[data-shape="17"] .diamond_configurator__preview__image img.clear.data_shape_id_17 {
    display: block; }
  #diamond_configurator[data-shape="17"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_17.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="17"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_17.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="17"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_17.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="17"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_17 {
    display: block; }
  #diamond_configurator[data-shape="18"] .diamond_configurator__preview__image img.clear.data_shape_id_18 {
    display: block; }
  #diamond_configurator[data-shape="18"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_18.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="18"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_18.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="18"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_18.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="18"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_18 {
    display: block; }
  #diamond_configurator[data-shape="19"] .diamond_configurator__preview__image img.clear.data_shape_id_19 {
    display: block; }
  #diamond_configurator[data-shape="19"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_19.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="19"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_19.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="19"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_19.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="19"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_19 {
    display: block; }
  #diamond_configurator[data-shape="20"] .diamond_configurator__preview__image img.clear.data_shape_id_20 {
    display: block; }
  #diamond_configurator[data-shape="20"][data-color="1"] .diamond_configurator__preview__image__hue img.data_shape_id_20.data_color_id_1 {
    display: block; }
  #diamond_configurator[data-shape="20"][data-color="2"] .diamond_configurator__preview__image__hue img.data_shape_id_20.data_color_id_2 {
    display: block; }
  #diamond_configurator[data-shape="20"][data-color="3"] .diamond_configurator__preview__image__hue img.data_shape_id_20.data_color_id_3 {
    display: block; }
  #diamond_configurator[data-color="0"][data-shape="20"] .diamond_configurator__preview__image__hue img.yellow.data_shape_id_20 {
    display: block; }
  @media screen and (max-width: 840px) {
    #diamond_configurator {
      padding: 100px 30px;
      border-bottom: 8px solid #FFFFFF; } }

.diamond_configurator__content {
  max-width: 1000px;
  margin: 0 auto; }
  @media screen and (max-width: 840px) {
    .diamond_configurator__content {
      max-width: 450px; } }

.diamond_configurator__visual {
  float: right;
  width: 45%;
  margin-left: 10%; }
  @media screen and (max-width: 840px) {
    .diamond_configurator__visual {
      float: none;
      width: 100%;
      margin: 0; }
      .diamond_configurator__visual .action_controls {
        display: none; } }

.diamond_configurator__color {
  margin: 30px 0 0 0;
  text-align: center; }
  .diamond_configurator__color input {
    display: none; }

.diamond_configurator__color__list {
  display: inline-block;
  white-space: nowrap;
  font-size: 0; }
  .diamond_configurator__color__list li {
    display: inline-block; }
    .diamond_configurator__color__list li a {
      width: 50px;
      height: 50px;
      display: block;
      position: relative; }
      .diamond_configurator__color__list li a:before, .diamond_configurator__color__list li a:after {
        content: '';
        display: block;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -13px;
        margin-left: -13px; }
      .diamond_configurator__color__list li a:before {
        background: #FFFFFF;
        transform: scale(0.8); }
      .diamond_configurator__color__list li a:after {
        width: 36px;
        height: 36px;
        border: 3px solid #7588aa;
        margin-top: -18px;
        margin-left: -18px;
        opacity: 0; }
      .diamond_configurator__color__list li a.pink:before {
        background: #BD5AA3; }
      .diamond_configurator__color__list li a.yellow:before {
        background: #D8D06D; }
      .diamond_configurator__color__list li a.blue:before {
        background: #5F82CA; }
    #diamond_configurator[data-color="0"] .diamond_configurator__color__list li.data_id_0 a:before {
      transform: none; }
    #diamond_configurator[data-color="0"] .diamond_configurator__color__list li.data_id_0 a:after {
      opacity: 1; }
    #diamond_configurator[data-color="1"] .diamond_configurator__color__list li.data_id_1 a:before {
      transform: none; }
    #diamond_configurator[data-color="1"] .diamond_configurator__color__list li.data_id_1 a:after {
      opacity: 1; }
    #diamond_configurator[data-color="2"] .diamond_configurator__color__list li.data_id_2 a:before {
      transform: none; }
    #diamond_configurator[data-color="2"] .diamond_configurator__color__list li.data_id_2 a:after {
      opacity: 1; }
    #diamond_configurator[data-color="3"] .diamond_configurator__color__list li.data_id_3 a:before {
      transform: none; }
    #diamond_configurator[data-color="3"] .diamond_configurator__color__list li.data_id_3 a:after {
      opacity: 1; }
    #diamond_configurator[data-color="4"] .diamond_configurator__color__list li.data_id_4 a:before {
      transform: none; }
    #diamond_configurator[data-color="4"] .diamond_configurator__color__list li.data_id_4 a:after {
      opacity: 1; }
    #diamond_configurator[data-color="5"] .diamond_configurator__color__list li.data_id_5 a:before {
      transform: none; }
    #diamond_configurator[data-color="5"] .diamond_configurator__color__list li.data_id_5 a:after {
      opacity: 1; }

.diamond_configurator__preview {
  width: 100%; }

.diamond_configurator__preview__image {
  position: relative;
  width: 100%;
  padding-bottom: 80%;
  overflow: hidden; }
  .diamond_configurator__preview__image img {
    position: absolute;
    top: -20%;
    left: 0;
    width: 100%;
    height: auto;
    display: none; }

.diamond_configurator__preview__image__hue {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.diamond_configurator__detail {
  float: right;
  width: 45%; }
  .diamond_configurator__detail .action_controls {
    display: none;
    margin-top: 50px; }
  @media screen and (max-width: 840px) {
    .diamond_configurator__detail {
      float: none;
      width: 100%; }
      .diamond_configurator__detail .action_controls {
        display: block; } }

.diamond_configurator__detail__item {
  margin-top: 40px;
  text-align: center;
  position: relative; }
  .diamond_configurator__detail__item:first-child {
    margin-top: 0; }

#diamond_configurator__color {
  display: none; }
  #diamond_configurator[data-color="0"] #diamond_configurator__color {
    display: block; }

#diamond_configurator__fancy {
  display: block; }
  #diamond_configurator[data-color="0"] #diamond_configurator__fancy.loaded {
    display: none; }

.diamond_configurator__detail__info {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  margin: 15px 0 0 0;
  text-transform: uppercase;
  color: #FFFFFF;
  text-decoration: none;
  letter-spacing: 0.1em;
  line-height: 20px;
  height: 20px;
  vertical-align: middle; }

.diamond_configurator__detail__info.color {
  display: none; }
  .diamond_configurator__detail__info.color.fancy {
    display: inline-block; }

#diamond_configurator[data-color="0"] .diamond_configurator__detail__info.color {
  display: inline-block; }
  #diamond_configurator[data-color="0"] .diamond_configurator__detail__info.color.fancy {
    display: none; }

a.diamond_configurator__detail__info:after {
  content: '?';
  width: 20px;
  height: 20px;
  background: #7588aa;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-family: "roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  margin-left: 5px;
  line-height: 18px; }

.diamond_education .overlay__title {
  text-align: left;
  max-width: 1200px; }
  @media screen and (max-width: 840px) {
    .diamond_education .overlay__title {
      max-width: 500px; } }

.diamond_education .overlay__introline:before {
  content: '?';
  width: 20px;
  height: 20px;
  background: #2a3954;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-family: "roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  margin-left: 5px;
  line-height: 18px;
  margin-right: 10px;
  color: #FFFFFF; }

.diamond_education .overlay__main {
  max-width: 1200px;
  margin: 0 auto; }
  @media screen and (max-width: 840px) {
    .diamond_education .overlay__main {
      max-width: 500px; } }

.diamond_education__frame {
  width: 100%;
  letter-spacing: -4px;
  direction: rtl; }

.diamond_education__description {
  width: 48%;
  display: inline-block;
  letter-spacing: 0;
  margin-right: 4%;
  vertical-align: top;
  direction: initial; }
  .diamond_education__description strong {
    font-weight: bold; }
  @media screen and (max-width: 840px) {
    .diamond_education__description {
      width: 100%;
      margin: 0; } }

.diamond_education__button {
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s; }
  ._reveal .diamond_education__button, .reveal .diamond_education__button {
    transform: translateY(100px);
    opacity: 0;
    transition: transform 0s 1s, opacity 0s 1s; }

.diamond_education__example {
  width: 48%;
  display: inline-block;
  letter-spacing: normal;
  vertical-align: top;
  direction: initial; }
  .diamond_education__example .selection_slider {
    width: 100%; }
  @media screen and (max-width: 840px) {
    .diamond_education__example {
      width: 100%;
      margin-bottom: 40px; } }

.diamond_education__example__title {
  text-align: center;
  font-style: italic;
  font-size: 14px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1.5px; }

.diamond_education__example__visual {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  position: relative; }

.diamond_education__example__visual__image {
  width: 100%;
  padding-bottom: 80%;
  position: relative;
  overflow: hidden; }
  .diamond_education__example__visual__image img {
    width: 100%;
    height: auto;
    position: absolute;
    top: -15%;
    left: 0; }
  .diamond_education__example__visual__image.clarity img, .diamond_education__example__visual__image.cut img {
    padding: 40px;
    display: none; }
  .diamond_education__example__visual__image.clarity[data-active="0"] img.data_id_0, .diamond_education__example__visual__image.cut[data-active="0"] img.data_id_0 {
    display: block; }
  .diamond_education__example__visual__image.clarity[data-active="1"] img.data_id_1, .diamond_education__example__visual__image.cut[data-active="1"] img.data_id_1 {
    display: block; }
  .diamond_education__example__visual__image.clarity[data-active="2"] img.data_id_2, .diamond_education__example__visual__image.cut[data-active="2"] img.data_id_2 {
    display: block; }
  .diamond_education__example__visual__image.clarity[data-active="3"] img.data_id_3, .diamond_education__example__visual__image.cut[data-active="3"] img.data_id_3 {
    display: block; }
  .diamond_education__example__visual__image.clarity[data-active="4"] img.data_id_4, .diamond_education__example__visual__image.cut[data-active="4"] img.data_id_4 {
    display: block; }
  .diamond_education__example__visual__image.clarity[data-active="5"] img.data_id_5, .diamond_education__example__visual__image.cut[data-active="5"] img.data_id_5 {
    display: block; }
  .diamond_education__example__visual__image.clarity[data-active="6"] img.data_id_6, .diamond_education__example__visual__image.cut[data-active="6"] img.data_id_6 {
    display: block; }
  .diamond_education__example__visual__image.clarity[data-active="7"] img.data_id_7, .diamond_education__example__visual__image.cut[data-active="7"] img.data_id_7 {
    display: block; }
  .diamond_education__example__visual__image.clarity[data-active="8"] img.data_id_8, .diamond_education__example__visual__image.cut[data-active="8"] img.data_id_8 {
    display: block; }
  .diamond_education__example__visual__image.clarity[data-active="9"] img.data_id_9, .diamond_education__example__visual__image.cut[data-active="9"] img.data_id_9 {
    display: block; }
  .diamond_education__example__visual__image.clarity[data-active="10"] img.data_id_10, .diamond_education__example__visual__image.cut[data-active="10"] img.data_id_10 {
    display: block; }
  .diamond_education__example__visual__image.clarity[data-active="11"] img.data_id_11, .diamond_education__example__visual__image.cut[data-active="11"] img.data_id_11 {
    display: block; }
  .diamond_education__example__visual__image.clarity[data-active="12"] img.data_id_12, .diamond_education__example__visual__image.cut[data-active="12"] img.data_id_12 {
    display: block; }

.content {
  padding: 100px 15px 0;
  background: #FFFFFF;
  min-height: 100%; }
  body.noheader .content, body.logoheader .content, body.transparentheader .content {
    padding-top: 15px; }
  @media screen and (max-width: 840px) {
    .content {
      padding: 80px 8px 0; }
      body.noheader .content, body.logoheader .content, body.transparentheader .content {
        padding-top: 8px; } }
  .content h2 {
    font-size: 40px;
    text-align: center;
    font-family: "JannonAntiqua", serif;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 20px; }
    @media screen and (max-width: 840px) {
      .content h2 {
        font-size: 34px; } }
    @media screen and (max-width: 600px) {
      .content h2 {
        font-size: 28px; } }
  .content h2 ~ p {
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 1.4;
    text-align: center;
    max-width: 600px;
    margin: 0 auto; }
    .content h2 ~ p strong {
      font-weight: 600; }
    @media screen and (max-width: 840px) {
      .content h2 ~ p {
        font-size: 16px; } }
    @media screen and (max-width: 600px) {
      .content h2 ~ p {
        font-size: 14px; } }
  .content .overlay__title ~ .content__text h2 {
    font-size: 14px;
    font-family: "freight-sans-pro", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.1em; }
  .content .overlay__title ~ .content__text p {
    text-align: left;
    margin-bottom: 60px; }
  .content .overlay__title + .content__text h2 {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.8s; }
  .content .overlay__title + .content__text p {
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1s; }

.content__share #share {
  background: #AFAFAF;
  color: #AFAFAF; }

.content__subject_slider {
  background: #AFAFAF; }

.content__text {
  padding: 0 80px;
  margin-bottom: 80px; }
  .content__text h2 {
    display: block;
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .content__text p {
    display: block;
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s; }
  .content__text.reveal h2, .content__text.reveal p {
    transform: translateY(100px);
    opacity: 0; }
  @media screen and (max-width: 840px) {
    .content__text {
      padding: 0 20px; } }

.content__text__address {
  max-width: 350px;
  margin: 0 auto;
  letter-spacing: -4px; }

.content__text__address__column {
  display: inline-block !important;
  vertical-align: top;
  width: 45%;
  letter-spacing: normal;
  margin-left: 10%; }
  .content__text__address__column:first-child {
    margin-left: 0; }

.content__image_text {
  text-align: center; }
  .content__image_text .button {
    display: inline-block;
    margin: 160px auto 80px;
    background: #333333;
    color: #FFFFFF;
    max-width: 600px;
    width: 100%;
    vertical-align: bottom;
    padding-top: 2px;
    padding-bottom: 2px; }
    .content__image_text .button span {
      line-height: 40px; }
    .content__image_text .button svg {
      fill: #FFFFFF;
      height: 40px;
      width: 40px;
      vertical-align: bottom;
      display: inline-block;
      margin-left: 20px; }

.content__image_text__main {
  padding: 80px 80px 0;
  background: #F4F4F4; }
  .content__image_text__main img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    max-width: 800px;
    position: relative;
    top: 80px; }
  @media screen and (max-width: 840px) {
    .content__image_text__main {
      padding: 80px 20px 0; } }

.download__content {
  max-width: 500px;
  margin: 30px auto;
  width: 100%; }

.download__content__item {
  margin: 0 0 50px 0; }

.download__content__item__title {
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 10px; }
  .download__content__item__title strong {
    font-weight: 600; }

.download__content__item__assets {
  margin: 0 -1.5%;
  letter-spacing: -4px; }
  .download__content__item__assets a {
    display: inline-block;
    vertical-align: top;
    width: 45.5%;
    margin: 1.5%; }
    .download__content__item__assets a img {
      display: block;
      width: 100%;
      height: auto; }

#overview {
  width: 100%; }

.overview__intro {
  width: 100%;
  position: relative;
  padding-bottom: 250px; }
  @media screen and (max-width: 840px) {
    .overview__intro {
      padding-bottom: 200px; } }

.overview__intro__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #333333;
  background-size: cover;
  background-position: center;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .overview__intro__image:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .reveal .overview__intro__image {
    transform: scale(0.9);
    opacity: 0; }

.overview__intro__title {
  position: relative;
  z-index: 2;
  text-align: left;
  width: 100%;
  color: #FFFFFF;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s; }
  .reveal .overview__intro__title {
    transform: scale(0.9);
    opacity: 0; }

.overview__intro__title__main {
  width: 100%;
  max-width: 1200px;
  padding: 100px 80px;
  margin: 0 auto; }
  .overview__intro__title__main h1 {
    font-family: "JannonAntiqua", serif;
    font-weight: bold;
    line-height: 1.4;
    font-size: 50px;
    margin-bottom: 20px; }
    .overview__intro__title__main h1 span {
      font-weight: normal;
      font-size: 30px;
      display: inline-block;
      position: relative;
      margin-bottom: 15px; }
  .overview__intro__title__main p {
    max-width: 600px;
    transition: opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s; }
    .reveal .overview__intro__title__main p {
      opacity: 0; }
  @media screen and (max-width: 840px) {
    .overview__intro__title__main {
      padding: 60px 20px 40px;
      max-width: 450px;
      margin: 0 auto; }
      .overview__intro__title__main h1 {
        font-size: 30px; }
        .overview__intro__title__main h1 span {
          font-size: 20px; }
        .overview__intro__title__main h1 .headline_br {
          display: none; }
      .overview__intro__title__main p {
        font-size: 14px; } }

.overview__products {
  width: 100%;
  margin: -250px 0 0 0;
  padding-bottom: 75vh; }
  @media screen and (max-width: 840px) {
    .overview__products {
      margin-top: -200px;
      padding-bottom: 50px; } }

.overview__list {
  width: 100%; }

.overview__list__controls {
  width: 100%;
  font-size: 0;
  margin-bottom: 40px;
  position: relative;
  z-index: 5; }

.overview__list__controls__frame {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding: 0 80px;
  z-index: 2; }
  @media screen and (max-width: 840px) {
    .overview__list__controls__frame {
      max-width: 450px;
      padding: 0 20px; } }

.overview__list__controls__item {
  display: inline-block;
  height: 44px;
  width: 32%;
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 14px;
  color: #333333;
  position: relative;
  vertical-align: top;
  line-height: 44px;
  background: #F4F4F4;
  text-decoration: none;
  margin-left: 2%;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .reveal .overview__list__controls__item {
    transform: scale(0.9);
    opacity: 0; }
  .overview__list__controls__item:first-child {
    margin-left: 0; }
  @media screen and (max-width: 840px) {
    .overview__list__controls__item {
      margin-left: 0;
      margin-top: 4%;
      width: 48%; }
      .overview__list__controls__item:last-child {
        margin-left: 4%; } }

.overview__list__controls__icon {
  position: absolute;
  top: 12px;
  right: 20px;
  width: 20px;
  height: 20px;
  will-change: transform; }
  .overview__list__controls__icon svg {
    width: 100%;
    height: 100%;
    display: block;
    fill: currentColor;
    will-change: transform; }

.overview__list__controls__item--search {
  transition-delay: 1.1s;
  transform-origin: 100% 0; }
  .overview__list__controls__item--search input {
    width: 100%;
    height: 100%;
    font-weight: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    border: none;
    text-indent: 20px;
    background: transparent;
    padding-right: 60px;
    -webkit-appearance: none;
    border-radius: 0; }
    .overview__list__controls__item--search input::placeholder {
      color: inherit; }
  .overview__list__controls__item--search .overview__list__controls__icon {
    cursor: text; }
  @media screen and (max-width: 840px) {
    .overview__list__controls__item--search {
      width: 100%;
      transform-origin: 50% 0; } }

.overview__list__controls__item--filter {
  transition-delay: 1.4s;
  padding-left: 20px;
  transform-origin: 50% 0; }
  .overview__list__controls__item--filter.active {
    background: #AFAFAF;
    color: #FFFFFF; }
    .overview__list__controls__item--filter.active:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border: 2px solid #F4F4F4;
      border-bottom: none; }
  @media screen and (max-width: 840px) {
    .overview__list__controls__item--filter {
      transform-origin: 50% 0; } }

.overview__list__controls__item--sort {
  transition-delay: 1.7s;
  transform-origin: 0 0; }
  .overview__list__controls__item--sort label {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding-right: 60px;
    padding-left: 20px;
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%; }
  .overview__list__controls__item--sort .overview__list__controls__icon {
    cursor: pointer; }
  .overview__list__controls__item--sort input {
    position: absolute;
    display: block;
    opacity: 0;
    z-index: -1; }
    .overview__list__controls__item--sort input:focus ~ ul {
      display: block; }
    .overview__list__controls__item--sort input:focus ~ label {
      pointer-events: none; }
  .overview__list__controls__item--sort ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #E4E4E4;
    z-index: 20; }
    .overview__list__controls__item--sort ul li {
      line-height: 1.3;
      padding: 10px 20px;
      cursor: pointer;
      letter-spacing: 0.1em;
      font-weight: normal; }
      .overview__list__controls__item--sort ul li:hover {
        background: #FFFFFF; }
      .overview__list__controls__item--sort ul li.active {
        font-weight: 600; }
  @media screen and (max-width: 840px) {
    .overview__list__controls__item--sort {
      transform-origin: 50% 0; } }

.overview__list__filter__content {
  width: 32%;
  margin-left: 2%;
  display: inline-block;
  vertical-align: top;
  color: #FFFFFF;
  padding: 0 10px; }
  .overview__list__filter__content:first-child {
    margin-left: 0; }
  @media screen and (max-width: 840px) {
    .overview__list__filter__content {
      width: 100%;
      margin: 0 0 20px 0; } }

.overview__list__filter__content__title {
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px;
  font-weight: 600; }

.overview__list__filter__content__list {
  margin: 0 -1%;
  white-space: normal; }

.filter__checkbox {
  display: inline-block;
  vertical-align: top;
  width: 48%;
  margin: 0 1%;
  margin-bottom: 2%; }
  .filter__checkbox input {
    display: none; }
    .filter__checkbox input:checked + .filter__checkbox__content {
      background: #FFFFFF;
      color: #333333; }
      .filter__checkbox input:checked + .filter__checkbox__content svg {
        opacity: 1; }

.filter__checkbox__content {
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 1.4;
  padding: 8px 8px 8px 30px;
  border: 2px solid #FFFFFF;
  position: relative;
  letter-spacing: 0.5px;
  transition: background 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  font-weight: 500; }

.filter__checkbox__icon {
  position: absolute;
  top: 10px;
  left: 8px;
  width: 14px;
  height: 14px; }
  .filter__checkbox__icon svg {
    display: block;
    opacity: 0;
    width: 100%;
    height: 100%;
    fill: #636363; }

.overview__list__frame {
  width: 100%;
  font-size: 0;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 80px;
  position: relative;
  z-index: 4; }
  @media screen and (max-width: 840px) {
    .overview__list__frame {
      max-width: 450px;
      padding: 0 20px; } }

.product_list {
  display: block;
  letter-spacing: -4px;
  margin: 0 -1%; }
  @media screen and (max-width: 840px) {
    .product_list {
      margin: 0; } }

.product_list__item {
  letter-spacing: normal;
  width: 31.33333%;
  display: inline-block;
  vertical-align: top;
  margin: 0 1% 2%; }
  @media screen and (max-width: 840px) {
    .product_list__item {
      width: 100%;
      margin: 0 0 20px !important; } }

.product_list__item__content {
  width: 100%;
  height: 0;
  padding-bottom: 155%;
  white-space: normal;
  position: relative;
  overflow: hidden;
  background: #FFFFFF;
  border: 1px solid #F4F4F4;
  display: block;
  text-decoration: none;
  color: #333333;
  font-size: 16px;
  font-family: "freight-sans-pro", sans-serif;
  box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.05);
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 3s cubic-bezier(0.19, 1, 0.22, 1); }
  .first .product_list__item__content {
    transition-delay: 0.3s; }
  .second .product_list__item__content {
    transition-delay: 0.6s; }
  .third .product_list__item__content {
    transition-delay: 0.9s; }
  .black .product_list__item__content {
    background: #000000;
    color: #FFFFFF;
    border: none;
    box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.4); }
    html.no-touchevents .black .product_list__item__content:hover {
      background: #000000; }
  .reveal .product_list__item__content, ._reveal .product_list__item__content {
    opacity: 0;
    transform: translateY(100px); }
  ._reveal .product_list__item__content {
    transition: transform 0.75s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.75s cubic-bezier(0.19, 1, 0.22, 1); }
  html.no-touchevents .product_list__item__content:hover .product_list__item__image {
    transform: translateX(-50%) translateY(-50%) translateY(-60px); }
  html.no-touchevents .product_list__item__content:hover .product_list__item__text {
    transform: translateY(-90px); }
  html.no-touchevents .product_list__item__content:hover .product_list__item__price {
    opacity: 1;
    transform: none; }
  html.no-touchevents .product_list__item__content:hover .product_list__item__arrow {
    opacity: 1;
    transform: none; }
  @media screen and (max-width: 840px) {
    .product_list__item__content {
      padding-bottom: 100%;
      transition-delay: 0s !important; } }

.product_list__item__image {
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  will-change: transform;
  transform: translateX(-50%) translateY(-50%);
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.product_list__item__text {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
  transform: none;
  line-height: 1.4;
  transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1), transform 1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.product_list__item__title {
  display: block;
  margin-bottom: 5px;
  font-family: "JannonAntiqua", serif;
  font-weight: bold; }
  .product_list__item__title span {
    font-weight: normal;
    opacity: 0.6;
    display: inline-block; }

.product_list__item__price {
  opacity: 0;
  display: block;
  transform: translateY(50px);
  transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1), transform 1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.product_list__item__arrow {
  position: absolute;
  bottom: 20px;
  width: 50px;
  height: 50px;
  left: 50%;
  margin-left: -25px;
  opacity: 0;
  transform: translateY(200px);
  transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1), transform 1s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .product_list__item__arrow svg {
    fill: #333333; }
  .black .product_list__item__arrow svg {
    fill: #FFFFFF; }

.overview__list__controls__filter__content {
  background: #AFAFAF;
  width: 100%;
  border-top: 2px solid #F4F4F4;
  margin-top: -2px;
  position: relative;
  z-index: 1;
  display: none; }
  .overview__list__controls__filter__content.active {
    display: block; }

.overview__list__controls__filter__content__main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 80px 60px;
  position: relative; }
  @media screen and (max-width: 840px) {
    .overview__list__controls__filter__content__main {
      max-width: 450px;
      padding: 80px 20px 60px; } }

.overview__list__controls__close {
  position: absolute;
  right: 80px;
  top: 20px;
  width: 40px;
  height: 40px;
  padding: 13px;
  display: block; }
  .overview__list__controls__close svg {
    fill: #FFFFFF;
    display: block;
    width: 100%;
    height: 100%; }
  @media screen and (max-width: 840px) {
    .overview__list__controls__close {
      right: 20px; } }

.overview__list__controls__reset {
  position: absolute;
  right: 140px;
  top: 18px;
  height: 40px;
  padding: 11px 0;
  display: block;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 18px;
  vertical-align: bottom;
  letter-spacing: 0.05em;
  font-weight: 500; }
  .overview__list__controls__reset svg {
    fill: #FFFFFF;
    display: inline-block;
    height: 18px;
    width: 18px;
    vertical-align: bottom;
    margin-right: 10px; }
  .overview__list__controls__reset:before, .overview__list__controls__reset:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: #FFFFFF;
    position: absolute;
    bottom: 3px;
    left: 0;
    opacity: 0.3;
    z-index: 2; }
  .overview__list__controls__reset:after {
    opacity: 1;
    width: 0;
    z-index: 3;
    transition: width 0.3s ease-in-out; }
  .overview__list__controls__reset:hover:after {
    width: 100%; }
  @media screen and (max-width: 840px) {
    .overview__list__controls__reset {
      right: 80px; } }

#detail {
  width: 100%;
  height: 100%;
  padding-top: 0; }
  #detail #additional {
    background: #BFBCAB;
    border-top: 16px solid #FFFFFF; }
    @media screen and (max-width: 840px) {
      #detail #additional {
        border-top: 8px solid #FFFFFF; } }
  #detail.black #additional {
    background: #4d3a39; }
  #detail.black #share {
    background: #4d3a39;
    color: #4d3a39; }
  #detail.black #quality {
    background: #000000;
    color: #FFFFFF; }
  #detail.watches #additional {
    background: #2a3954; }
  #detail.watches #share {
    background: #2a3954;
    color: #2a3954; }
  #detail.bridal #additional {
    background: #BFBCAB; }
  #detail.bridal #share {
    background: #BFBCAB;
    color: #BFBCAB; }

.detail__intro {
  height: 100%;
  padding-top: 16px;
  display: table;
  width: 100%;
  border-bottom: 16px solid #FFFFFF; }
  .detail__intro .overlay__title {
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 5;
    width: 100%;
    max-width: 100%;
    transform-origin: 50% 80%;
    transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1.9s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2s; }
  .detail__intro .overlay__headline {
    font-size: calc(1.5vw + 1.5vh + 10px);
    margin: calc(0.1vw + 0.1vh + 10px) 0 1px 0; }
  .detail__intro .overlay__breadcumbs {
    transition-delay: 2.1s; }
  .detail__intro .overlay__text {
    transition-delay: 2.1s; }
  .black .detail__intro {
    color: #FFFFFF; }
  .detail__intro.reveal .overlay__title {
    transform: scale(0.9);
    opacity: 0; }
  .detail__intro.reveal .overlay__headline {
    transform: none;
    opacity: 1; }
  .detail__intro.reveal .overlay__breadcumbs {
    transform: none;
    opacity: 0; }
  .detail__intro.reveal .overlay__text {
    transform: none;
    opacity: 0; }
  @media screen and (max-width: 840px) {
    .detail__intro {
      padding-top: 8px;
      border-bottom: 8px solid #FFFFFF; }
      .detail__intro .overlay__title {
        top: 40px; } }

.detail__intro__frame {
  display: table;
  padding: 200px 20px 0;
  width: 100%;
  height: 100%;
  position: relative; }
  .detail__intro__frame:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: #FFFFFF;
    transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    border: 1px solid #E4E4E4; }
    .reveal .detail__intro__frame:after {
      transform: scale(0.9);
      opacity: 0; }
  .black .detail__intro__frame:after {
    background: #000000; }

.detail__intro__main {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  position: relative;
  z-index: 2; }

.detail__intro__title {
  width: 100%;
  text-align: center;
  margin-bottom: calc(3vw + 3vh + 10px);
  font-size: calc(0.42vw + 0.42vh + 10px); }
  .detail__intro__title h1 {
    font-family: "JannonAntiqua", serif;
    font-size: calc(1.5vw + 1.5vh + 10px);
    font-weight: bold;
    letter-spacing: 0.03em;
    line-height: 1.4;
    margin: 15px 0 5px 0; }

.detail__intro__breadcrumbs {
  vertical-align: bottom;
  position: relative; }

.detail__intro__breadcrumbs__separator {
  margin: 0 10px;
  width: 1px;
  height: calc(0.71vw + 0.71vh + 10px);
  display: inline-block;
  position: relative;
  vertical-align: bottom; }
  .detail__intro__breadcrumbs__separator:before, .detail__intro__breadcrumbs__separator:after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -2px;
    left: 0;
    height: 4px;
    width: 1px;
    background: #333333;
    display: inline-block;
    transform-origin: 50% 0;
    transform: rotate(-45deg); }
  .detail__intro__breadcrumbs__separator:after {
    margin-top: -1px;
    transform-origin: 50% 100%;
    transform: rotate(45deg); }

.detail__intro__breadcrumbs__separator--straight:before, .detail__intro__breadcrumbs__separator--straight:after {
  transform: none; }

.detail__intro__breadcrumbs__separator--straight:after {
  margin-top: 0; }

.detail__bottom_controls {
  position: absolute;
  bottom: 25px;
  height: 50px;
  left: 50%;
  width: 700px;
  margin-left: -350px;
  letter-spacing: -4px;
  z-index: 3;
  white-space: nowrap;
  transition: opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 2s; }
  .reveal .detail__bottom_controls {
    opacity: 0; }
  @media screen and (max-width: 840px) {
    .detail__bottom_controls {
      width: 500px;
      margin-left: -250px; } }
  @media screen and (max-width: 600px) {
    .detail__bottom_controls {
      width: 100%;
      left: 0;
      margin-left: 0;
      padding: 0 20px; } }

.detail__product__scroll {
  width: 50px;
  height: 50px;
  padding: 14px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -25px; }
  .detail__product__scroll svg {
    fill: #AFAFAF;
    width: 100%;
    height: 100%;
    display: block; }

.detail__intro__image__nav {
  display: inline-block;
  width: 50%;
  vertical-align: bottom;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 12px;
  text-decoration: none;
  color: inherit; }
  .detail__intro__image__nav svg {
    display: inline-block;
    margin: 0 auto;
    width: 30px;
    height: 30px;
    fill: #AFAFAF; }

.detail__intro__image__nav--prev {
  text-align: left;
  padding-right: 25px; }
  .detail__intro__image__nav--prev svg {
    left: 0; }

.detail__intro__image__nav--next {
  text-align: right;
  padding-left: 25px; }
  .detail__intro__image__nav--next svg {
    right: 0; }

.detail__intro__image__list {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  transform-origin: 50% 20%;
  transition: transform 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1s, opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1) 1s; }
  .reveal .detail__intro__image__list {
    transform: scale(0.9);
    opacity: 0; }

.detail__intro__image__frame {
  width: calc(15vw + 15vh + 10px);
  height: calc(22vw + 22vh + 10px);
  margin: 0 auto;
  white-space: nowrap;
  transform: none;
  will-change: transform;
  transition: transform 1.5s cubic-bezier(0.77, 0, 0.175, 1);
  margin-top: -2%; }
  .detail__intro[data-image="0"] .detail__intro__image__frame {
    transform: translateX(0%); }
    .detail__intro[data-image="0"] .detail__intro__image__frame .detail__intro__image.data_id_0 {
      opacity: 1; }
  .detail__intro[data-image="1"] .detail__intro__image__frame {
    transform: translateX(-100%); }
    .detail__intro[data-image="1"] .detail__intro__image__frame .detail__intro__image.data_id_1 {
      opacity: 1; }
  .detail__intro[data-image="2"] .detail__intro__image__frame {
    transform: translateX(-200%); }
    .detail__intro[data-image="2"] .detail__intro__image__frame .detail__intro__image.data_id_2 {
      opacity: 1; }
  .detail__intro[data-image="3"] .detail__intro__image__frame {
    transform: translateX(-300%); }
    .detail__intro[data-image="3"] .detail__intro__image__frame .detail__intro__image.data_id_3 {
      opacity: 1; }
  .detail__intro[data-image="4"] .detail__intro__image__frame {
    transform: translateX(-400%); }
    .detail__intro[data-image="4"] .detail__intro__image__frame .detail__intro__image.data_id_4 {
      opacity: 1; }
  .detail__intro[data-image="5"] .detail__intro__image__frame {
    transform: translateX(-500%); }
    .detail__intro[data-image="5"] .detail__intro__image__frame .detail__intro__image.data_id_5 {
      opacity: 1; }
  @media screen and (max-width: 840px) {
    .detail__intro__image__frame {
      width: 100%;
      height: calc(30vw + 30vh + 10px); } }

.detail__intro__image {
  width: 100%;
  height: 100%;
  display: inline-block;
  opacity: 0;
  transition: opacity 1.5s cubic-bezier(0.77, 0, 0.175, 1);
  line-height: calc(20vw + 20vh + 10px);
  text-align: center; }
  .detail__intro__image img {
    display: inline-block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%); }

.detail__action {
  background: #BFBCAB;
  color: #FFFFFF;
  overflow: hidden; }
  .black .detail__action {
    background: #4d3a39; }
  .watches .detail__action {
    background: #2a3954; }
  .bridal .detail__action {
    background: #BFBCAB; }

.detail__action__frame {
  white-space: nowrap;
  font-size: 0;
  padding: 80px 80px;
  max-width: 800px;
  margin: 0 auto; }
  .detail__action__frame .action_controls {
    display: inline-block;
    width: 45%;
    margin-right: 10%;
    white-space: normal;
    vertical-align: top; }
  .detail__action__frame.image {
    max-width: 1100px; }
    .detail__action__frame.image .action_controls {
      width: 100%;
      margin: 0 0 40px 0; }
    .detail__action__frame.image .detail__action__image {
      vertical-align: top;
      display: inline-block;
      width: 50%; }
      .detail__action__frame.image .detail__action__image img {
        width: 100%; }
  @media screen and (max-width: 840px) {
    .detail__action__frame {
      white-space: normal;
      padding: 80px 20px;
      max-width: 400px; }
      .detail__action__frame .action_controls {
        width: 100%;
        margin-bottom: 50px; }
      .detail__action__frame.image {
        max-width: 400px; }
        .detail__action__frame.image .detail__action__image {
          display: none; } }

.detail__action__main {
  vertical-align: top;
  display: inline-block;
  width: 100%; }
  .detail__action__frame.image .detail__action__main {
    width: 50%;
    padding-right: 10%;
    white-space: normal; }
    @media screen and (max-width: 840px) {
      .detail__action__frame.image .detail__action__main {
        width: 100%;
        padding-right: 0; } }

.detail__action__benefits {
  display: inline-block;
  width: 45%;
  font-size: 16px;
  white-space: normal;
  vertical-align: top;
  padding-top: 10px; }
  .detail__action__frame.image .detail__action__benefits {
    width: 100%; }
  @media screen and (max-width: 840px) {
    .detail__action__benefits {
      width: 100%; } }

.detail__action__benefits__item {
  display: block;
  padding-left: 50px;
  margin-bottom: 20px;
  position: relative;
  letter-spacing: 0.05em;
  line-height: 1.4; }

.detail__action__benefits__icon {
  position: absolute;
  top: 0;
  left: 10px;
  width: 22px;
  height: 22px; }
  .detail__action__benefits__icon svg {
    fill: #FFFFFF;
    height: 100%;
    width: 100%; }

#features {
  background: #F4F4F4;
  padding: 80px 20px 100px; }

.feature__frame {
  width: 100%;
  max-width: 800px;
  margin: 100px auto 0; }

.feature__content {
  width: 100%;
  position: relative;
  padding-right: 60%; }

.feature__image {
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  height: 100%;
  padding: 20px 50px;
  text-align: center; }

.feature__image__frame {
  height: 100%;
  width: auto;
  display: inline-block;
  position: relative; }
  .feature__image__frame img {
    width: auto;
    height: 100%;
    display: block; }
  .feature__image__frame a {
    display: block;
    position: absolute;
    top: 30%;
    left: 10%;
    width: 45px;
    height: 45px;
    border: 1px solid #E4E4E4;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 50%; }
    .feature__image__frame a:after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      margin: 14px;
      border-radius: 50%;
      background: #BFBCAB; }

.feature__list {
  width: 100%; }

.feature__item {
  width: 100%;
  background: #FFFFFF;
  position: relative; }
  .feature__item:after {
    content: '';
    position: absolute;
    top: 50px;
    right: -8px;
    width: 16px;
    height: 16px;
    display: block;
    background: #FFFFFF;
    transform: rotate(45deg); }

.feature__item__text {
  padding: 40px; }
  .feature__item__text h3 {
    font-family: "JannonAntiqua", serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 10px; }
  .feature__item__text p {
    letter-spacing: 0.05em; }

.feature__item__image {
  width: 100%; }
  .feature__item__image img {
    width: 100%;
    height: auto; }

#specs {
  width: 100%;
  background: #F4F4F4;
  padding: 200px 20px;
  position: relative;
  overflow: hidden;
  border-top: 16px solid #FFFFFF; }
  .black #specs {
    background: #000000;
    color: #FFFFFF; }
  @media screen and (max-width: 840px) {
    #specs {
      padding: 80px 20px;
      border-top: 8px solid #FFFFFF; } }

.specs__bg {
  position: absolute;
  top: -1%;
  right: 0;
  height: 102%;
  opacity: 0.04;
  transform: translateX(50%); }
  .specs__bg svg {
    height: 100%;
    width: auto;
    fill: #000000; }

.page_teaser {
  padding: 50px 30px;
  max-width: 1200px;
  margin: 0 auto; }
  @media screen and (max-width: 700px) {
    .page_teaser {
      max-width: 500px; } }

.page_teaser__content {
  margin: 0 -2%;
  font-size: 0; }
  @media screen and (max-width: 700px) {
    .page_teaser__content {
      margin: 0; } }

.page_teaser__item {
  display: inline-block;
  width: 46%;
  margin: 0 2%;
  position: relative; }
  .page_teaser__item h2 {
    font-size: 25px;
    line-height: 1.35;
    letter-spacing: 0.02em;
    text-align: left;
    color: #FFFFFF;
    max-width: 400px; }
    @media screen and (max-width: 840px) {
      .page_teaser__item h2 {
        font-size: 22px; } }
  .page_teaser__item a {
    margin-top: 0; }
  .page_teaser__item img {
    width: 100%;
    height: auto; }
  .page_teaser__item:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), black);
    opacity: 0.45;
    z-index: 1; }
  @media screen and (max-width: 700px) {
    .page_teaser__item {
      width: 100%;
      margin: 20px 0; }
      .page_teaser__item:first-child {
        margin-top: 0; } }

.page_teaser__item__content {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 30px;
  width: 100%;
  z-index: 2; }

.category_teaser {
  background: #F4F4F4;
  padding: 80px 30px; }
  @media screen and (max-width: 840px) {
    .category_teaser {
      padding-top: 50px; } }

.category_teaser_content {
  max-width: 1600px;
  margin: 0 auto; }
  @media screen and (max-width: 1200px) {
    .category_teaser_content {
      max-width: 600px; } }

.category_teaser_item {
  display: table;
  width: 100%;
  font-size: 0;
  margin-top: 100px; }
  .category_teaser_item:nth-child(even) {
    direction: rtl; }
  @media screen and (max-width: 1200px) {
    .category_teaser_item {
      direction: ltr; } }
  @media screen and (max-width: 840px) {
    .category_teaser_item:first-child {
      margin-top: 40px; } }

.category_teaser_item_main {
  display: table-cell;
  width: 100%;
  vertical-align: middle; }

.category_teaser_item_content {
  width: 35%;
  display: inline-block;
  vertical-align: middle;
  direction: ltr; }
  .category_teaser_item_content h3 {
    font-size: 26px;
    line-height: 1.6;
    margin-bottom: 30px;
    font-family: "JannonAntiqua", serif; }
    @media screen and (max-width: 840px) {
      .category_teaser_item_content h3 {
        font-size: 22px; } }
    @media screen and (max-width: 600px) {
      .category_teaser_item_content h3 {
        font-size: 20px; }
        .category_teaser_item_content h3 br {
          display: none; } }
  .category_teaser_item_content p {
    font-size: 16px;
    line-height: 1.4; }
  @media screen and (max-width: 1200px) {
    .category_teaser_item_content {
      width: 100%; } }

.category_teaser_item_spacer {
  width: 5%;
  display: inline-block;
  vertical-align: middle; }
  @media screen and (max-width: 1200px) {
    .category_teaser_item_spacer {
      width: 100%;
      height: 40px; } }

.category_teaser_item_image {
  width: 60%;
  display: inline-block;
  vertical-align: middle; }
  @media screen and (max-width: 1200px) {
    .category_teaser_item_image {
      width: 100%; } }

.product_navigation__frame {
  width: 100%;
  position: relative; }
  .product_navigation__frame .overlay_close {
    right: auto !important;
    left: 40px; }
    @media screen and (max-width: 840px) {
      .product_navigation__frame .overlay_close {
        left: 30px; } }
    @media screen and (max-width: 600px) {
      .product_navigation__frame .overlay_close {
        left: 15px; } }

.product_navigation__main {
  width: 100%;
  height: 100vh;
  max-width: 1600px;
  display: table;
  margin: 0 auto; }
  .product_navigation__main .overlay__title__frame {
    display: table-row;
    width: 100%; }
    @media screen and (max-width: 840px) {
      .product_navigation__main .overlay__title__frame {
        display: block; } }
  .product_navigation__main .overlay__foter {
    display: table-row;
    width: 100%;
    text-align: left; }
    @media screen and (max-width: 840px) {
      .product_navigation__main .overlay__foter {
        display: block; } }
  @media screen and (max-width: 840px) {
    .product_navigation__main {
      display: block;
      height: 100%; } }

.product_navigation__content {
  width: 100%;
  height: 100%;
  display: table-row; }
  @media screen and (max-width: 840px) {
    .product_navigation__content {
      display: block; } }

.product_navigation {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  padding: 30px 0 50px;
  text-align: center;
  font-size: 0;
  white-space: nowrap;
  transform: translateX(33.3333%);
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: transform; }
  .product_navigation[data-active] {
    transform: none; }
    .product_navigation[data-active] .product_navigation__page--main {
      border-color: #E4E4E4; }
    .product_navigation[data-active] .product_navigation__page--detail {
      opacity: 1; }
    .product_navigation[data-active] ul {
      transition-delay: 0s; }
  .product_navigation[data-active="0"] ul[data-id="0"] {
    max-height: 100%;
    overflow: visible;
    transition-delay: 0s; }
  .product_navigation[data-active="0"] .product_navigation__page--main li a {
    opacity: 0.15; }
  .product_navigation[data-active="0"] .product_navigation__page--main li[data-id="0"] a {
    opacity: 1; }
  .product_navigation[data-active="1"] ul[data-id="1"] {
    max-height: 100%;
    overflow: visible;
    transition-delay: 0s; }
  .product_navigation[data-active="1"] .product_navigation__page--main li a {
    opacity: 0.15; }
  .product_navigation[data-active="1"] .product_navigation__page--main li[data-id="1"] a {
    opacity: 1; }
  .product_navigation[data-active="2"] ul[data-id="2"] {
    max-height: 100%;
    overflow: visible;
    transition-delay: 0s; }
  .product_navigation[data-active="2"] .product_navigation__page--main li a {
    opacity: 0.15; }
  .product_navigation[data-active="2"] .product_navigation__page--main li[data-id="2"] a {
    opacity: 1; }
  .product_navigation[data-active="3"] ul[data-id="3"] {
    max-height: 100%;
    overflow: visible;
    transition-delay: 0s; }
  .product_navigation[data-active="3"] .product_navigation__page--main li a {
    opacity: 0.15; }
  .product_navigation[data-active="3"] .product_navigation__page--main li[data-id="3"] a {
    opacity: 1; }
  .product_navigation[data-active="4"] ul[data-id="4"] {
    max-height: 100%;
    overflow: visible;
    transition-delay: 0s; }
  .product_navigation[data-active="4"] .product_navigation__page--main li a {
    opacity: 0.15; }
  .product_navigation[data-active="4"] .product_navigation__page--main li[data-id="4"] a {
    opacity: 1; }
  .product_navigation[data-active="5"] ul[data-id="5"] {
    max-height: 100%;
    overflow: visible;
    transition-delay: 0s; }
  .product_navigation[data-active="5"] .product_navigation__page--main li a {
    opacity: 0.15; }
  .product_navigation[data-active="5"] .product_navigation__page--main li[data-id="5"] a {
    opacity: 1; }
  .product_navigation[data-active="6"] ul[data-id="6"] {
    max-height: 100%;
    overflow: visible;
    transition-delay: 0s; }
  .product_navigation[data-active="6"] .product_navigation__page--main li a {
    opacity: 0.15; }
  .product_navigation[data-active="6"] .product_navigation__page--main li[data-id="6"] a {
    opacity: 1; }
  .product_navigation[data-active="7"] ul[data-id="7"] {
    max-height: 100%;
    overflow: visible;
    transition-delay: 0s; }
  .product_navigation[data-active="7"] .product_navigation__page--main li a {
    opacity: 0.15; }
  .product_navigation[data-active="7"] .product_navigation__page--main li[data-id="7"] a {
    opacity: 1; }
  .product_navigation[data-active="8"] ul[data-id="8"] {
    max-height: 100%;
    overflow: visible;
    transition-delay: 0s; }
  .product_navigation[data-active="8"] .product_navigation__page--main li a {
    opacity: 0.15; }
  .product_navigation[data-active="8"] .product_navigation__page--main li[data-id="8"] a {
    opacity: 1; }
  .product_navigation[data-active="9"] ul[data-id="9"] {
    max-height: 100%;
    overflow: visible;
    transition-delay: 0s; }
  .product_navigation[data-active="9"] .product_navigation__page--main li a {
    opacity: 0.15; }
  .product_navigation[data-active="9"] .product_navigation__page--main li[data-id="9"] a {
    opacity: 1; }
  .product_navigation[data-active="10"] ul[data-id="10"] {
    max-height: 100%;
    overflow: visible;
    transition-delay: 0s; }
  .product_navigation[data-active="10"] .product_navigation__page--main li a {
    opacity: 0.15; }
  .product_navigation[data-active="10"] .product_navigation__page--main li[data-id="10"] a {
    opacity: 1; }
  @media screen and (max-width: 1200px) {
    .product_navigation {
      transform: translateX(25%); } }
  @media screen and (max-width: 840px) {
    .product_navigation {
      display: block;
      transform: none;
      vertical-align: top; }
      .product_navigation[data-active] {
        transform: translateX(-100%); } }

.product_navigation__page {
  display: inline-block;
  padding: 0 40px;
  vertical-align: middle;
  text-align: left; }
  .product_navigation__page li {
    border-top: 1px solid #E4E4E4;
    transition: transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    .product_navigation__page li[data-id="0"] {
      transition-delay: 0.3s; }
    .product_navigation__page li[data-id="1"] {
      transition-delay: 0.4s; }
    .product_navigation__page li[data-id="2"] {
      transition-delay: 0.5s; }
    .product_navigation__page li[data-id="3"] {
      transition-delay: 0.6s; }
    .product_navigation__page li[data-id="4"] {
      transition-delay: 0.7s; }
    .product_navigation__page li[data-id="5"] {
      transition-delay: 0.8s; }
    .product_navigation__page li[data-id="6"] {
      transition-delay: 0.9s; }
    .product_navigation__page li[data-id="7"] {
      transition-delay: 1s; }
    .product_navigation__page li[data-id="8"] {
      transition-delay: 1.1s; }
    .product_navigation__page li[data-id="9"] {
      transition-delay: 1.2s; }
    .product_navigation__page li[data-id="10"] {
      transition-delay: 1.3s; }
    .product_navigation__page li:first-child {
      border-top: none; }
    ._reveal .product_navigation__page li, .reveal .product_navigation__page li {
      transform: translateY(100px);
      opacity: 0;
      transition-delay: 0s !important; }
  .product_navigation__page a {
    font-size: 30px;
    padding: 28px 30px 20px;
    line-height: 1.35;
    display: inline-block;
    width: 100%;
    color: #000000;
    text-decoration: none;
    font-family: "JannonAntiqua", serif;
    opacity: 1;
    transition: opacity 0.5s, transform 0.3s;
    transform: none;
    white-space: normal; }
    .product_navigation__page a:hover {
      transform: translateX(10px);
      opacity: 1 !important; }
    @media screen and (max-width: 1200px) {
      .product_navigation__page a {
        font-size: 22px;
        padding: 21px 30px 15px; } }
  @media screen and (max-width: 840px) {
    .product_navigation__page {
      vertical-align: top; } }
  @media screen and (max-width: 600px) {
    .product_navigation__page {
      padding-left: 20px;
      padding-right: 20px; } }

.product_navigation__page__back {
  opacity: 0.4; }
  .product_navigation__page__back svg {
    vertical-align: bottom;
    height: 30px;
    position: relative;
    top: -5px; }
    @media screen and (max-width: 1200px) {
      .product_navigation__page__back svg {
        top: -1px; } }
  .product_navigation__page__back a {
    padding: 10px 0;
    font-family: "freight-sans-pro", sans-serif;
    font-size: 18px;
    line-height: 2.3;
    letter-spacing: 0.23em;
    font-weight: 600;
    text-transform: uppercase; }
    .product_navigation__page__back a:hover {
      transform: translateX(-10px); }
    @media screen and (max-width: 1200px) {
      .product_navigation__page__back a {
        font-size: 13px; } }

.product_navigation__page--main {
  width: 33.33333%;
  border-right: 1px solid rgba(228, 228, 228, 0);
  transition: border-color 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  @media screen and (max-width: 1200px) {
    .product_navigation__page--main {
      width: 50%; } }
  @media screen and (max-width: 840px) {
    .product_navigation__page--main {
      width: 100%; } }

.product_navigation__page--detail {
  width: 66.66666%;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  @media screen and (max-width: 1200px) {
    .product_navigation__page--detail {
      width: 50%; } }
  @media screen and (max-width: 840px) {
    .product_navigation__page--detail {
      width: 100%; } }

.product_navigation__page__detail {
  position: relative;
  width: 100%;
  white-space: normal; }
  .product_navigation__page__detail ul {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0s 0.5s; }

.subject_slider {
  padding: 100px 0 80px 0;
  color: #FFFFFF;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden; }
  .subject_slider .subject_slider__headline__main h2 {
    transform: translateX(-50%); }
  .subject_slider .subject_slider__text__main p {
    transform: translateX(-50%); }
  .subject_slider[data-active="0"] .subject_slider__navigation li.data_id_0 {
    opacity: 1; }
    .subject_slider[data-active="0"] .subject_slider__navigation li.data_id_0 a:before {
      width: 20px; }
  .subject_slider[data-active="0"] .subject_slider__headline__main h2.data_id_0 {
    transform: none;
    opacity: 1;
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s, opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s; }
    .subject_slider[data-active="0"] .subject_slider__headline__main h2.data_id_0 ~ h2 {
      transform: scale(0.9); }
  .subject_slider[data-active="0"] .subject_slider__text__main p.data_id_0 {
    transform: none;
    opacity: 1;
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s, opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s; }
    .subject_slider[data-active="0"] .subject_slider__text__main p.data_id_0 ~ p {
      transform: scale(0.9); }
  .subject_slider[data-active="0"] .subject_slider__image__item.data_id_0 ~ .subject_slider__image__item {
    opacity: 0; }
  .subject_slider .subject_slider__headline__main h2 {
    transform: translateX(-50%); }
  .subject_slider .subject_slider__text__main p {
    transform: translateX(-50%); }
  .subject_slider[data-active="1"] .subject_slider__navigation li.data_id_1 {
    opacity: 1; }
    .subject_slider[data-active="1"] .subject_slider__navigation li.data_id_1 a:before {
      width: 20px; }
  .subject_slider[data-active="1"] .subject_slider__headline__main h2.data_id_1 {
    transform: none;
    opacity: 1;
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s, opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s; }
    .subject_slider[data-active="1"] .subject_slider__headline__main h2.data_id_1 ~ h2 {
      transform: scale(0.9); }
  .subject_slider[data-active="1"] .subject_slider__text__main p.data_id_1 {
    transform: none;
    opacity: 1;
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s, opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s; }
    .subject_slider[data-active="1"] .subject_slider__text__main p.data_id_1 ~ p {
      transform: scale(0.9); }
  .subject_slider[data-active="1"] .subject_slider__image__item.data_id_1 ~ .subject_slider__image__item {
    opacity: 0; }
  .subject_slider .subject_slider__headline__main h2 {
    transform: translateX(-50%); }
  .subject_slider .subject_slider__text__main p {
    transform: translateX(-50%); }
  .subject_slider[data-active="2"] .subject_slider__navigation li.data_id_2 {
    opacity: 1; }
    .subject_slider[data-active="2"] .subject_slider__navigation li.data_id_2 a:before {
      width: 20px; }
  .subject_slider[data-active="2"] .subject_slider__headline__main h2.data_id_2 {
    transform: none;
    opacity: 1;
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s, opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s; }
    .subject_slider[data-active="2"] .subject_slider__headline__main h2.data_id_2 ~ h2 {
      transform: scale(0.9); }
  .subject_slider[data-active="2"] .subject_slider__text__main p.data_id_2 {
    transform: none;
    opacity: 1;
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s, opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s; }
    .subject_slider[data-active="2"] .subject_slider__text__main p.data_id_2 ~ p {
      transform: scale(0.9); }
  .subject_slider[data-active="2"] .subject_slider__image__item.data_id_2 ~ .subject_slider__image__item {
    opacity: 0; }
  .subject_slider .subject_slider__headline__main h2 {
    transform: translateX(-50%); }
  .subject_slider .subject_slider__text__main p {
    transform: translateX(-50%); }
  .subject_slider[data-active="3"] .subject_slider__navigation li.data_id_3 {
    opacity: 1; }
    .subject_slider[data-active="3"] .subject_slider__navigation li.data_id_3 a:before {
      width: 20px; }
  .subject_slider[data-active="3"] .subject_slider__headline__main h2.data_id_3 {
    transform: none;
    opacity: 1;
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s, opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s; }
    .subject_slider[data-active="3"] .subject_slider__headline__main h2.data_id_3 ~ h2 {
      transform: scale(0.9); }
  .subject_slider[data-active="3"] .subject_slider__text__main p.data_id_3 {
    transform: none;
    opacity: 1;
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s, opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s; }
    .subject_slider[data-active="3"] .subject_slider__text__main p.data_id_3 ~ p {
      transform: scale(0.9); }
  .subject_slider[data-active="3"] .subject_slider__image__item.data_id_3 ~ .subject_slider__image__item {
    opacity: 0; }
  .subject_slider .subject_slider__headline__main h2 {
    transform: translateX(-50%); }
  .subject_slider .subject_slider__text__main p {
    transform: translateX(-50%); }
  .subject_slider[data-active="4"] .subject_slider__navigation li.data_id_4 {
    opacity: 1; }
    .subject_slider[data-active="4"] .subject_slider__navigation li.data_id_4 a:before {
      width: 20px; }
  .subject_slider[data-active="4"] .subject_slider__headline__main h2.data_id_4 {
    transform: none;
    opacity: 1;
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s, opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s; }
    .subject_slider[data-active="4"] .subject_slider__headline__main h2.data_id_4 ~ h2 {
      transform: scale(0.9); }
  .subject_slider[data-active="4"] .subject_slider__text__main p.data_id_4 {
    transform: none;
    opacity: 1;
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s, opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s; }
    .subject_slider[data-active="4"] .subject_slider__text__main p.data_id_4 ~ p {
      transform: scale(0.9); }
  .subject_slider[data-active="4"] .subject_slider__image__item.data_id_4 ~ .subject_slider__image__item {
    opacity: 0; }
  .subject_slider .subject_slider__headline__main h2 {
    transform: translateX(-50%); }
  .subject_slider .subject_slider__text__main p {
    transform: translateX(-50%); }
  .subject_slider[data-active="5"] .subject_slider__navigation li.data_id_5 {
    opacity: 1; }
    .subject_slider[data-active="5"] .subject_slider__navigation li.data_id_5 a:before {
      width: 20px; }
  .subject_slider[data-active="5"] .subject_slider__headline__main h2.data_id_5 {
    transform: none;
    opacity: 1;
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s, opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s; }
    .subject_slider[data-active="5"] .subject_slider__headline__main h2.data_id_5 ~ h2 {
      transform: scale(0.9); }
  .subject_slider[data-active="5"] .subject_slider__text__main p.data_id_5 {
    transform: none;
    opacity: 1;
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s, opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s; }
    .subject_slider[data-active="5"] .subject_slider__text__main p.data_id_5 ~ p {
      transform: scale(0.9); }
  .subject_slider[data-active="5"] .subject_slider__image__item.data_id_5 ~ .subject_slider__image__item {
    opacity: 0; }
  @media screen and (max-width: 840px) {
    .subject_slider {
      max-width: 500px;
      padding-top: 50px; } }

.subject_slider__content {
  width: 100%;
  position: relative;
  z-index: 1; }

.subject_slider__image__list {
  position: absolute;
  top: 0;
  right: 40%;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0; }
  .subject_slider__image__list:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.35), transparent);
    z-index: 5; }
  @media screen and (max-width: 840px) {
    .subject_slider__image__list {
      right: 10%;
      padding-bottom: 90%;
      bottom: auto; } }

.subject_slider__image__item {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 1;
  transition: opacity 1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.subject_slider__title {
  width: 100%;
  white-space: normal;
  position: relative;
  z-index: 5; }
  .subject_slider__title:after {
    content: '';
    display: block;
    float: none;
    clear: both; }

.subject_slider__navigation {
  float: right;
  padding: 20px 40px;
  width: 100%;
  max-width: 40%;
  position: relative;
  z-index: 3; }
  .subject_slider__navigation li {
    opacity: 0.3;
    white-space: normal;
    transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1); }
    .subject_slider__navigation li a {
      display: block;
      padding: 10px 10px 10px 30px;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-weight: bold;
      color: inherit;
      position: relative; }
      .subject_slider__navigation li a:before {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -1px;
        left: 0;
        height: 2px;
        width: 10px;
        background: #FFFFFF;
        transition: width 0.5s cubic-bezier(0.165, 0.84, 0.44, 1); }
  @media screen and (max-width: 840px) {
    .subject_slider__navigation {
      max-width: 100%;
      width: 100%;
      float: none;
      padding: 0 20px;
      margin-bottom: 40px; } }

.subject_slider__headline {
  padding: 40px 44% 40px 80px; }
  @media screen and (max-width: 840px) {
    .subject_slider__headline {
      padding: 40px 10% 0 20px; } }

.subject_slider__headline__main {
  width: 100%;
  display: block;
  letter-spacing: -10px;
  transform: none;
  white-space: nowrap; }
  .subject_slider__headline__main h2 {
    letter-spacing: normal;
    white-space: normal;
    margin: 0;
    display: inline-block;
    width: 100%;
    opacity: 0;
    text-align: center;
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22), opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    vertical-align: top; }
    .subject_slider__headline__main h2.data_id_0 {
      position: relative;
      left: 0%; }
    .subject_slider__headline__main h2.data_id_1 {
      position: relative;
      left: -100%; }
    .subject_slider__headline__main h2.data_id_2 {
      position: relative;
      left: -200%; }
    .subject_slider__headline__main h2.data_id_3 {
      position: relative;
      left: -300%; }
    .subject_slider__headline__main h2.data_id_4 {
      position: relative;
      left: -400%; }
    .subject_slider__headline__main h2.data_id_5 {
      position: relative;
      left: -500%; }
    @media screen and (max-width: 840px) {
      .subject_slider__headline__main h2 {
        text-align: center; } }

.subject_slider__text {
  width: 100%;
  text-align: right;
  position: relative;
  z-index: 6;
  margin-top: 30px; }
  @media screen and (max-width: 840px) {
    .subject_slider__text {
      margin-top: 40%; } }

.subject_slider__text__main {
  max-width: 50%;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
  letter-spacing: -4px;
  transform: none; }
  .subject_slider__text__main p {
    display: inline-block;
    vertical-align: top;
    background: #FFFFFF;
    padding: 40px;
    color: #333333;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.05em;
    white-space: normal;
    opacity: 0;
    transition: transform 1s cubic-bezier(0.895, 0.03, 0.685, 0.22), opacity 1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }
    .subject_slider__text__main p.data_id_0 {
      position: relative;
      left: 0%; }
    .subject_slider__text__main p.data_id_1 {
      position: relative;
      left: -100%; }
    .subject_slider__text__main p.data_id_2 {
      position: relative;
      left: -200%; }
    .subject_slider__text__main p.data_id_3 {
      position: relative;
      left: -300%; }
    .subject_slider__text__main p.data_id_4 {
      position: relative;
      left: -400%; }
    .subject_slider__text__main p.data_id_5 {
      position: relative;
      left: -500%; }
  @media screen and (max-width: 840px) {
    .subject_slider__text__main {
      max-width: 90%; }
      .subject_slider__text__main p {
        padding: 30px 15px 30px 30px; } }

.subject_slider__controls {
  height: 90px;
  width: 100%;
  white-space: nowrap;
  font-size: 0;
  position: relative;
  z-index: 10; }
  .subject_slider__controls a {
    cursor: pointer;
    padding: 0 30px;
    height: 100%;
    width: 50%;
    text-align: right;
    display: inline-block; }
    .subject_slider__controls a svg {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin: 20px 0;
      fill: #FFFFFF; }
    .subject_slider__controls a:first-child {
      text-align: left; }
  @media screen and (max-width: 840px) {
    .subject_slider__controls {
      margin-top: 20px; } }

.selection_slider {
  width: 100%;
  height: 60px;
  line-height: 60px;
  position: relative;
  user-select: none;
  will-change: transform; }
  .selection_slider input {
    display: none; }
  .selection_slider:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    width: 80px;
    height: 100%;
    margin-left: -40px;
    background: #000000;
    opacity: 0.1; }
  .selection_slider.large:after {
    width: 120px;
    margin-left: -60px; }
  .selection_slider.extra_large:after {
    width: 150px;
    margin-left: -75px; }
  .selection_slider.dark:after {
    background: #FFFFFF; }

.selection_slider__selection {
  position: absolute;
  top: 0;
  left: 50%;
  width: 80px;
  height: 100%;
  margin-left: -40px;
  border: 2px solid #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  z-index: 10;
  pointer-events: none; }
  .selection_slider.large .selection_slider__selection {
    width: 120px;
    margin-left: -60px; }
  .selection_slider.extra_large .selection_slider__selection {
    width: 150px;
    margin-left: -75px; }
  .selection_slider.dark .selection_slider__selection {
    border-color: #000000; }

.selection_slider__frame {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 3;
  will-change: transform;
  cursor: grab; }
  .selection_slider__frame:before {
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #000000, rgba(0, 0, 0, 0), #000000);
    z-index: 5; }
    .selection_slider.dark .selection_slider__frame:before {
      background: linear-gradient(to right, #FFFFFF, rgba(255, 255, 255, 0), #FFFFFF); }
    .no-csspointerevents .selection_slider__frame:before {
      display: none; }
  .selection_slider__frame:active {
    cursor: grabbing; }

.selection_slider__content {
  width: 100%;
  height: 80px;
  padding-bottom: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  white-space: nowrap;
  font-size: 0; }
  .selection_slider__content:before, .selection_slider__content:after {
    content: '';
    display: inline-block;
    width: 50%;
    height: 100%;
    vertical-align: top;
    margin-left: -40px; }
  .selection_slider.large .selection_slider__content:before, .selection_slider.large .selection_slider__content:after {
    margin-left: -60px; }
  .selection_slider.extra_large .selection_slider__content:before, .selection_slider.extra_large .selection_slider__content:after {
    margin-left: -75px; }
  html.touchevents .selection_slider__content {
    overflow: hidden; }

.selection_slider__list {
  width: auto;
  height: 100%;
  display: inline-block;
  position: relative; }

.selection_slider__item {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: inline-block;
  width: 80px;
  height: 100%; }
  .selection_slider__item:first-child {
    border-left: 0; }
  .selection_slider.large .selection_slider__item {
    width: 120px; }
  .selection_slider.extra_large .selection_slider__item {
    width: 150px; }
  .selection_slider.dark .selection_slider__item {
    border-color: rgba(0, 0, 0, 0.2); }

.selection_slider__element {
  display: block;
  width: 100%;
  height: 100%;
  color: inherit;
  text-decoration: none;
  font-family: "roboto", sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.01em; }
  .selection_slider__element.svg {
    padding: 15px 0; }
  .selection_slider__element svg {
    width: 30px;
    height: 30px;
    display: block;
    margin: 0 auto;
    fill: #FFFFFF; }

.product_overview_frame {
  background: #F4F4F4;
  padding: 80px 30px; }
  @media screen and (max-width: 840px) {
    .product_overview_frame {
      padding-top: 50px; } }

.product_overview {
  max-width: 1400px;
  margin: 0 auto; }

.product_overview__content {
  margin: 0 -1%;
  font-size: 0; }

.product_overview__category {
  margin: 0 0 10px 0;
  display: inline-block;
  width: 100%; }
  .product_overview__category h3 {
    padding: 20px 25px;
    font-size: 14px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    line-height: 1.3; }

.product_overview__category--thirds-right .product_overview__item.product_overview__item--last {
  width: 48%; }

.product_overview__category--thirds-left .product_overview__item.product_overview__item--first {
  width: 48%; }

.product_overview__category--inline {
  width: 33.33333%; }
  .product_overview__category--inline .product_overview__item {
    width: 94%;
    margin: 0 3%;
    padding-bottom: 45%; }
  @media screen and (max-width: 600px) {
    .product_overview__category--inline {
      width: 100%; } }

.product_overview__item {
  margin: 0 1%;
  width: 23%;
  padding-bottom: 15%;
  display: inline-block;
  position: relative;
  white-space: normal;
  overflow: hidden; }
  .product_overview__item:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000;
    opacity: 0.3;
    z-index: 4; }
  .product_overview__item h4 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    padding: 0 10%;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
    line-height: 1.3;
    letter-spacing: 0.14em;
    z-index: 5; }
    @media screen and (max-width: 1200px) {
      .product_overview__item h4 {
        font-size: 16px; } }
    @media screen and (max-width: 840px) {
      .product_overview__item h4 {
        font-size: 14px; } }
  .product_overview__item:hover .product_overview__item__background {
    transform: scale(1.1); }
  @media screen and (max-width: 600px) {
    .product_overview__item {
      width: 100% !important;
      margin: 0 0 20px 0 !important;
      padding-bottom: 30% !important; } }

.product_overview__item__background {
  display: block;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: none; }

.product_slider__frame {
  width: 100%;
  overflow: hidden; }

.product_slider {
  padding: 80px 20px;
  max-width: 1200px;
  margin: 0 auto; }
  .product_slider[data-active="0"] .product_slider__content {
    transform: translateX(0%); }
    @media screen and (max-width: 840px) {
      .product_slider[data-active="0"] .product_slider__content {
        transform: translateX(0%); } }
  .product_slider[data-active="0"] .product_slider__nav__pages li[data-id="0"] a:before {
    opacity: 1; }
  .product_slider[data-active="1"] .product_slider__content {
    transform: translateX(-33.33333%); }
    @media screen and (max-width: 840px) {
      .product_slider[data-active="1"] .product_slider__content {
        transform: translateX(-100%); } }
  .product_slider[data-active="1"] .product_slider__nav__pages li[data-id="1"] a:before {
    opacity: 1; }
  .product_slider[data-active="2"] .product_slider__content {
    transform: translateX(-66.66667%); }
    @media screen and (max-width: 840px) {
      .product_slider[data-active="2"] .product_slider__content {
        transform: translateX(-200%); } }
  .product_slider[data-active="2"] .product_slider__nav__pages li[data-id="2"] a:before {
    opacity: 1; }
  .product_slider[data-active="3"] .product_slider__content {
    transform: translateX(-100.0%); }
    @media screen and (max-width: 840px) {
      .product_slider[data-active="3"] .product_slider__content {
        transform: translateX(-300%); } }
  .product_slider[data-active="3"] .product_slider__nav__pages li[data-id="3"] a:before {
    opacity: 1; }
  .product_slider[data-active="4"] .product_slider__content {
    transform: translateX(-133.33333%); }
    @media screen and (max-width: 840px) {
      .product_slider[data-active="4"] .product_slider__content {
        transform: translateX(-400%); } }
  .product_slider[data-active="4"] .product_slider__nav__pages li[data-id="4"] a:before {
    opacity: 1; }
  .product_slider[data-active="5"] .product_slider__content {
    transform: translateX(-166.66667%); }
    @media screen and (max-width: 840px) {
      .product_slider[data-active="5"] .product_slider__content {
        transform: translateX(-500%); } }
  .product_slider[data-active="5"] .product_slider__nav__pages li[data-id="5"] a:before {
    opacity: 1; }
  .product_slider[data-active="6"] .product_slider__content {
    transform: translateX(-200%); }
    @media screen and (max-width: 840px) {
      .product_slider[data-active="6"] .product_slider__content {
        transform: translateX(-600%); } }
  .product_slider[data-active="6"] .product_slider__nav__pages li[data-id="6"] a:before {
    opacity: 1; }
  .product_slider[data-active="7"] .product_slider__content {
    transform: translateX(-233.33333%); }
    @media screen and (max-width: 840px) {
      .product_slider[data-active="7"] .product_slider__content {
        transform: translateX(-700%); } }
  .product_slider[data-active="7"] .product_slider__nav__pages li[data-id="7"] a:before {
    opacity: 1; }
  .product_slider[data-active="8"] .product_slider__content {
    transform: translateX(-266.66667%); }
    @media screen and (max-width: 840px) {
      .product_slider[data-active="8"] .product_slider__content {
        transform: translateX(-800%); } }
  .product_slider[data-active="8"] .product_slider__nav__pages li[data-id="8"] a:before {
    opacity: 1; }
  .product_slider[data-active="9"] .product_slider__content {
    transform: translateX(-300%); }
    @media screen and (max-width: 840px) {
      .product_slider[data-active="9"] .product_slider__content {
        transform: translateX(-900%); } }
  .product_slider[data-active="9"] .product_slider__nav__pages li[data-id="9"] a:before {
    opacity: 1; }
  .product_slider[data-active="10"] .product_slider__content {
    transform: translateX(-333.33333%); }
    @media screen and (max-width: 840px) {
      .product_slider[data-active="10"] .product_slider__content {
        transform: translateX(-1000%); } }
  .product_slider[data-active="10"] .product_slider__nav__pages li[data-id="10"] a:before {
    opacity: 1; }
  .product_slider[data-active="0"] .product_slider__nav--prev {
    opacity: 0; }
  .product_slider[data-active="9"] .product_slider__nav--next {
    opacity: 0; }

.product_slider__content {
  margin: 0 -1%;
  white-space: nowrap;
  transform: none;
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  font-size: 0; }
  .product_slider__content .product_list__item {
    padding: 0 10px; }

.product_slider__nav__frame {
  width: 100%;
  position: relative;
  text-align: center; }

.product_slider__nav__pages {
  display: inline-block;
  font-size: 0;
  padding: 0 80px;
  white-space: nowrap; }
  .product_slider__nav__pages li {
    display: inline-block; }
    .product_slider__nav__pages li a {
      height: 60px;
      width: 25px;
      display: inline-block;
      position: relative; }
      .product_slider__nav__pages li a:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        margin-left: -4px;
        margin-top: -4px;
        background: #000000;
        border-radius: 50%;
        opacity: 0.15;
        transform: none;
        transition: opacity 0.3s, transform 0.3s;
        will-change: transform; }
      .product_slider__nav__pages li a:hover:before {
        transform: scale(1.2);
        opacity: 0.5; }
  @media screen and (max-width: 840px) {
    .product_slider__nav__pages {
      padding: 0 0 60px; } }

.product_slider__nav {
  position: absolute;
  bottom: 0;
  padding: 10px 20px;
  opacity: 1;
  transition: opacity 0.3s, transform 0.3s;
  transform: none; }
  .product_slider__nav svg {
    display: block;
    height: 40px;
    width: auto; }

.product_slider__nav--prev {
  left: 0; }
  .product_slider__nav--prev:hover {
    transform: translateX(-10px); }

.product_slider__nav--next {
  right: 0; }
  .product_slider__nav--next:hover {
    transform: translateX(10px); }
