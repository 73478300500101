#quality {
  background: $light_gray;
  color: $near_black;
  padding: 80px 20px 0;
  border-top: 16px solid $white;
  @media screen and (max-width: $medium_breakpoint) {
    border-top: 8px solid $white;
  }
}

.quality__content {
  max-width: 800px;
  margin: 0 auto;
}

.quality__quote__frame {
  padding: 90px 0;
  margin: 0 auto;
  @media screen and (max-width: $medium_breakpoint) {
    max-width: 400px;
  }
}

.quality__quote {
  font-size: 30px;
  line-height: 1.5;
  font-family: $JannonAntiqua;
  padding: 0 30px;
  position: relative;
  &:before, &:after {
    content: '„';
    font-size: 55px;
    opacity: 0.3;
  }
  &:before {
    position: absolute;
    top: -40px;
    left: 0;
  }
  &:after {
    content: '“';
    position: absolute;
    margin-left: 10px;
    margin-top: 15px;
  }
  @media screen and (max-width: $medium_breakpoint) {
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    &:before, &:after {
      font-size: 35px;
    }
  }
}

.quality__person {
  width: 100%;
  text-align: right;
  margin-top: 60px;
}

.quality__person__content {
  display: inline-block;
  text-align: left;
}

.quality__signature {
  width: 240px;
  svg {
    .stroke {
      stroke: currentColor !important;
    }
  }
}

.quality__function {
  width: 100%;
  font-style: italic;
  display: block;
  margin-top: -15px;
}

.quality__promise__list {
  width: 100%;
  white-space: nowrap;
  font-size: 0;
  position: relative;
  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
    width: 1px;
    top: 35px;
    bottom: 35px;
    background: currentColor;
    opacity: 0.3;
    left: 30%;
  }
  &:after {
    left: auto;
    right: 34%;
  }
  @media screen and (max-width: $medium_breakpoint) {
    &:before, &:after {
      display: none;
    }
  }
}

.quality__promise__list__separator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: currentColor;
  opacity: 0.3;
  @media screen and (max-width: $medium_breakpoint) {
    display: none;
  }
}

.quality__promise__item {
  display: inline-block;
  vertical-align: top;
  width: 36%;
  position: relative;
  padding: 60px 30px 60px 80px;
  white-space: normal;
  &:first-child {
    width: 30%;
    padding-left: 40px;
    .quality__promise__icon {
      left: 0;
    }
  }
  &:last-child {
    width: 34%;
    padding-right: 0;
  }
  a {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    display: block;
    width: 100% !important;
    border-top: 1px solid rgba($white, 0.3);
    padding-left: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 400px;
    margin: 0 auto;
    .quality__promise__icon {
      left: 0;
      top: 30px;
    }
  }
}

.quality__promise__icon {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 40px;
  top: 60px;
  svg {
    width: 100%;
    fill: currentColor;
    height: 100%;
  }
}

.quality__promise__title {
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: 1.5;
  margin-bottom: 10px;
}

.quality__promise__text {
  font-size: 12px;
  letter-spacing: 0.01em;
  line-height: 1.4;
  margin-bottom: 15px;
}