#footer {
  width: 100%;
  background: $white;
  font-size: 14px;
  padding: 25px 20px 10px;
  ul {
    display: inline-block;
    margin-bottom: 15px;
    li {
      display: inline-block;
      padding-right: 21px;
      position: relative;
      a {
        line-height: 30px;
        text-decoration: none;
        color: $near_black;
      }
      &:before {
        content: '';
        display: block;
        height: 14px;
        background: $dark_light_gray;
        width: 1px;
        position: absolute;
        top: 9px;
        right: 9px;
      }
      &:last-child {
        padding: 0;
        &:before {
          display: none;
        }
      }
      &.active {
        a {
          font-weight: 600;
        }
      }
    }
    @media screen and (max-width: $small_breakpoint) {
      display: block;
      li {
        display: block;
      }
      &:last-child {
        li {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.footer__meta {
  margin: 0 40px 15px 0;
  li {
    display: inline-block !important;
    a {
      text-transform: uppercase;
    }
  }
}