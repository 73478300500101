.action_controls {
  width: 100%;
  color: inherit;
  select, input[type="text"] {
    width: 100%;
    height: 40px;
    border: 1px solid $white;
    font-family: $Roboto;
    border-radius: 0;
    -webkit-appearance: none;
    text-indent: 16px;
    background: transparent;
    font-size: 14px;
    font-weight: 700;
    color: $white;
    letter-spacing: 0.05em;
    display: inline-block;
    text-decoration: none;
    line-height: 38px;
    &:first-child {
      margin-left: 0;
    }
  }
  input[type="text"] {
    margin-top: 15px;
    &::-webkit-input-placeholder {
      color: rgba($white, 0.6);
      text-transform: uppercase;
    }
    &::-moz-placeholder {
      color: rgba($white, 0.6);
      text-transform: uppercase;
    }
    &:-ms-input-placeholder {
      color: rgba($white, 0.6);
      text-transform: uppercase;
    }
    &:-moz-placeholder {
      color: rgba($white, 0.6);
      text-transform: uppercase;
    }
  }
}

.action_controls__attributes {
  margin-top: 40px;
}

.action_controls__select {
  width: 48%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  &:first-child {
    margin-right: 4%;
  }
  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid $white;
    border-right: 2px solid $white;
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    right: 20px;
  }
  &.errors {
    select {
      border-color: red;
    }
  }
  a {
    display: inline-block;
    margin-top: 5px;
    font-size: 13px;
    text-decoration: none;
    color: $white;
    border-bottom: 1px solid rgba($white, 0.3);
    transition: border 0.2s;
    &:hover {
      border-bottom-color: rgba($white, 0.6);
    }
  }
}

.action_controls__price_table {
  display: none;
}

.action_controls__price {
  font-family: $JannonAntiqua;
  font-size: 25px;
  height: 45px;
  line-height: 45px;
  position: relative;
  border-bottom: 1px solid $white;
  text-align: right;
  .action_controls__currency {
    position: absolute;
    top: 0;
    left: 0;
  }
  &.request {
    text-align: center;
  }
}

.action_controls__buttons {
  width: 100%;
  margin: 40px 0 0 0;
  font-size: 0;
  white-space: nowrap;
  .button {
    width: 48%;
    margin: 0;
    &:first-child {
      margin-right: 4%;
    }
  }
}