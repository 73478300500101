body.overlayopen {
  transition: background 0s 0s;
  .header__logo {
    opacity: 0;
    transition: opacity 0.2s;
  }
  #canvas {
    transform: scale(0.9);
    opacity: 0.8;
    will-change: transform;
  }
  .overlay {
    &.active {
      height: auto;
      min-height: 100%;
      opacity: 1;
      transform: none;
      transition: min-height 0s 0s, transform 1s $easeOutQuart 0.1s, opacity 1s $easeOutQuart 0.1s;
    }
  }
}

.overlay {
  width: 100%;
  background: $white;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  min-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: scale(1.1);
  z-index: 500;
  transition: min-height 0s 1.1s, transform 1s $easeOutQuart, opacity 1s $easeOutQuart;
  &.transition {
    opacity: 0 !important;
    transform: scale(1.1) !important;
    transition: transform 0.5s $easeInQuart, opacity 0.5s $easeInQuart !important;
  }
}

.overlay__foter {
  text-align: center;
  transition: transform $reveal_duration $reveal_timing_function 0.5s,
  opacity $reveal_duration $reveal_timing_function 0.5s;
  ._reveal &, .reveal & {
    transform: translateY($reveal_delta);
    opacity: 0;
    transition: transform 0s 1s,
    opacity 0s 1s;
  }
}

.overlay--navigation {
  .overlay__main {
    padding-bottom: 200px;
  }
}

.overlay__separator {
  width: 30px;
  height: 1px;
  background: $gray;
  border: none;
  display: block;
  margin: 0;
  transition: width 0.5s $easeOutQuart 4s;
  ._reveal &, .reveal & {
    width: 0;
  }
}

.overlay__logo {
  text-align: center;
  .overlay--header & {
    position: relative;
    z-index: 5;
    svg {
      fill: $white;
    }
  }
}

.overlay_close {
  position: absolute;
  top: 25px;
  right: 40px;
  width: 50px;
  height: 50px;
  z-index: 100;
  &:before, &:after {
    content: '';
    height: 1px;
    width: 30px;
    position: absolute;
    top: 25px;
    left: 50%;
    margin-left: -15px;
    background: $gray;
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  .overlay--header & {
    &:before, &:after {
      background: $white;
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    top: 15px;
    right: 30px;
  }
  @media screen and (max-width: $small_breakpoint) {
    right: 15px;
  }
}

.overlay__title__frame {
  width: 100%;
  .overlay--header & {
    padding-bottom: 40px;
    color: $white;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.6;
      background: $black;
      z-index: 2;
    }
    &:before, &:after {
      transition: transform $reveal_duration $reveal_timing_function,
      opacity $reveal_duration $reveal_timing_function;
    }
    .overlay__title {
      position: relative;
      z-index: 3;
    }
  }
  .overlay--header._reveal &, .overlay--header.reveal & {
    &:before, &:after {
      transform: scale(0.9);
      opacity: 0;
      transition: transform 0s 1s,
      opacity 0s 1s;
    }
  }
  .overlay--header--boutique & {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-position: center;
      background-size: cover;
      background-image: url('/static/img/blesq_boutique.jpg');
    }
  }
}

.overlay__title {
  text-align: center;
  padding: 100px 80px 60px;
  max-width: 1200px;
  margin: 0 auto;
  @media screen and (max-width: $medium_breakpoint) {
    padding: 80px 20px;
  }
  @media screen and (max-width: $small_breakpoint) {
    padding: 60px 20px;
  }
}

.overlay__introline {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  line-height: 1.5;
  display: inline-block;
  transition: transform $reveal_duration $reveal_timing_function,
  opacity $reveal_duration $reveal_timing_function;
  ._reveal &, .reveal & {
    transform: translateY($reveal_delta);
    opacity: 0;
    transition: transform 0s 1s,
    opacity 0s 1s;
  }
  @media screen and (max-width: $large_breakpoint) {
    font-size: 14px;
  }
  @media screen and (max-width: $medium_breakpoint) {
    font-size: 12px;
  }
}

.overlay__breadcumbs {
  display: inline-block;
  font-size: 0;
  transition: transform $reveal_duration $reveal_timing_function,
  opacity $reveal_duration $reveal_timing_function;
  ._reveal &, .reveal & {
    transform: translateY($reveal_delta);
    opacity: 0;
    transition: transform 0s 1s,
    opacity 0s 1s;
  }
  li {
    display: inline-block;
    font-size: 13px;
    letter-spacing: 0.05em;
    line-height: 20px;
    padding-left: 30px;
    position: relative;
    &:first-child {
      padding-left: 0;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}

.overlay__breadcumbs__icon {
  position: absolute;
  top: 0;
  left: 5px;
  height: 20px;
  width: 20px;
  svg {
    display: block;
    width: 20px;
    height: 20px;
    fill: currentColor;
  }
}

.overlay__headline {
  font-family: $JannonAntiqua;
  font-size: 50px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 15px;
  line-height: 1.4;
  display: block;
  transition: transform $reveal_duration $reveal_timing_function 0.4s,
  opacity $reveal_duration $reveal_timing_function 0.4s;
  ._reveal &, .reveal & {
    transform: translateY($reveal_delta);
    opacity: 0;
    transition: transform 0s 1s,
    opacity 0s 1s;
  }
  @media screen and (max-width: $large_breakpoint) {
    font-size: 42px;
  }
  @media screen and (max-width: $medium_breakpoint) {
    font-size: 34px;
  }
  @media screen and (max-width: $small_breakpoint) {
    font-size: 24px;
  }
}

.overlay__text {
  letter-spacing: 0.05em;
  font-size: 16px;
  transition: transform $reveal_duration $reveal_timing_function 0.6s,
  opacity $reveal_duration $reveal_timing_function 0.6s;
  ._reveal &, .reveal & {
    transform: translateY($reveal_delta);
    opacity: 0;
    transition: transform 0s 1s,
    opacity 0s 1s;
  }
  @media screen and (max-width: $medium_breakpoint) {
    font-size: 14px;
  }
  @media screen and (max-width: $small_breakpoint) {
    font-size: 13px;
  }
}

.overlay__footer {
  text-align: left;
  margin: 50px 0 0 0;
  li {
    margin: 20px 0;
    a {
      text-decoration: none;
      color: $near_black;
      letter-spacing: 2px;
      font-size: 15px;
      text-transform: uppercase;
    }
    @for $i from 0 through 5 {
      &.data_id_#{$i} {
        transition: transform $reveal_duration $reveal_timing_function 0.15s * $i + 2s,
        opacity $reveal_duration $reveal_timing_function 0.15s * $i + 2s;
      }
    }
    ._reveal &, .reveal & {
      transform: translateY($reveal_delta);
      opacity: 0;
    }
  }
}

.overlay__meta {
  text-align: left;
  margin: 50px 0 0 0;
  transition: transform $reveal_duration $reveal_timing_function 2.5s,
  opacity $reveal_duration $reveal_timing_function 2.5s;
  li {
    display: inline-block;
    position: relative;
    vertical-align: top;
    padding-left: 17px;
    a {
      line-height: 20px;
      text-decoration: none;
      color: $near_black;
      text-transform: uppercase;
    }
    &:before {
      content: '';
      height: 16px;
      width: 1px;
      position: absolute;
      display: block;
      left: 7px;
      top: 2px;
      background: $dark_light_gray;
    }
    &.active {
      a {
        font-weight: 600;
      }
    }
  }
  ._reveal &, .reveal & {
    transform: translateY($reveal_delta);
    opacity: 0;
    transition: transform 0s 1s,
    opacity 0s 1s;
  }
}

.overlay__meta__first {
  padding-left: 0 !important;
  &:before {
    display: none !important;
  }
}

.overlay__meta__icon {
  margin: 4px 0;
  height: 14px;
  display: block;
  svg {
    display: block;
    height: 100%;
    width: auto;
    fill: $near_black;
  }
}

.overlay__meta__social {
  margin-left: 20px;
  &:before {
    display: none !important;
  }
  transition: transform $reveal_duration $reveal_timing_function 5.5s,
  opacity $reveal_duration $reveal_timing_function 5.5s;
  ._reveal &, .reveal & {
    transform: translateY($reveal_delta/4);
    opacity: 0;
    transition: transform 0s 1s,
    opacity 0s 1s;
  }
}

.overlay__main {
  padding: 0 80px 100px;
  position: relative;
  z-index: 5;
  @media screen and (max-width: $medium_breakpoint) {
    padding: 0 20px 100px;
  }
}

.overlay__content {
  display: block;
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  @media screen and (max-width: $medium_breakpoint) {
    max-width: 450px;
  }
}

.overlay__content--columns {
  letter-spacing: -4px;
}

.overlay__choice__list {
  display: block;
  font-size: 0;
  margin: 0 -1%;
  @media screen and (max-width: $medium_breakpoint) {
    margin: 0;
  }
}

.overlay__choice__item {
  width: 50%;
  display: inline-block;
  padding: 1%;
  vertical-align: top;
  @for $i from 0 through 10 {
    &.data_id_#{$i} {
      transition: transform $reveal_duration $reveal_timing_function $i * 0.15s + 1s,
      opacity $reveal_duration $reveal_timing_function $i * 0.15s + 1s;
    }
  }
  ._reveal &, .reveal & {
    transform: translateY($reveal_delta);
    opacity: 0;
    transition: transform 0s 1s,
    opacity 0s 1s;
  }
  @media screen and (max-width: $medium_breakpoint) {
    width: 100%;
    padding: 0;
    margin: 0 0 20px 0;
  }
}

.overlay__choice {
  display: block;
  width: 100%;
  height: auto;
  text-decoration: none;
  color: inherit;
  background: $light_gray;
  padding: 40px 30px 40px 100px;
  position: relative;
  font-size: 14px;
  line-height: 1.4;
  h2 {
    font-weight: bold;
    letter-spacing: 0.1em;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  p {
    letter-spacing: 0.07em;
  }
  @media screen and (max-width: $medium_breakpoint) {
    padding: 30px 30px 30px 80px;
  }
}

.overlay__choice__icon {
  position: absolute;
  top: 40px;
  height: 40px;
  width: 40px;
  left: 40px;
  svg {
    fill: $blue;
    display: block;
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: $medium_breakpoint) {
    top: 30px;
    height: 30px;
    width: 30px;
    left: 30px;
  }
}

.overlay__navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 30px rgba($black, 0.05);
  padding: 25px 80px;
  text-align: right;
  font-size: 0;
  background: $white;
  z-index: 10;
  transition: transform $reveal_duration $reveal_timing_function,
  opacity $reveal_duration $reveal_timing_function;
  ._reveal &, .reveal & {
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0s 1s,
    opacity 0s 1s;
  }
  @media screen and (max-width: $medium_breakpoint) {
    padding: 25px 20px;
  }
  @media screen and (max-width: $small_breakpoint) {
    padding: 10px 20px;
  }
}

.overlay__navigation__content {
  max-width: 850px;
  margin: 0 auto;
}

.overlay__navigation__button {
  display: inline-block;
  width: 49%;
  margin-right: 2%;
  height: 50px;
  background: $light_gray;
  font-size: 14px;
  color: $near_black;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  vertical-align: bottom;
  line-height: 50px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  border-radius: 0;
  @media screen and (max-width: $small_breakpoint) {
    font-size: 12px;
    height: 30px;
    line-height: 30px;
  }
}

.overlay__navigation__button--next {
  margin: 0;
  background: $blue;
  color: $white;
}

.overlay__navigation__icon {
  margin: 0 10px;
  vertical-align: bottom;
  height: 50px;
  width: 50px;
  display: inline-block;
  svg {
    fill: currentColor;
    display: block;
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: $small_breakpoint) {
    height: 30px;
    width: 30px;
    margin: 0 5px;
  }
}

.overlay__aside {
  display: inline-block;
  width: 49%;
  margin-right: 2%;
  margin-top: -20px;
  letter-spacing: 0;
  vertical-align: top;
  h2 {
    font-weight: bold;
    letter-spacing: 0.1em;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  p {
    line-height: 1.6;
    .time_indent {
      display: inline-block;
      width: 60px;
    }
  }
  & + .overlay__form {
    width: 49%;
    max-width: 100%;
    display: inline-block;
  }
  transition: transform $reveal_duration $reveal_timing_function 0.6s,
  opacity $reveal_duration $reveal_timing_function 0.6s;
  ._reveal &, .reveal & {
    transform: translateY($reveal_delta);
    opacity: 0;
    transition: transform 0s 1s,
    opacity 0s 1s;
  }
  @media screen and (max-width: $medium_breakpoint) {
    width: 100%;
    margin-right: 0;
    & + .overlay__form {
      width: 100%;
    }
  }
}

.overlay__form {
  display: block;
  margin: 0 auto;
  max-width: 450px;
  letter-spacing: 0;
  vertical-align: top;
  padding-top: 20px;
  input, textarea {
    width: 100%;
    height: 45px;
    background: $light_gray;
    font-family: $FreightSansPro;
    font-size: 15px;
    border-radius: 0;
    -webkit-appearance: none;
    border: none;
    text-indent: 20px;
    letter-spacing: 0.08em;
  }
  textarea {
    height: 140px;
    text-indent: 0;
    padding: 15px 20px;
    display: block;
    resize: none;
  }
}

.overlay__form__product {
  padding: 40px 40px 40px 160px;
  background: $light_gray;
  margin-bottom: 20px;
  position: relative;
  min-height: 160px;
  line-height: 1.5;
  h2 {
    margin: 0;
  }
  img {
    display: block;
    position: absolute;
    background: $white;
    top: 40px;
    left: 40px;
    width: 100px;
    height:   100px;
  }
  b {
    font-weight: 600;
  }
}

.overlay__form__contact {
  background: $light_gray;
  padding: 40px;
  @media screen and (max-width: $medium_breakpoint) {
    display: none;
  }
}

.overlay__form__fields {
  width: 100%;
  transition: transform $reveal_duration $reveal_timing_function 0.8s,
  opacity $reveal_duration $reveal_timing_function 0.8s;
  ._reveal &, .reveal & {
    transform: translateY($reveal_delta);
    opacity: 0;
    transition: transform 0s 1s,
    opacity 0s 1s;
  }
  &.errors {
    transition: none;
  }
}

.overlay__form__input {
  margin-bottom: 20px;
  &.error {
    border-bottom: 1px solid red;
  }
}