.product_slider__frame {
  width: 100%;
  overflow: hidden;
}

.product_slider {
  padding: 80px 20px;
  max-width: 1200px;
  margin: 0 auto;
  @for $i from 0 through 10 {
    &[data-active="#{$i}"] {
      .product_slider__content {
        transform: translateX(-33.3333333333% * $i);
        @media screen and (max-width: $medium_breakpoint) {
          transform: translateX(-100% * $i);
        }
      }
      .product_slider__nav__pages {
        li[data-id="#{$i}"] {
          a:before {
            opacity: 1;
          }
        }
      }
    }
  }
  &[data-active="0"] {
    .product_slider__nav--prev {
      opacity: 0;
    }
  }
  &[data-active="9"] {
    .product_slider__nav--next {
      opacity: 0;
    }
  }
}

.product_slider__content {
  margin: 0 -1%;
  white-space: nowrap;
  transform: none;
  transition: transform 0.5s $easeOutQuad;
  font-size: 0;
  .product_list__item {
    padding: 0 10px;
  }
}

.product_slider__nav__frame {
  width: 100%;
  position: relative;
  text-align: center;
}

.product_slider__nav__pages {
  display: inline-block;
  font-size: 0;
  padding: 0 80px;
  white-space: nowrap;
  li {
    display: inline-block;
    a {
      height: 60px;
      width: 25px;
      display: inline-block;
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        margin-left: -4px;
        margin-top: -4px;
        background: $black;
        border-radius: 50%;
        opacity: 0.15;
        transform: none;
        transition: opacity 0.3s, transform 0.3s;
        will-change: transform;
      }
      &:hover {
        &:before {
          transform: scale(1.2);
          opacity: 0.5;
        }
      }
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    padding: 0 0 60px;
  }
}

.product_slider__nav {
  position: absolute;
  bottom: 0;
  padding: 10px 20px;
  opacity: 1;
  transition: opacity 0.3s, transform 0.3s;
  transform: none;
  svg {
    display: block;
    height: 40px;
    width: auto;
  }
}

.product_slider__nav--prev {
  left: 0;
  &:hover {
    transform: translateX(-10px);
  }
}

.product_slider__nav--next {
  right: 0;
  &:hover {
    transform: translateX(10px);
  }
}