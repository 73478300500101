#detail {
  width: 100%;
  height: 100%;
  padding-top: 0;
  #additional {
    background: $bronze;
    border-top: 16px solid $white;
    @media screen and (max-width: $medium_breakpoint) {
      border-top: 8px solid $white;
    }
  }
  &.black {
    #additional {
      background: $taube;
    }
    #share {
      background: $taube;
      color: $taube;
    }
    #quality {
      background: $light_black;
      color: $white;
    }
  }
  &.watches {
    #additional {
      background: $mint;
    }
    #share {
      background: $mint;
      color: $mint;
    }
  }
  &.bridal {
    #additional {
      background: $bridal;
    }
    #share {
      background: $bridal;
      color: $bridal;
    }
  }
}

.detail__intro {
  height: 100%;
  padding-top: 16px;
  display: table;
  width: 100%;
  border-bottom: 16px solid $white;
  .overlay__title {
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 5;
    width: 100%;
    max-width: 100%;
    transform-origin: 50% 80%;
    transition: transform $reveal_duration $reveal_timing_function 1.9s,
    opacity $reveal_duration $reveal_timing_function 2s;
  }
  .overlay__headline {
    font-size: viewport_scale(1.5);
    margin: viewport_scale(0.1) 0 1px 0;
  }
  .overlay__breadcumbs {
    transition-delay: 2.1s;
  }
  .overlay__text {
    transition-delay: 2.1s;
  }
  .black & {
    color: $white;
  }
  &.reveal {
    .overlay__title {
      transform: scale(0.9);
      opacity: 0;
    }
    .overlay__headline {
      transform: none;
      opacity: 1;
    }
    .overlay__breadcumbs {
      transform: none;
      opacity: 0;
    }
    .overlay__text {
      transform: none;
      opacity: 0;
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    padding-top: 8px;
    border-bottom: 8px solid $white;
    .overlay__title {
      top: 40px;
    }
  }
}

.detail__intro__frame {
  display: table;
  padding: 200px 20px 0;
  width: 100%;
  height: 100%;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: $white;
    transition: transform $reveal_duration/3 $reveal_timing_function,
    opacity $reveal_duration/3 $reveal_timing_function;
    border: 1px solid $dark_light_gray;
    .reveal & {
      transform: scale(0.9);
      opacity: 0;
    }
  }
  .black & {
    &:after {
      background: $black;
    }
  }
}

.detail__intro__main {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  position: relative;
  z-index: 2;
}

.detail__intro__title {
  width: 100%;
  text-align: center;
  margin-bottom: viewport_scale(3);
  font-size: viewport_scale(0.42);
  h1 {
    font-family: $JannonAntiqua;
    font-size: viewport_scale(1.5);
    font-weight: bold;
    letter-spacing: 0.03em;
    line-height: 1.4;
    margin: 15px 0 5px 0;
  }
}

.detail__intro__breadcrumbs {
  vertical-align: bottom;
  position: relative;
}

.detail__intro__breadcrumbs__separator {
  margin: 0 10px;
  width: 1px;
  height: viewport_scale(0.71);
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -2px;
    left: 0;
    height: 4px;
    width: 1px;
    background: $near_black;
    display: inline-block;
    transform-origin: 50% 0;
    transform: rotate(-45deg);
  }
  &:after {
    margin-top: -1px;
    transform-origin: 50% 100%;
    transform: rotate(45deg);
  }
}

.detail__intro__breadcrumbs__separator--straight {
  &:before, &:after {
    transform: none;
  }
  &:after {
    margin-top: 0;
  }
}

.detail__bottom_controls {
  position: absolute;
  bottom: 25px;
  height: 50px;
  left: 50%;
  width: 700px;
  margin-left: -350px;
  letter-spacing: -4px;
  z-index: 3;
  white-space: nowrap;
  transition: opacity $reveal_duration $reveal_timing_function 2s;
  .reveal & {
    opacity: 0;
  }
  @media screen and (max-width: $medium_breakpoint) {
    width: 500px;
    margin-left: -250px;
  }
  @media screen and (max-width: $small_breakpoint) {
    width: 100%;
    left: 0;
    margin-left: 0;
    padding: 0 20px;
  }
}

.detail__product__scroll {
  width: 50px;
  height: 50px;
  padding: 14px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -25px;
  svg {
    fill: $gray;
    width: 100%;
    height: 100%;
    display: block;
  }
}

.detail__intro__image__nav {
  display: inline-block;
  width: 50%;
  vertical-align: bottom;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 12px;
  text-decoration: none;
  color: inherit;
  svg {
    display: inline-block;
    margin: 0 auto;
    width: 30px;
    height: 30px;
    fill: $gray
  }
}

.detail__intro__image__nav--prev {
  text-align: left;
  padding-right: 25px;
  svg {
    left: 0;
  }
}

.detail__intro__image__nav--next {
  text-align: right;
  padding-left: 25px;
  svg {
    right: 0;
  }
}

.detail__intro__image__list {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  transform-origin: 50% 20%;
  transition: transform $reveal_duration $reveal_timing_function 1s,
  opacity $reveal_duration $reveal_timing_function 1s;
  .reveal & {
    transform: scale(0.9);
    opacity: 0;
  }
}

.detail__intro__image__frame {
  width: viewport_scale(15);
  height: viewport_scale(22);
  margin: 0 auto;
  white-space: nowrap;
  transform: none;
  will-change: transform;
  transition: transform 1.5s $easeInOutQuart;
  margin-top: -2%;
  @for $i from 0 through 5 {
    .detail__intro[data-image="#{$i}"] & {
      transform: translateX(-100% * $i);
      .detail__intro__image.data_id_#{$i} {
        opacity: 1;
      }
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    width: 100%;
    height: viewport_scale(30);
  }
}

.detail__intro__image {
  width: 100%;
  height: 100%;
  display: inline-block;
  opacity: 0;
  transition: opacity 1.5s $easeInOutQuart;
  line-height: viewport_scale(20);
  text-align: center;
  img {
    display: inline-block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.detail__action {
  background: $bronze;
  color: $white;
  overflow: hidden;
  .black & {
    background: $taube;
  }
  .watches & {
    background: $mint;
  }
  .bridal & {
    background: $bridal;
  }
}

.detail__action__frame {
  white-space: nowrap;
  font-size: 0;
  padding: 80px 80px;
  max-width: 800px;
  margin: 0 auto;
  .action_controls {
    display: inline-block;
    width: 45%;
    margin-right: 10%;
    white-space: normal;
    vertical-align: top;
  }
  &.image {
    max-width: 1100px;
    .action_controls {
      width: 100%;
      margin: 0 0 40px 0;
    }
    .detail__action__image {
      vertical-align: top;
      display: inline-block;
      width: 50%;
      img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    white-space: normal;
    padding: 80px 20px;
    max-width: 400px;
    .action_controls {
      width: 100%;
      margin-bottom: 50px;
    }
    &.image {
      max-width: 400px;
      .detail__action__image {
        display: none;
      }
    }
  }
}

.detail__action__main {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  .detail__action__frame.image & {
    width: 50%;
    padding-right: 10%;
    white-space: normal;
    @media screen and (max-width: $medium_breakpoint) {
      width: 100%;
      padding-right: 0;
    }
  }
}

.detail__action__benefits {
  display: inline-block;
  width: 45%;
  font-size: 16px;
  white-space: normal;
  vertical-align: top;
  padding-top: 10px;
  .detail__action__frame.image & {
    width: 100%;
  }
  @media screen and (max-width: $medium_breakpoint) {
    width: 100%;
  }
}

.detail__action__benefits__item {
  display: block;
  padding-left: 50px;
  margin-bottom: 20px;
  position: relative;
  letter-spacing: 0.05em;
  line-height: 1.4;
}

.detail__action__benefits__icon {
  position: absolute;
  top: 0;
  left: 10px;
  width: 22px;
  height: 22px;
  svg {
    fill: $white;
    height: 100%;
    width: 100%;
  }
}

#features {
  background: $light_gray;
  padding: 80px 20px 100px;
}

.feature__frame {
  width: 100%;
  max-width: 800px;
  margin: 100px auto 0;
}

.feature__content {
  width: 100%;
  position: relative;
  padding-right: 60%;
}

.feature__image {
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  height: 100%;
  padding: 20px 50px;
  text-align: center;
}

.feature__image__frame {
  height: 100%;
  width: auto;
  display: inline-block;
  position: relative;
  img {
    width: auto;
    height: 100%;
    display: block;
  }
  a {
    display: block;
    position: absolute;
    top: 30%;
    left: 10%;
    width: 45px;
    height: 45px;
    border: 1px solid $dark_light_gray;
    background: rgba($white, 0.9);
    border-radius: 50%;
    &:after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      margin: 14px;
      border-radius: 50%;
      background: $bronze;
    }
  }
}

.feature__list {
  width: 100%;
}

.feature__item {
  width: 100%;
  background: $white;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 50px;
    right: -8px;
    width: 16px;
    height: 16px;
    display: block;
    background: $white;
    transform: rotate(45deg);
  }
}

.feature__item__text {
  padding: 40px;
  h3 {
    font-family: $JannonAntiqua;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 10px;
  }
  p {
    letter-spacing: 0.05em;
  }
}

.feature__item__image {
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
}

#specs {
  width: 100%;
  background: $light_gray;
  padding: 200px 20px;
  position: relative;
  overflow: hidden;
  border-top: 16px solid $white;
  .black & {
    background: $light_black;
    color: $white;
  }
  @media screen and (max-width: $medium_breakpoint) {
    padding: 80px 20px;
    border-top: 8px solid $white;
  }
}

.specs__bg {
  position: absolute;
  top: -1%;
  right: 0;
  height: 102%;
  opacity: 0.04;
  transform: translateX(50%);
  svg {
    height: 100%;
    width: auto;
    fill: $black;
  }
}