$landing_slider_transition_duration: 1.5s;

#landing {
  height: 90%;
  width: 100%;
  border: 16px solid $white;
  border-bottom: none;
  overflow: hidden;
  background: $white;
  & + #footer {
    margin-top: -16px;
  }
  @media screen and (max-width: $medium_breakpoint) {
    border: 8px solid $white;
    & + #footer {
      margin-top: -8px;
    }
  }
}

.landing__slider {
  position: relative;
  width: 100%;
  height: 100%;
  @for $i from 0 through 3 {
    &[data-active="#{$i}"] {
      .landing__slider__content {
        transform: translateX(-100% * $i);
      }
      .landing__slider__navigation__button__list {
        transform: translateX(-100% * $i);
        a {
          &.data_id_#{$i} {
            opacity: 1;
          }
        }
      }
      .landing__slider__navigation__line {
        transform: translateX(-180px * $i);
        @media screen and (max-width: $medium_breakpoint) {
          transform: translateX(-150px * $i);
        }
      }
      .landing__slider__item.data_id_#{$i} {
        .landing__slider__item__headline__frame {
          transform: none;
          opacity: 1;
        }
      }
    }
  }
}

.landing__slider__content {
  width: 100%;
  height: 100%;
  font-size: 0;
  white-space: nowrap;
  transition: transform $landing_slider_transition_duration $easeOutQuart;
  will-change: transform;
}

.landing__slider__item {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}

.landing__slider__item__content {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  transition: transform $reveal_duration $reveal_timing_function,
  opacity $reveal_duration $reveal_timing_function;
  span {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(center, rgba($black, 0.75), $black);
    opacity: 0.3;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 200px;
    display: block;
    background-image: radial-gradient(ellipse at top left, rgba($black, 0.4), rgba($black, 0), rgba($black, 0));
    z-index: 1;
    will-change: transform;
  }
  .reveal & {
    transform: scale(0.9);
    opacity: 0;
  }
}

.landing__slider__item__headline__frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform $reveal_duration $reveal_timing_function 0.4s,
  opacity $reveal_duration $reveal_timing_function 0.4s;
  z-index: 5;
  transform: scale(0.9);
  opacity: 0;
  .reveal & {
    transform: scale(0.9) !important;
    opacity: 0 !important;
  }
}

.landing__slider__item__headline {
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) translateY(-20px);
  display: inline-block;
  padding: 20px 40px;
  max-width: 1700px;
  width: 100%;
  white-space: normal;
  text-align: center;
  h2 {
    color: $white;
    font-size: viewport_scale(1.5);
    font-family: $JannonAntiqua;
    line-height: 1.5;
    letter-spacing: 0.02em;
    font-weight: bold;
    transition: box-shadow $reveal_duration $reveal_timing_function 0.4s;
    text-shadow: 0 0 3em $black, 0 0 2em rgba($black, 0.5), 0 0 1em rgba($black, 0.8);
    .reveal & {
      text-shadow: none;
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    max-width: 650px;
    br {
      display: none;
    }
  }
}

.landing__slider__navigation {
  position: absolute;
  bottom: 100px;
  width: 100%;
  @media screen and (max-width: $medium_breakpoint) {
    bottom: 40px;
  }
}

.landing__slider__navigation__line {
  font-size: 0;
  position: relative;
  left: 50%;
  margin-left: -26px;
  white-space: nowrap;
  transition: transform $landing_slider_transition_duration $easeOutQuart;
  a {
    display: inline-block;
    width: 51px;
    height: 51px;
    margin-left: 130px;
    position: relative;
    &:before {
      content: '';
      width: 11px;
      height: 11px;
      position: absolute;
      border-radius: 50%;
      background: $white;
      top: 20px;
      left: 20px;
      transform: none;
      transition: transform 0.3s;
    }
    &:after {
      content: '';
      position: absolute;
      top: 25px;
      height: 1px;
      background: $white;
      left: 40px;
      width: 150px;
    }
    &:hover {
      &:before {
        transform: scale(1.2);
      }
    }
    &:first-child {
      margin: 0;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    @for $i from 0 through 8 {
      &.data_id_#{$i} {
        transition: transform $reveal_duration $reveal_timing_function 0.3s * $i + 1.4s,
        opacity $reveal_duration $reveal_timing_function 0.3s * $i + 1.4s;
        &:after {
          transition: width 0.5s linear 0.6s * $i + 3.5s,
          opacity 0.5s linear 0.6s * $i + 3.5s;
        }
        .reveal & {
          opacity: 0;
          transform: translateY($reveal_delta);
          &:after {
            width: 0;
            opacity: 0;
          }
        }
      }
    }
    @media screen and (max-width: $medium_breakpoint) {
      margin-left: 100px;
      &:after {
        width: 120px;
      }
    }
  }
}

.landing__slider__navigation__button__frame {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

.landing__slider__navigation__button {
  display: inline-block;
  height: 50px;
  background: $white;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  transition: transform $reveal_duration $reveal_timing_function 1.5s,
  opacity $reveal_duration $reveal_timing_function 1.5s;
  .reveal & {
    opacity: 0;
    transform: translateY($reveal_delta);
  }
}

.landing__slider__navigation__button__list {
  transition: transform $landing_slider_transition_duration $easeOutQuart;
  width: 100%;
  height: 100%;
  display: block;
}