.page_teaser {
  padding: 50px 30px;
  max-width: 1200px;
  margin: 0 auto;
  @media screen and (max-width: $medium_small_breakpoint) {
    max-width: 500px;
  }
}

.page_teaser__content {
  margin: 0 -2%;
  font-size: 0;
  @media screen and (max-width: $medium_small_breakpoint) {
    margin: 0;
  }
}

.page_teaser__item {
  display: inline-block;
  width: 46%;
  margin: 0 2%;
  position: relative;
  h2 {
    font-size: 25px;
    line-height: 1.35;
    letter-spacing: 0.02em;
    text-align: left;
    color: $white;
    max-width: 400px;
    @media screen and (max-width: $medium_breakpoint) {
      font-size: 22px;
    }
  }
  a {
    margin-top: 0;
  }
  img {
    width: 100%;
    height: auto;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba($black, 0.25), rgba($black, 1));
    opacity: 0.45;
    z-index: 1;
  }
  @media screen and (max-width: $medium_small_breakpoint) {
    width: 100%;
    margin: 20px 0;
    &:first-child {
      margin-top: 0;
    }
  }
}

.page_teaser__item__content {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 30px;
  width: 100%;
  z-index: 2;
}

.category_teaser {
  background: $light_gray;
  padding: 80px 30px;
  @media screen and (max-width: $medium_breakpoint) {
    padding-top: 50px;
  }
}

.category_teaser_content {
  max-width: 1600px;
  margin: 0 auto;
  @media screen and (max-width: $large_breakpoint) {
    max-width: 600px;
  }
}

.category_teaser_item {
  display: table;
  width: 100%;
  font-size: 0;
  margin-top: 100px;
  &:nth-child(even) {
    direction: rtl;
  }
  @media screen and (max-width: $large_breakpoint) {
    direction: ltr;
  }
  @media screen and (max-width: $medium_breakpoint) {
    &:first-child {
      margin-top: 40px;
    }
  }
}

.category_teaser_item_main {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}

.category_teaser_item_content {
  width: 35%;
  display: inline-block;
  vertical-align: middle;
  direction: ltr;
  h3 {
    font-size: 26px;
    line-height: 1.6;
    margin-bottom: 30px;
    font-family: $JannonAntiqua;
    @media screen and (max-width: $medium_breakpoint) {
      font-size: 22px;
    }
    @media screen and (max-width: $small_breakpoint) {
      font-size: 20px;
      br {
        display: none;
      }
    }
  }
  p {
    font-size: 16px;
    line-height: 1.4;
  }
  @media screen and (max-width: $large_breakpoint) {
    width: 100%;
  }
}

.category_teaser_item_spacer {
  width: 5%;
  display: inline-block;
  vertical-align: middle;
  @media screen and (max-width: $large_breakpoint) {
    width: 100%;
    height: 40px;
  }
}

.category_teaser_item_image {
  width: 60%;
  display: inline-block;
  vertical-align: middle;
  @media screen and (max-width: $large_breakpoint) {
    width: 100%;
  }
}