$subject_slider_transition_duration: 1s;

.subject_slider {
  padding: 100px 0 80px 0;
  color: $white;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  @for $i from 0 through 5 {
    .subject_slider__headline__main h2 {
      transform: translateX(-50%);
    }
    .subject_slider__text__main p {
      transform: translateX(-50%);
    }
    &[data-active="#{$i}"] {
      .subject_slider__navigation li.data_id_#{$i} {
        opacity: 1;
        a {
          &:before {
            width: 20px;
          }
        }
      }
      .subject_slider__headline__main h2.data_id_#{$i} {
        transform: none;
        opacity: 1;
        transition: transform $subject_slider_transition_duration/2 $easeInQuart $subject_slider_transition_duration/2,
        opacity $subject_slider_transition_duration/2 $easeInQuart $subject_slider_transition_duration/2;
        & ~ h2 {
          transform: scale(0.9);
        }
      }
      .subject_slider__text__main p.data_id_#{$i} {
        transform: none;
        opacity: 1;
        transition: transform $subject_slider_transition_duration/2 $easeInQuart $subject_slider_transition_duration/2,
        opacity $subject_slider_transition_duration/2 $easeInQuart $subject_slider_transition_duration/2;
        & ~ p {
          transform: scale(0.9);
        }
      }
      .subject_slider__image__item.data_id_#{$i} {
        & ~ .subject_slider__image__item {
          opacity: 0;
        }
      }
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    max-width: 500px;
    padding-top: 50px;
  }
}

.subject_slider__content {
  width: 100%;
  position: relative;
  z-index: 1;
}

.subject_slider__image__list {
  position: absolute;
  top: 0;
  right: 40%;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 0;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, rgba($black, 0.35), transparent);
    z-index: 5;
  }
  @media screen and (max-width: $medium_breakpoint) {
    right: 10%;
    padding-bottom: 90%;
    bottom: auto;
  }
}

.subject_slider__image__item {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 1;
  transition: opacity $subject_slider_transition_duration $easeInQuart;
}

.subject_slider__title {
  width: 100%;
  white-space: normal;
  position: relative;
  z-index: 5;
  &:after {
    content: '';
    display: block;
    float: none;
    clear: both;
  }
}

.subject_slider__navigation {
  float: right;
  padding: 20px 40px;
  width: 100%;
  max-width: 40%;
  position: relative;
  z-index: 3;
  li {
    opacity: 0.3;
    white-space: normal;
    transition: opacity $subject_slider_transition_duration/2 $easeOutQuart;
    a {
      display: block;
      padding: 10px 10px 10px 30px;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-weight: bold;
      color: inherit;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -1px;
        left: 0;
        height: 2px;
        width: 10px;
        background: $white;
        transition: width $subject_slider_transition_duration/2 $easeOutQuart;
      }
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    max-width: 100%;
    width: 100%;
    float: none;
    padding: 0 20px;
    margin-bottom: 40px;
  }
}

.subject_slider__headline {
  padding: 40px 44% 40px 80px;
  @media screen and (max-width: $medium_breakpoint) {
    padding: 40px 10% 0 20px;
  }
}

.subject_slider__headline__main {
  width: 100%;
  display: block;
  letter-spacing: -10px;
  transform: none;
  white-space: nowrap;
  h2 {
    letter-spacing: normal;
    white-space: normal;
    margin: 0;
    display: inline-block;
    width: 100%;
    opacity: 0;
    text-align: center;
    transition: transform $subject_slider_transition_duration/2 $easeInQuart,
    opacity $subject_slider_transition_duration/2 $easeInQuart;
    vertical-align: top;
    @for $i from 0 through 5 {
      &.data_id_#{$i} {
        position: relative;
        left: -100% * $i;
      }
    }
    @media screen and (max-width: $medium_breakpoint) {
      text-align: center;
    }
  }
}

.subject_slider__text {
  width: 100%;
  text-align: right;
  position: relative;
  z-index: 6;
  margin-top: 30px;
  @media screen and (max-width: $medium_breakpoint) {
    margin-top: 40%;
  }
}

.subject_slider__text__main {
  max-width: 50%;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
  letter-spacing: -4px;
  transform: none;
  p {
    display: inline-block;
    vertical-align: top;
    background: $white;
    padding: 40px;
    color: $near_black;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.05em;
    white-space: normal;
    opacity: 0;
    transition: transform $subject_slider_transition_duration $easeInQuart,
    opacity $subject_slider_transition_duration $easeInQuart;
    @for $i from 0 through 5 {
      &.data_id_#{$i} {
        position: relative;
        left: -100% * $i;
      }
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    max-width: 90%;
    p {
      padding: 30px 15px 30px 30px;
    }
  }
}

.subject_slider__controls {
  height: 90px;
  width: 100%;
  white-space: nowrap;
  font-size: 0;
  position: relative;
  z-index: 10;
  a {
    cursor: pointer;
    padding: 0 30px;
    height: 100%;
    width: 50%;
    text-align: right;
    display: inline-block;
    svg {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin: 20px 0;
      fill: $white;
    }
    &:first-child {
      text-align: left;
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    margin-top: 20px;
  }
}