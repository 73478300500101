.diamond_education {
  .overlay__title {
    text-align: left;
    max-width: 1200px;
    @media screen and (max-width: $medium_breakpoint) {
      max-width: 500px;
    }
  }
  .overlay__introline {
    &:before {
      content: '?';
      width: 20px;
      height: 20px;
      background: $blue;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      font-family: $Roboto;
      font-size: 12px;
      letter-spacing: 0;
      margin-left: 5px;
      line-height: 18px;
      margin-right: 10px;
      color: $white;
    }
  }
  .overlay__main {
    max-width: 1200px;
    margin: 0 auto;
    @media screen and (max-width: $medium_breakpoint) {
      max-width: 500px;
    }
  }
}

.diamond_education__frame {
  width: 100%;
  letter-spacing: -4px;
  direction: rtl;
}

.diamond_education__description {
  width: 48%;
  display: inline-block;
  letter-spacing: 0;
  margin-right: 4%;
  vertical-align: top;
  direction: initial;
  strong {
    font-weight: bold;
  }
  @media screen and (max-width: $medium_breakpoint) {
    width: 100%;
    margin: 0;
  }
}

.diamond_education__button {
  transition: transform $reveal_duration $reveal_timing_function 0.8s,
  opacity $reveal_duration $reveal_timing_function 0.8s;
  ._reveal &, .reveal & {
    transform: translateY($reveal_delta);
    opacity: 0;
    transition: transform 0s 1s,
    opacity 0s 1s;
  }
}

.diamond_education__example {
  width: 48%;
  display: inline-block;
  letter-spacing: normal;
  vertical-align: top;
  direction: initial;
  .selection_slider {
    width: 100%;
  }
  @media screen and (max-width: $medium_breakpoint) {
    width: 100%;
    margin-bottom: 40px;
  }
}

.diamond_education__example__title {
  text-align: center;
  font-style: italic;
  font-size: 14px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.diamond_education__example__visual {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  position: relative;
}

.diamond_education__example__visual__image {
  width: 100%;
  padding-bottom: 80%;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
    position: absolute;
    top: -15%;
    left: 0;
  }
  &.clarity, &.cut {
    img {
      padding: 40px;
      display: none;
    }
    @for $i from 0 through 12 {
      &[data-active="#{$i}"] {
        img.data_id_#{$i} {
          display: block;
        }
      }
    }
  }
}