$products_transition_fix_delay: 0.7s;
$products_transition_delay: 0.12s;

#products {
  text-align: center;
  .overlay__content {
    display: inline-block;
    width: auto;
    max-width: 100%;
    text-align: left;
  }
}

.products__frame {
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: $medium_breakpoint) {
    max-width: 400px;
  }
}

.products__list {
  margin: 0 -20px 20px -20px;
  font-size: 0;
}

.products__range {
  display: inline-block;
  margin: 0 0 90px 0;
  padding: 0 20px;
  vertical-align: top;
  @for $i from 0 through 20 {
    &.data_id_#{$i} {
      h3 {
        transition: transform $reveal_duration $reveal_timing_function $products_transition_delay * $i + $products_transition_fix_delay,
        opacity $reveal_duration $reveal_timing_function $products_transition_delay * $i + $products_transition_fix_delay;
      }
    }
  }
  ._reveal & h3, .reveal & h3 {
    transform: translateY($reveal_delta);
    opacity: 0;
    transition: transform 0s 1s,
    opacity 0s 1s;
  }
  @media screen and (max-width: $medium_breakpoint) {
    width: 100%;
  }
}

.products__range__title {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 1.5;
  @media screen and (max-width: $large_breakpoint) {
    font-size: 14px;
    margin-bottom: 8px;
  }
  @media screen and (max-width: $medium_breakpoint) {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.products__range__list {
  display: block;
  margin: 0;
  letter-spacing: -4px;
  white-space: normal;
}

.products__category {
  width: 180px;
  display: inline-block;
  vertical-align: top;
  margin: 0 15px 0 0;
  letter-spacing: 0;
  &:last-child {
    margin: 0;
  }
  @for $i from 0 through 20 {
    &.data_id_#{$i} {
      transition: transform $reveal_duration $reveal_timing_function $products_transition_delay * $i + $products_transition_fix_delay,
      opacity $reveal_duration $reveal_timing_function $products_transition_delay * $i + $products_transition_fix_delay;
    }
  }
  ._reveal &, .reveal & {
    transform: translateY($reveal_delta);
    opacity: 0;
    transition: transform 0s 1s,
    opacity 0s 1s;
  }
  @media screen and (max-width: $large_breakpoint) {
    width: 120px;
    margin: 0 10px 0 0;
  }
  @media screen and (max-width: $medium_breakpoint) {
    width: 31%;
    margin: 0 3.5% 0 0;
    &:nth-child(3) {
      margin: 0;
    }
  }
}

.products__category__frame {
  display: block;
  width: 100%;
  color: $near_black;
  text-decoration: none;
  transition: opacity 1s $easeOutQuart;
  .hover & {
    opacity: 0.4;
  }
  &:hover {
    opacity: 1;
  }
}

.products__category__item {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background: $black;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
}

.products__category__title {
  font-size: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 10px 0 0 0;
  line-height: 1.5;
  @media screen and (max-width: $large_breakpoint) {
    font-size: 14px;
  }
  @media screen and (max-width: $medium_breakpoint) {
    font-size: 12px;
  }
}