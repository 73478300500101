@import url("//hello.myfonts.net/count/34b59e");

@font-face {
  font-family: 'JannonAntiqua';
  src: url('../fonts/Jannon/34B59E_1_0.woff2') format('woff2'),
  url('../fonts/Jannon/34B59E_1_0.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'JannonAntiqua';
  src: url('../fonts/JannonBold/34B59E_0_0.woff2') format('woff2'),
  url('../fonts/JannonBold/34B59E_0_0.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}