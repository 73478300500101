.content {
  padding: 100px 15px 0;
  background: $white;
  min-height: 100%;
  body.noheader &, body.logoheader &, body.transparentheader & {
    padding-top: 15px;
  }
  @media screen and (max-width: $medium_breakpoint) {
    padding: 80px 8px 0;
    body.noheader &, body.logoheader &, body.transparentheader & {
      padding-top: 8px;
    }
  }

  h2 {
    font-size: 40px;
    text-align: center;
    font-family: $JannonAntiqua;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 20px;
    @media screen and (max-width: $medium_breakpoint) {
      font-size: 34px;
    }
    @media screen and (max-width: $small_breakpoint) {
      font-size: 28px;
    }
  }

  h2 ~ p {
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 1.4;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    strong {
      font-weight: 600;
    }
    @media screen and (max-width: $medium_breakpoint) {
      font-size: 16px;
    }
    @media screen and (max-width: $small_breakpoint) {
      font-size: 14px;
    }
  }

  .overlay__title ~ .content__text {
    h2 {
      font-size: 14px;
      font-family: $FreightSansPro;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
    p {
      text-align: left;
      margin-bottom: 60px;
    }
  }

  .overlay__title + .content__text {
    h2 {
      transition: transform $reveal_duration $reveal_timing_function 0.8s,
      opacity $reveal_duration $reveal_timing_function 0.8s;
    }
    p {
      transition: transform $reveal_duration $reveal_timing_function 1s,
      opacity $reveal_duration $reveal_timing_function 1s;
    }
  }
}

.content__share {
  #share {
    background: $gray;
    color: $gray;
  }
}

.content__subject_slider {
  background: $gray;
}

.content__text {
  padding: 0 80px;
  margin-bottom: 80px;
  h2 {
    display: block;
    transition: transform $reveal_duration $reveal_timing_function,
    opacity $reveal_duration $reveal_timing_function;
  }
  p {
    display: block;
    transition: transform $reveal_duration $reveal_timing_function 0.2s,
    opacity $reveal_duration $reveal_timing_function 0.2s;
  }
  &.reveal {
    h2, p {
      transform: translateY($reveal_delta);
      opacity: 0;
    }
  }
  @media screen and (max-width: $medium_breakpoint) {
    padding: 0 20px;
  }
}

.content__text__address {
  max-width: 350px;
  margin: 0 auto;
  letter-spacing: -4px;
}

.content__text__address__column {
  display: inline-block !important;
  vertical-align: top;
  width: 45%;
  letter-spacing: normal;
  margin-left: 10%;
  &:first-child {
    margin-left: 0;
  }
}

.content__image_text {
  text-align: center;
  .button {
    display: inline-block;
    margin: 160px auto 80px;
    background: $near_black;
    color: $white;
    max-width: 600px;
    width: 100%;
    vertical-align: bottom;
    padding-top: 2px;
    padding-bottom: 2px;
    span {
      line-height: 40px;
    }
    svg {
      fill: $white;
      height: 40px;
      width: 40px;
      vertical-align: bottom;
      display: inline-block;
      margin-left: 20px;
    }
  }
}

.content__image_text__main {
  padding: 80px 80px 0;
  background: $light_gray;
  img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    max-width: 800px;
    position: relative;
    top: 80px;
  }
  @media screen and (max-width: $medium_breakpoint) {
    padding: 80px 20px 0;
  }
}

.download__content {
  max-width: 500px;
  margin: 30px auto;
  width: 100%;
}

.download__content__item {
  margin: 0 0 50px 0;
}

.download__content__item__title {
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 10px;
  strong {
    font-weight: 600;
  }
}

.download__content__item__assets {
  margin: 0 -1.5%;
  letter-spacing: -4px;
  a {
    display: inline-block;
    vertical-align: top;
    width: 45.5%;
    margin: 1.5%;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}